import { createPersonal, specialCourseExercise, viewExercises } from '@/router/_middleware/manageEducation'
import auth from '@/router/_middleware/auth'
import isManager from '@/router/_middleware/isManager'

export default [
  {
    component: () => import('@/views/manager/education/exercises/Journal.vue'),
    meta: { middleware: [auth, isManager] },
    name: 'manager.education.exercises.journal',
    path: '/manager/education/exercises/journal',
  },
  {
    component: () => import('@/views/manager/education/exercises/New.vue'),
    meta: { middleware: [auth, createPersonal] },
    name: 'manager.education.exercises.new',
    path: '/manager/education/exercises/:groupID/new',
  },
  {
    component: () => import('@/views/manager/education/exercises/quickStart/List.vue'),
    meta: { middleware: [auth, specialCourseExercise] },
    name: 'manager.education.exercises.quickStart',
    path: '/manager/education/exercises/:groupID/:exerciseUUID/quick-start',
  },
  {
    component: () => import('@/views/manager/education/exercises/Edit.vue'),
    meta: { middleware: [auth, createPersonal] },
    name: 'manager.education.exercises.item.edit',
    path: '/manager/education/exercises/:groupID/:exerciseUUID/edit',
  },
  {
    children: [
      {
        children: [
          {
            component: () => import('@/views/manager/education/exercises/item/task/master/Answer.vue'),
            meta: { middleware: [auth, viewExercises] },
            name: 'manager.education.exercises.item.task.master.answer',
            path: '/manager/education/exercises/:groupID/:exerciseUUID/:taskUUID/:masterID/answer',
          },
          {
            component: () => import('@/views/manager/education/exercises/item/task/master/Information.vue'),
            meta: { middleware: [auth, viewExercises] },
            name: 'manager.education.exercises.item.task.master.information',
            path: '/manager/education/exercises/:groupID/:exerciseUUID/:taskUUID/:masterID/information',
          },
          {
            component: () => import('@/views/manager/education/exercises/item/task/master/Messages.vue'),
            meta: { middleware: [auth, viewExercises] },
            name: 'manager.education.exercises.item.task.master.messages',
            path: '/manager/education/exercises/:groupID/:exerciseUUID/:taskUUID/:masterID/messages',
          },
        ],
        component: () => import('@/views/manager/education/exercises/item/task/Master.vue'),
        meta: { middleware: [auth, viewExercises] },
        name: 'manager.education.exercises.item.task.master',
        path: '/manager/education/exercises/:groupID/:exerciseUUID/:taskUUID/:masterID',
      },
      {
        component: () => import('@/components/tables/ExerciseMasterTable.vue'),
        exact: true,
        meta: { middleware: [auth, viewExercises] },
        name: 'manager.education.exercises.item.masterList',
        path: '',
      },
    ],
    component: () => import('@/views/manager/education/exercises/Item.vue'),
    meta: { middleware: [auth, viewExercises] },
    path: '/manager/education/exercises/:groupID/:exerciseUUID/:taskUUID',
  },
  {
    component: () => import('@/views/manager/education/exercises/List.vue'),
    meta: { middleware: [auth, viewExercises] },
    name: 'manager.education.exercises',
    path: '/manager/education/exercises',
  },
]
