



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class FireworksIcon extends Vue {
  @Prop({
    default: 40,
    validator (value: number): boolean {
      return [40, 60, 80].includes(value)
    },
  })
  private size!: number

  @Prop({
    default: 'orange-base',
    validator (value: string): boolean {
      return !!value.match(/(red-lighten-4|orange-base|blue-base)/)
    },
  })
  private color!: string

  @Prop({ default: 300 })
  private delay!: number
}
