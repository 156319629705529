












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageContainer extends Vue {
  // Используется в единственном месте, где не хватает столбцов из-за сайдбара
  @Prop({ default: false })
  private shortHeader!: boolean

  // true, если внутри контента только карточка формы (880px на 1920)
  @Prop({ default: false })
  private shortContent!: boolean

  @Prop({ default: false })
  private isWide!: boolean

  @Prop({ default: 6 })
  private space!: number

  @Prop({ default: false })
  private isProgress!: boolean

  @Prop({ default: false })
  private contentCenter!: boolean

  @Prop({ default: false })
  private fullHeight!: boolean
}
