



















































//import { Bind, Debounce } from 'lodash-decorators'
import { MetaInfo } from 'vue-meta'
import vuescroll from 'vuescroll'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import Cookies from 'js-cookie'
import { POSITION } from 'vue-toastification'
import { omit } from 'lodash'
import camelCaseKeys from 'camelcase-keys'

// // Для ускорения загрузки Rive анимаций
// import riveWASMResource from '@rive-app/canvas/rive.wasm'
// import { RuntimeLoader } from '@rive-app/canvas'
// components
import AppMainTutorial from '@/components/tutorials/AppMainTutorial.vue'
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import CalendarTutorial from '@/components/tutorials/CalendarTutorial.vue'
import CookieAgreement from '@/components/modals/CookieAgreement.vue'
import BirthdayModal from '@/components/modals/BirthdayModal.vue'
import EndYearAnimation from '@/components/EndYearAnimation.vue'
import BirthdayAnimation from '@/components/BirthdayAnimation.vue'
import GlobalModals from '@/components/GlobalModals.vue'
import Header from '@/components/layout/Header.vue'
import Loader from '@/components/Loader.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import SidenavManager from '@/components/layout/sidenavs/SidenavManager.vue'
import SidenavMaster from '@/components/layout/sidenavs/SidenavMaster.vue'
import TourVisible from '@/components/modals/TourVisible.vue'
import Footer from '@/views/Footer.vue'
import SidenavMasterTour from '@/components/layout/sidenavs/SidenavMasterTour.vue'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import LessonExerciseTutorial from '@/components/tutorials/LessonExerciseTutorial.vue'
import MasterEducationModule from '@/store/modules/master/education'
import MasterTutorialsModule from '@/store/modules/master/tutorials'
import ProfileModule from '@/store/modules/profile'
import SystemModule from '@/store/modules/system'
import { EducationMasterGroupResource, Permission, SelfResource } from '@/store/types'
// mixins
import PermissionsMixin from '@/mixins/PermissionsMixin'
// utils
import { isNewYear, isShowEndYearAnimation, isShowBirthdayAnimation } from '@/utils/constants'

@Component({
  components: {
    EndYearAnimation,
    BirthdayAnimation,
    ButtonIconAction,
    CalendarTutorial,
    CookieAgreement,
    BirthdayModal,
    Footer,
    GlobalModals,
    Header,
    LessonExerciseTutorial,
    Loader,
    ScrollToTop,
    SidenavManager,
    SidenavMaster,
    SidenavMasterTour,
    TourVisible,
    AppMainTutorial,
  },
})
export default class App extends Mixins(PermissionsMixin) {
  @Ref() scroll!: vuescroll

  private showOnTopButton = false
  private scrollOptions = {
    vuescroll: {
      // detectResize: false,
      sizeStrategy: 'number',
    },
  }

  private isVisibleFooter = ['auth.login', 'auth.register', 'profile']

  private get windowWidth () {
    return this.$vuetify.breakpoint.width
  }

  private windowHeight = window.innerHeight

  private vh = this.windowHeight * 0.01

  private get routeName() {
    return this.$route.name
  }

  private get isNewYear() {
    return isNewYear
  }

  private get isShowEndYearAnimation() {
    return isShowEndYearAnimation
  }

  private get isShowBirthdayAnimation() {
    return isShowBirthdayAnimation && this.isUserBirthday
  }

  private today = new Date()

  private isUserBirthday = false

  private showBirthdayAnimationFirstTime: boolean | null = false

  private get loading () {
    return SystemModule.loading
  }

  private get user () {
    return AuthModule.self
  }

  private get isManager () {
    return SystemModule.interface === 'manager'
  }

  private get isMobile () {
    return this.windowWidth <= 1025
  }

  private get hideSidebar () {
    return this.windowWidth < 1366
  }

  private get positionReset() {
    return this.$vuetify.breakpoint.width < 700
  }

  private get snackbarText() {
    return SystemModule.snackbarText
  }

  private get isShowTimer() {
    return SystemModule.isShowTimer
  }

  private get isMainTourActive() {
    return MasterTutorialsModule.mainTutorialIsActive
  }

  private get isCalendarTourStart() {
    return MasterTutorialsModule.calendarTutorialActive
  }

  private get mainTourStep() {
    return MasterTutorialsModule.mainTutorialStep
  }

  private scrollTop(type: 'next' | 'previous') {
    return new Promise((resolve) => {
      if (type === 'previous') {
        this.scroll.scrollTo({ y: 0 }, 500, 'easeInOutQuad')
        resolve(true)
      } else {
        resolve(true)
      }
    })
  }

  private sub: any = null

  private created () {
    SystemModule.initInterface()
    DictionaryModule.fetchDictionaries()
      .then(response => {
        this.$bus.$emit('getPaymentErrors', response.paymentErrors)
      })

    document.documentElement.style.setProperty('--vh', `${this.vh}px`)

    window.addEventListener('resize', this.handleWindowResize)
  }

  private mounted () {
    this.$bus.$on('getPositionScrollY', this.getPositionScrollY)
    this.$bus.$on('scrollToTop', this.handleScrollToTop)
    this.$bus.$on('scrollToBottom', this.handleScrollToBottom)
    this.$bus.$on('scrollIntoView', this.handleScrollIntoView)
    this.$bus.$on('socketUser', this.socketUser)

    if (!this.isNewYear) {
      localStorage.removeItem('snowflake')
    }

    document.addEventListener('visibilitychange', ({ type }: Event) => {
      if (type === 'visibilitychange' && document.visibilityState === 'visible') {
        this.birthDate(this.user?.birthDate ?? '')

        if (!this.isUserBirthday) {
          this.today = new Date()
          localStorage.removeItem('birthdayAnimationActive')
        }
      }
    })

    const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    if (checkIsIOS())
      this.addMaximumScaleToMetaViewport()
  }

  private socketUser() {
    if (this.user && !this.sub) {
      this.sub = this.$centrifuge.newSubscription(`user.${this.user.id}`)
      this.sub.on('publication', (ctx: any) => {

        // Если обновлен профиль пользователя
        if (ctx.data.event === 'App\\Events\\Broadcast\\UserProfileUpdatedEvent') {
          const response = camelCaseKeys(omit(ctx.data, ['event', 'socket']), { deep: true }) as SelfResource
          if (ProfileModule.information) {
            ProfileModule.setInformation({
              ...ProfileModule.information,
              isLocalTimezone: response.isLocalTimezone,
            })
          }
          AuthModule.setSelf(response)
        }

        // Если обновлен список мастер-групп пользователя
        if (ctx.data.event === 'App\\Events\\Broadcast\\MasterGroupListChangedEvent') {
          const { masterGroups } = camelCaseKeys(omit(ctx.data, ['event', 'socket']), { deep: true }) as { masterGroups: EducationMasterGroupResource[] }
          MasterEducationModule.setMasterGroups(masterGroups)
          this.$bus.$emit('updateFormLegend', masterGroups)
        }
      })
      this.sub.subscribe()
    }
  }

  private addMaximumScaleToMetaViewport() {
    const el = document.querySelector('meta[name=viewport]')

    if (el) {
      let content = el.getAttribute('content')
      if (content) {
        el.setAttribute('content', `${content},maximum-scale=1.0`)
      }
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    this.$bus.$off('getPositionScrollY', this.getPositionScrollY as any)
  }

  private isSnowflakeActive = localStorage.getItem('snowflake') === null ? true : localStorage.getItem('snowflake') === '1'
  private isEndYearAnimationActive = localStorage.getItem('endYearAnimationActive') === null ? true : localStorage.getItem('endYearAnimationActive') === '1'
  private isBirthdayAnimationActive = localStorage.getItem('birthdayAnimationActive') === null ? true : localStorage.getItem('birthdayAnimationActive') === '1'

  private handleSnowflakeSwitch(value: boolean) {
    try {
      value ? this.$metrika.reachGoal('on_animation') : this.$metrika.reachGoal('off_animation')
    } catch {
      // eslint-disable-next-line
      console.error('Отправка метрики заблокирована расширением браузера')
    }
    localStorage.setItem('snowflake', value ? '1' : '0')
    this.isSnowflakeActive = value
  }

  private handleEndYearAnimationSwitch(value: boolean) {
    try {
      value ? this.$metrika.reachGoal('on_animation') : this.$metrika.reachGoal('off_animation')
    } catch {
      // eslint-disable-next-line
      console.error('Отправка метрики заблокирована расширением браузера')
    }
    localStorage.setItem('endYearAnimationActive', value ? '1' : '0')
    this.isEndYearAnimationActive = value
  }

  private handleBirthdayAnimationSwitch(value: boolean) {
    localStorage.setItem('birthdayAnimationActive', value ? '1' : '0')
    this.isBirthdayAnimationActive = value
  }

  //@Bind
  //@Debounce(150)
  private handleWindowResize () {
    this.windowHeight = window.innerHeight
    this.vh = this.windowHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    vuescroll.refreshAll()
  }

  private getPositionScrollY() {
    const { scrollTop } = this.scroll.getPosition()
    SystemModule.setScrollY(scrollTop || 0)
  }

  private handleScroll (vertical: any) {
    this.$bus.$emit('handleScroll', vertical.scrollTop)
    this.showOnTopButton = vertical.scrollTop > 500
  }

  private handleScrollToTop () {
    this.scroll.scrollTo({ y: 0 }, 500, 'easeInOutQuad')

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
      })
    }, 450)
  }

  private handleScrollToBottom() {
    this.scroll.scrollTo({ y: '100%' }, 500, 'easeInOutQuad')

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
      })
    }, 450)
  }

  private handleScrollIntoView(selector: string) {
    const elem = document.querySelector(selector)
    if (elem) {
      const { scrollTop } = this.scroll.getPosition()
      const { y } = elem.getBoundingClientRect()
      const scrollYPosition = (scrollTop || 0) + y - 72
      this.scroll.scrollTo({ y: scrollYPosition }, 500, 'easeInOutQuad')
    }
  }

  private birthDate(birthDate: string) {
    const todayDate = `${this.today.getDate()} ${this.today.getMonth() + 1}`
    const userBirthday = `${new Date(birthDate).getDate()} ${new Date(birthDate).getMonth() + 1}`
    this.isUserBirthday = todayDate === userBirthday

    // Анимация дня рождения воспроизводится первый раз?
    if (this.isUserBirthday) {
      if (!Cookies.get(`showBirthdayAnimationFirstTime-${(this.user as unknown as SelfResource).id}`)) {
        this.showBirthdayAnimationFirstTime = true
        Cookies.set(`showBirthdayAnimationFirstTime-${(this.user as unknown as SelfResource).id}`, '1', { expires: 1 })
      }
    } else {
      this.showBirthdayAnimationFirstTime = false
      localStorage.removeItem('birthdayAnimationActive')
    }
  }

  @Watch('$route.fullPath')
  private watchFullPath() {
    this.handleScrollToTop()
    if (this.isManager && this.hasPermission(Permission.SUPPORT_ANSWER) && !Cookies.get('messageCounter')) {
      DictionaryModule.fetchMessageCount()
    }
  }

  @Watch('snackbarText')
  private watchSnackbarText(value: string) {
    if (value) {
      this.$toast.error(value, {
        hideProgressBar: true,
        position: POSITION.BOTTOM_RIGHT,
        timeout: 4000,
      })
      SystemModule.setSnackbarText('')
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Добро пожаловать',
      titleTemplate: '%s - Школа СМИТАП',
    }
  }
}
