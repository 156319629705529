





































import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import UserCard from '@/components/cards/UserCard.vue'
import SystemModule from '@/store/modules/system'
import { SelfResource } from '@/store/types'

@Component({
  components: {
    ButtonArrow,
    UserCard,
  },
})
export default class MobileMenu extends Vue {
  @Prop({ required: true })
  private user!: SelfResource

  @Prop({ default: false })
  private tinyIcon!: boolean

  @Prop({ default: false })
  private isNewYear!: boolean

  @Prop({ default: false })
  private isShowEndYearAnimation!: boolean

  @Prop({ default: false })
  private isShowBirthdayAnimation!: boolean

  private showMenu = false

  private isSnowflakeActive = localStorage.getItem('snowflake') === null ? true : localStorage.getItem('snowflake') === '1'
  private isEndYearAnimationActive = localStorage.getItem('endYearAnimationActive') === null ? true : localStorage.getItem('endYearAnimationActive') === '1'
  private isBirthdayAnimationActive = localStorage.getItem('birthdayAnimationActive') === null ? true : localStorage.getItem('birthdayAnimationActive') === '1'

  private get interface () {
    return SystemModule.interface
  }
}
