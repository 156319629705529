





import { Component, Vue } from 'vue-property-decorator'

import SystemModule from '@/store/modules/system'

@Component
export default class SidenavToggle extends Vue {
  private visible = false

  private get isManager () {
    return SystemModule.interface === 'manager'
  }

  private get icon () {
    const { fullPath } = this.$route

    if (fullPath.indexOf('/profile') === 0) {
      return require('@/assets/images/icons/sidebar/education.svg')
    }
    else if (this.isManager) {
      if (fullPath === '/manager' || fullPath === '/manager/dashboard') {
        return require('@/assets/images/icons/sidebar/education.svg')
      } else if (fullPath.indexOf('/manager/calendar') === 0) {
        return require('@/assets/images/icons/sidebar/calendar.svg')
      } else if (fullPath.indexOf('/manager/users') === 0) {
        return require('@/assets/images/icons/sidebar/users.svg')
      } else if (fullPath.indexOf('/manager/commerce') === 0) {
        return require('@/assets/images/icons/sidebar/orders.svg')
      } else if (fullPath.indexOf('/manager/control') === 0) {
        return require('@/assets/images/icons/sidebar/courses.svg')
      } else if (fullPath.indexOf('/manager/bank') === 0) {
        return require('@/assets/images/icons/sidebar/depository.svg')
      } else if (fullPath.indexOf('/manager/education') === 0) {
        return require('@/assets/images/icons/sidebar/process.svg')
      } else if (fullPath.indexOf('/manager/support') === 0) {
        return require('@/assets/images/icons/sidebar/support.svg')
      }
    } else {
      if (fullPath === '/master' || fullPath === '/master/' || fullPath === '/master/dashboard' || fullPath === '/master/journal') {
        return require('@/assets/images/icons/sidebar/education.svg')
      } else if (fullPath.indexOf('/master/exercises') === 0 || fullPath.indexOf('/master/repetition') === 0) {
        return require('@/assets/images/icons/sidebar/exercises.svg')
      } else if (fullPath.indexOf('/master/courses') === 0 || fullPath.indexOf('/master/lessons') === 0) {
        return require('@/assets/images/icons/sidebar/study.svg')
      } else if (fullPath.indexOf('/master/calendar') === 0) {
        return require('@/assets/images/icons/sidebar/calendar.svg')
      } else if (fullPath.indexOf('/master/depository') === 0) {
        return require('@/assets/images/icons/sidebar/depository.svg')
      } else if (fullPath.indexOf('/master/support') === 0) {
        return require('@/assets/images/icons/sidebar/support.svg')
      } else if (fullPath.indexOf('/master/help') === 0) {
        return require('@/assets/images/icons/sidebar/help.svg')
      } else if (fullPath.includes('/master/progress')) {
        return require('@/assets/images/icons/sidebar/progress.svg')
      }
    }

    return ''
  }

  private mounted () {
    this.$bus.$on('sidenav-toggle', this.handleToggle)
  }

  private handleToggle (value: boolean) {
    this.visible = value
  }

  private handleClick () {
    this.$bus.$emit('sidenav-toggle', !this.visible)
  }
}
