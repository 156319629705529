





import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Компонент спиннера. Управляется из SystemModule.
 * Перед каждым асинхронным запросом увеличивает счетчик на 1, после ответа или ошибки уменьшает счетчик на 1
 * Пока счетчик больше 0, спиннер крутится
 *
 * Для запросов в которых не нужен спиннер (например для асинхронного селекта) нужно подложить параметр loading: false
 * const { data } = await ManagerUsersSearchGetRequest(
 *   { query: payload },
 *   { loading: false },
 * )
 */

@Component
export default class Loader extends Vue {
  @Prop({ default: false })
  private visible!: boolean
}
