export default [
  {
    children: [
      {
        component: () => import('@/views/docs/List.vue'),
        name: 'docs',
        path: '',
      },
      {
        component: () => import('@/views/docs/Offer.vue'),
        name: 'docs.offer',
        path: 'offer',
      },
      {
        component: () => import('@/views/docs/Policy.vue'),
        name: 'docs.policy',
        path: 'policy',
      },
      {
        component: () => import('@/views/docs/Personal.vue'),
        name: 'docs.personal',
        path: 'personal',
      },
    ],
    component: () => import('@/views/Docs.vue'),
    path: '/docs',
  },
]
