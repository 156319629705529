





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import { SaleNotificationResource } from '@/store/types'
// utils
import { randomColorBanner } from '@/utils/functions'

@Component({
  components: {
    Dialog,
    ModalWrapper,
  },
})
export default class DiscountModal extends Vue {
  @Prop({ required: true })
  private discounts!: SaleNotificationResource[]

  @Prop({ default: false })
  private visible!: boolean

  private color = ''

  private swiper: any = null
  private sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 16,
  }

  private mounted() {
    this.color = randomColorBanner()
    this.swiperInit()
  }

  private swiperInit() {
    this.swiper = new Swiper('#discounts-modal-slider', {
      ...this.sliderOptions,
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      loop: this.discounts.length > 1,
      navigation: {
        disabledClass: 'v-btn--disabled',
        nextEl: '.discounts-modal__slider-next',
        prevEl: '.discounts-modal__slider-prev',
      },
      speed: 500,
      modules: [Autoplay, Navigation],
    })
  }

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('closeModal')
  }

  private handleGoStore() {
    this.handleClose()
    if (this.$route.name !== 'master.store') {
      this.$router.push({ name: 'master.store' })
    }
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value) {
      this.color = randomColorBanner()
      setTimeout(() => {
        this.swiperInit()
      }, 10)
    } else {
      this.swiper.destroy()
    }
  }
}
