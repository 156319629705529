import auth from '@/router/_middleware/auth'
import { managePromos } from '@/router/_middleware/manageCommerce'

export default [
  {
    component: () => import('@/views/manager/commerce/promocodes/New.vue'),
    meta: { middleware: [auth, managePromos] },
    name: 'manager.commerce.promocodes.new',
    path: '/manager/commerce/promocodes/new',
  },
  {
    component: () => import('@/views/manager/commerce/promocodes/Edit.vue'),
    meta: { middleware: [auth, managePromos] },
    name: 'manager.commerce.promocodes.edit',
    path: '/manager/commerce/promocodes/:promoId/edit',
  },
  {
    component: () => import('@/views/manager/commerce/promocodes/List.vue'),
    meta: { middleware: [auth, managePromos] },
    name: 'manager.commerce.promocodes',
    path: '/manager/commerce/promocodes',
  },
]
