import auth from '@/router/_middleware/auth'
import { createOrders, viewOrders } from '@/router/_middleware/manageCommerce'

export default [
  {
    component: () => import('@/views/manager/commerce/orders/New.vue'),
    meta: { middleware: [auth, createOrders] },
    name: 'manager.commerce.orders.new',
    path: '/manager/commerce/orders/new',
  },
  {
    component: () => import('@/views/manager/commerce/orders/List.vue'),
    meta: { middleware: [auth, viewOrders] },
    name: 'manager.commerce.orders',
    path: '/manager/commerce/orders',
  },
  {
    component: () => import('@/views/manager/commerce/orders/Item.vue'),
    meta: { middleware: [auth, createOrders, viewOrders] },
    name: 'manager.order.item',
    path: '/manager/orders/:id',
  },
]
