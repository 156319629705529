







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonTextIcon extends Vue {
  @Prop({ default: '$plus' })
  readonly icon!: string

  @Prop({ default: '' })
  readonly iconColor!: string

  @Prop({ default: 'left' })
  readonly iconPosition!: 'left' | 'right'
}
