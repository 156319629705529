




























import { Component, Prop, Vue } from 'vue-property-decorator'

import { SelfResource } from '@/store/types/schema'

@Component
export default class BirthdayModal extends Vue {
  @Prop({ required: true })
  private user!: SelfResource

  private isVisible = false
  private fireworks = [
    {
      size: 19,
      color: '#FFA552',
      position: {
        top: '6px',
        left: '34px',
      },
    },
    {
      size: 29,
      color: '#FFCDD2',
      position: {
        top: '4px',
        right: '7px',
      },
    },
    {
      size: 43,
      color: '#6B9EFF',
      position: {
        bottom: '4px',
        left: '5px',
      },
    },
    {
      size: 26,
      color: '#56C0AD',
      position: {
        bottom: '2px',
        right: '31px',
      },
    },
  ]

  private mounted() {
    this.isVisible = true
  }

  protected handleBirthday () {
    this.isVisible = false
  }
}
