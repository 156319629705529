import { Route } from 'vue-router/types/router'

import { MessageReactionStore } from '@/store/types/schema'

export interface IReactionPayload {
  masterGroupId: number,
  exerciseUuid?: string,
  taskUuid: string,
  masterId?: number,
  messageId: number,
  body: MessageReactionStore,
}

export interface IEditorData {
  blocks: any[],
  time: number,
  version: string,
}

export interface ILink {
  exact?: boolean,
  icon?: string,
  name: string,
  path: string,
}

export interface ITab {
  exact?: boolean,
  icon?: string,
  name: string,
  to?: Route,
}

export type SnackbarType = 'success' | 'error' | 'info'
export interface ISnackbarMessage {
  text: string,
  timeout: number,
  type: SnackbarType,
}

export type ColorHEX = `#${string}`

export interface IRecord {
  blob: Blob,
  duration: string,
  id: number,
  url: string,
}

export enum NotesDrawerView {
  CREATE = 'create',
  EDIT = 'edit',
  LIST = 'list',
  NOTE = 'note',
}

// TODO: обноавить линтер
// export type SchemaResponse<T extends () => Promise<unknown>> =
//   Awaited<ReturnType<T>> extends AxiosResponse ?
//     Awaited<ReturnType<T>>['data'] :
//     unknown
