import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { CharacterGetRequest, CharacterUpdateRequest, CharacterWithItemsAndRanksResource } from '@/store/types/schema.progress'

import MasterProgressModule from './progress'
import MasterCharacterModule from './character'

/**
 * Контракт между модулями - персонажа и рангами
 *
 */

@Module({
  dynamic: true,
  name: 'MasterProgressWithCharacter',
  namespaced: true,
  store,
})
class MasterProgressWithCharacter extends VuexModule {
  isHasCourses = false
  characterWithRanks: CharacterWithItemsAndRanksResource | null = null

  @Mutation
  setChracterWithRanks(value: CharacterWithItemsAndRanksResource) {
    this.characterWithRanks = value
    this.isHasCourses = value.hasCourses
  }

  @Action({ rawError: true })
  public async fetchProgressWithCharacter() {
    const { data } = await CharacterGetRequest()

    this.setChracterWithRanks(data)

    MasterCharacterModule.setCharacter({
      ...data.character,
      equipments: [...data.items.map(item => item.uuid)],
    } as CharacterUpdateRequest)

    MasterCharacterModule.setIsViewedPartingWord(data.character.isViewedPartingWord)

    MasterProgressModule.setCourseRanks(data.ranks)

    return data
  }
}

const MasterProgressWithCharacterModule = getModule(MasterProgressWithCharacter)

export default MasterProgressWithCharacterModule
