import { Component, Vue } from 'vue-property-decorator'

// interface
interface IDocumentIE6 extends Document {
  onfocusin: any,
  onfocusout: any,
}

interface IWindowIsFocusCallbacks {
  onFocus?: () => void,
  onBlur?: () => void,
}

@Component
export default class WindowIsFocusMixin extends Vue {
  protected isWindowInFocus = true

  private onBlur() {
    this.isWindowInFocus = false
  }

  private onFocus(){
    this.isWindowInFocus = true
  }

  protected addListenerForCheckWindowFocus (callbacks?: IWindowIsFocusCallbacks) {
    /* tslint:disable */
    // eslint-disable-next-line
    if (/*@cc_on!@*/false) { // check for Internet Explorer
      (document as IDocumentIE6).onfocusin = () => {
        this.onFocus()
        if (callbacks && callbacks.onFocus) callbacks.onFocus()
      };
      (document as IDocumentIE6).onfocusout = () => {
        this.onBlur()
        if (callbacks && callbacks.onBlur) callbacks.onBlur()
      }
    /* tslint:enable */
    } else {
      window.onfocus = () => {
        this.onFocus()
        if (callbacks && callbacks.onFocus) callbacks.onFocus()
      }
      window.onblur = () => {
        this.onBlur()
        if (callbacks && callbacks.onBlur) callbacks.onBlur()
      }
    }
  }
}
