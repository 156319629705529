import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { CourseSubjectType } from '@/store/types/progress'

@Component
export default class MasterProgressCourseMixin extends Vue {
  protected getTagText(rank: number) {
    return `${rank} уровень`
  }

  protected getRankIcon(rank: number, isSpecial: boolean) {
    const prefix = isSpecial ? 'special_' : ''

    return require(`@/assets/images/progress/ranks/${prefix + rank}.svg`)
  }

  protected getSubjectColor(subject: CourseSubjectType, mode: 'name'|'var' = 'name') {
    const colors = {
      [CourseSubjectType.LAW_SCHOOL]:  mode === 'name' ? 'blue' : 'var(--v-blue-lighten-4)',
      [CourseSubjectType.HISTORY]: mode === 'name' ? 'orange' : 'var(--v-orange-lighten-4)',
      [CourseSubjectType.RUSSIAN_LANGUAGE]: mode === 'name' ? 'teal' : 'var(--v-teal-lighten-4)',
      [CourseSubjectType.ENGLISH_LANGUAGE]: mode === 'name' ? 'grey-pink' : 'var(--v-grey-pink-lighten-4)',
      [CourseSubjectType.LITERATURE]: mode === 'name' ? 'purple' : 'var(--v-purple-lighten-4)',
      [CourseSubjectType.SOCIAL]: mode === 'name' ? 'red' : 'var(--v-red-lighten-4)',
      [CourseSubjectType.OLYMPIAD]: mode === 'name' ? 'brown' : 'var(--v-brown-lighten-4)',
      [CourseSubjectType.OLYMPIAD_SOCIAL_SCIENCE]: mode === 'name' ? 'red' : 'var(--v-red-lighten-4)',
      [CourseSubjectType.BIOLOGY]: mode === 'name' ? 'green' : 'var(--v-green-lighten-4)',
      [CourseSubjectType.CHEMISTRY]: mode === 'name' ? 'deep-purple' : 'var(--v-deep-purple-lighten-4)',
      [CourseSubjectType.MATHEMATICS]: mode === 'name' ? 'blue' : 'var(--v-blue-lighten-4)',
      [CourseSubjectType.PHYSICS]: mode === 'name' ? 'indigo' : 'var(--v-indigo-lighten-4)',
      [CourseSubjectType.INFORMATICS]: mode === 'name' ? 'turquoise' : 'var(--v-turquoise-lighten-4)',
    }
    return colors[subject]
  }

  protected getCSSColor(color: string, transparency: string) {
    const cssVariableName = `--v-${color}-${transparency}`

    return getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariableName)
  }
}
