




































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import PackageRadioSelect from '@/components/PackageRadioSelect.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterCoursesModule from '@/store/modules/master/courses'
import MasterOrdersModule from '@/store/modules/master/orders'
import { CourseType, MasterOrderLargeResource, PurchaseLargeResource } from '@/store/types'

@Component({
  components: {
    Dialog,
    FilesList,
    ModalWrapper,
    PackageRadioSelect,
    Tag,
  },
})
export default class CourseProlongationModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private courseID!: number

  private course: PurchaseLargeResource | null = null
  private packageId = 0

  private get group () {
    return this.course ? this.course.groups[0] : null
  }

  private get isSpecial(): boolean {
    return Boolean(this.course?.type.value === CourseType.SPECIAL)
  }

  private mounted () {
    this.fetchCourse(this.courseID)
  }

  private fetchCourse (courseID: number) {
    MasterCoursesModule.fetchCourseProlongation(courseID)
      .then((response: PurchaseLargeResource) => {
        this.course = Object.assign({}, response)
        if (response.groups.length === 1 && response.groups[0].packages.length === 1) {
          this.packageId = response.groups[0].packages[0].id
        }
      })
      .catch(this.notifyError)
  }

  private handleOffer () {
    if (!this.packageId) {
      this.notifyError('Выберите период обучения')
      return
    }

    if (this.group) {
      MasterOrdersModule.createOrder({
        courseId: this.courseID,
        groupId: this.group.id,
        packageId: this.packageId,
      })
        .then((response: MasterOrderLargeResource) => {
          this.$emit('close')
          this.$router.push({ name: 'master.payment.order', params: { orderUUID: response.uuid } })
        })
        .catch (this.notifyError)
    }
  }

  private handleClose () {
    this.course = null
    this.$emit('close')
    this.$emit('update:courseID', null)
  }

  @Watch('courseID')
  private watchCourseID (value: number, oldValue: number) {
    if (value && value !== oldValue) {
      this.fetchCourse(value)
    }
  }
}
