import auth from '@/router/_middleware/auth'
import { manageControl } from '@/router/_middleware/manageControl'

import coursesRoutes from './control/courses'
import groupsRoutes from './control/groups'
import newsRoutes from './control/news'

export default [
  {
    children: [
      ...coursesRoutes,
      ...groupsRoutes,
      ...newsRoutes,
    ],
    component: () => import('@/views/manager/Control.vue'),
    meta: { middleware: [auth, manageControl] },
    path: '/manager/control',
  },
]
