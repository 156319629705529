import '@/utils/services/hooks'

import ClipboardJS from 'clipboard'
import { Settings } from 'luxon'
import { POSITION } from 'vue-toastification'
import Vue from 'vue'
import VueYandexMetrika from 'vue-yandex-metrika'

import '@/styles/base.scss'
import router from '@/router'
import store from '@/store'
import vuetify from '@/utils/plugins/vuetify'
import '@/utils/directives'
import '@/utils/plugins'

import App from './App.vue'

Vue.use(VueYandexMetrika, {
  debug: true,
  env: process.env.VUE_APP_YM_ENV,
  id: Number(process.env.VUE_APP_YM_ID),
})

Vue.config.productionTip = false

Settings.defaultLocale = 'ru'
Settings.defaultZoneName = 'Europe/Moscow'

Object.defineProperty(Vue.prototype,'$bus', {
  get: function () {
    return this.$root.bus
  },
})

new Vue({
  data: {
    bus: new Vue({}),
    clipboard: new ClipboardJS('.clipboard'),
  },
  mounted () {
    // Пример использования: <div class="clipboard" data-clipboard-text="Some text">Another text</div>
    this.clipboard.on('success', (event) => {
      this.$toast.info('Скопировано в буфер обмена', {
        hideProgressBar: true,
        position: POSITION.BOTTOM_RIGHT,
        timeout: 4000,
      })
      event.clearSelection()
    })
  },
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount('#app')
