import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

// Права на управление разделом "Банки"
export function manageBanks ({ next, nextMiddleware }: any) {
  if (![
    Permission.DEPOSITORY_MATERIALS_VIEW,
    Permission.DEPOSITORY_MANUAL_VIEW,
    Permission.BANK_PERSONAL_VIEW,
    Permission.BANK_BASE_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на управление разделом "База знаний"
export function manageDepository ({ next, nextMiddleware }: any) {
  if (![
    Permission.DEPOSITORY_MATERIALS_VIEW,
    Permission.DEPOSITORY_MATERIALS_EDIT,
    Permission.DEPOSITORY_MANUAL_VIEW,
    Permission.DEPOSITORY_MANUAL_EDIT,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр материалов курсов в базе знаний
export function viewMaterials ({ next, nextMiddleware }: any) {
  if (![
    Permission.DEPOSITORY_MATERIALS_VIEW,
    Permission.DEPOSITORY_MATERIALS_EDIT,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование материалов курсов в базе знаний
export function editMaterials ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.DEPOSITORY_MATERIALS_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр методичек
export function viewManuals ({ next, nextMiddleware }: any) {
  if (![
    Permission.DEPOSITORY_MANUAL_VIEW,
    Permission.DEPOSITORY_MANUAL_EDIT,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование методичек
export function editManuals ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.DEPOSITORY_MANUAL_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр банка домашних заданий
export function viewExercises ({ next, nextMiddleware }: any) {
  if (![
    Permission.BANK_PERSONAL_VIEW,
    Permission.BANK_BASE_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование банка домашних заданий
export function editExercises ({ next, nextMiddleware }: any) {
  if (![
    Permission.BANK_BASE_EDIT,
    Permission.BANK_PERSONAL_EDIT,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
