import Vue from 'vue'
import { Centrifuge } from 'centrifuge'

const transports = [
  {
    endpoint: `${process.env.VUE_APP_WEBSOCKETS_ENDPOINT}/connection/websocket`,
    transport: 'websocket',
  },
  {
    endpoint: `${process.env.VUE_APP_BACKEND_ENDPOINT}/broadcasting/auth`,
    transport: 'http_stream',
  },
]

const centrifuge = new Centrifuge(
  transports,
)

centrifuge.connect()

Vue.prototype.$centrifuge = centrifuge

export default Vue

