import axios from 'axios'

import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { baseURL, getToken } from '@/utils/services/config'
import { clearStorage, getQueryStringFromParams } from '@/utils/functions'
import router from '@/router'

const camelCaseKeys = require('camelcase-keys')
const snakeCaseKeys = require('snakecase-keys')

const api = axios.create({
  paramsSerializer (params) {
    return getQueryStringFromParams(params)
  },
})

api.interceptors.request.use(
  config => {
    // В params запросов, для которых не нужно показывать спиннер, надо подкладывать loading: false
    // Если этого поля нет, то спиннер показывается
    if (config.params && (config.params.loading === undefined || config.params.loading === true)) {
      SystemModule.loadingStart()
    }

    // По просьбе Даниила подкладываем роль юзера (тип интерфейса)
    const lsInterface = localStorage.getItem('interface')
    if (lsInterface) {
      config.headers['User-Type'] = lsInterface
    }

    if (process.env.NODE_ENV === 'production') {
      config.withCredentials = true
    } else {
      const authToken = getToken()

      config.headers['User-Type'] = SystemModule.interface

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }
    }

    if (config.data) {
      config.data = snakeCaseKeys(config.data)
    }

    if (config.params) {
      // Убираем "лишние" параметры из компонента v-data-table
      config.params = snakeCaseKeys(config.params)
    }

    config.baseURL = baseURL

    return config
  },
  reason => {
    SystemModule.loadingEnd()
    return Promise.reject(reason)
  },
)

api.interceptors.response.use(
  response => {
    if (response.data) {
      response.data = camelCaseKeys(response.data, { deep: true })
    }

    SystemModule.loadingEnd()

    return response
  },
  reason => {
    SystemModule.loadingEnd()
    if (reason.response && reason.response.status === 401) {
      AuthModule.clearSelf()
      clearStorage()
      if (router.currentRoute.name !== 'auth' && router.currentRoute.name !== 'auth.login' && router.currentRoute.name !== 'offer' && router.currentRoute.name !== 'master.payment.order')
        router.push({ name: 'auth', query: { redirect: router.currentRoute.fullPath } })
    }
    return Promise.reject(reason)
  },
)

export default api
