import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  CourseShopCardResource,
  EducationCourseShortResource,
  IMasterCoursesFilter,
  MasterCoursesCourseIdGetRequest,
  MasterCourseShortResource,
  MasterCoursesPurchaseCourseIdContinueGetRequest,
  MasterCoursesPurchaseCourseIdInfoGetRequest,
  MasterCoursesSelfGetRequest,
  MasterCoursesSelfSelectGetRequest,
  MasterCoursesShopGetRequest,
  MasterBannerActiveSalesGetRequest,
  SaleBannerResource,
  MasterBannerCloseGetRequest,
  MasterCoursesCourseIdRecommendationsGetRequest,
} from '@/store/types'

/**
 * Курсы доступные мастеру
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterCourses',
  namespaced: true,
  store,
})
class MasterCourses extends VuexModule {
  // ---------------------------- My courses ---------------------------- >>
  myCourses: EducationCourseShortResource[] = []

  @Mutation
  setMyCourses (payload: EducationCourseShortResource[]) {
    this.myCourses = [...payload]
  }

  @Action({ rawError: true })
  async fetchMyCourses () {
    const { data } = await MasterCoursesSelfGetRequest()
    this.setMyCourses(data)
    return data
  }

  // Manage
  @Action({ rawError: true })
  async fetchCourseProlongation (courseID: number) {
    const { data } = await MasterCoursesPurchaseCourseIdContinueGetRequest(courseID)
    return data
  }

  // ---------------------------- All courses ---------------------------- >>
  allCourses: MasterCourseShortResource[] = []
  coursesFilter: IMasterCoursesFilter = {}
  storeCourse: CourseShopCardResource | null = null

  @Mutation
  setAllCourses (payload: MasterCourseShortResource[]) {
    this.allCourses = [...payload]
  }

  @Mutation
  setCoursesFilter(payload: IMasterCoursesFilter) {
    this.coursesFilter = Object.assign({}, payload)
  }

  @Mutation
  setStoreCourse(payload: CourseShopCardResource | null) {
    this.storeCourse = payload
  }

  @Action({ rawError: true })
  async fetchAllCourses () {
    const { data } = await MasterCoursesShopGetRequest()
    this.setAllCourses(data)
    return data
  }

  @Action({ rawError: true })
  async fetchStoreCourse(courseID: number) {
    const { data } = await MasterCoursesCourseIdGetRequest(courseID)
    this.setStoreCourse(data)
    return data
  }

  // Manage
  @Action({ rawError: true })
  async fetchCourseInfo (courseID: number) {
    const { data } = await MasterCoursesPurchaseCourseIdInfoGetRequest(courseID)
    return data
  }

  // ---------------------------- User's courses ---------------------------- >>
  @Action({ rawError: true })
  async fetchSelfCourses () {
    const { data } = await MasterCoursesSelfSelectGetRequest()
    return data
  }

  // ---------------------------- User's discounts ---------------------------- >>
  usersDiscounts: SaleBannerResource[] = []


  @Mutation
  setUsersDiscounts(payload: SaleBannerResource[]) {
    this.usersDiscounts = [...payload]
  }

  @Action({ rawError: true })
  async fetchUsersDiscounts () {
    const { data } = await MasterBannerActiveSalesGetRequest()
    this.setUsersDiscounts(data)
    return data
  }

  @Action({ rawError: true })
  async closeBanner() {
    const { data } = await MasterBannerCloseGetRequest()
    return data
  }

  // ---------------------------- Recommendations ---------------------------- >>

  @Action({ rawError: true })
  async fetchRecommendations(courseID: number, options?: any) {
    const { data } = await MasterCoursesCourseIdRecommendationsGetRequest(courseID, options)
    return data
  }
}

const MasterCoursesModule = getModule(MasterCourses)

export default MasterCoursesModule
