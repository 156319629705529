













import { Component, Prop, Vue } from 'vue-property-decorator'

import Back from '@/components/_uikit/Back.vue'
import Breadcrumbs from '@/components/_uikit/Breadcrumbs.vue'
import { ILink } from '@/store/types'

@Component({
  components: {
    Back,
    Breadcrumbs,
  },
})
export default class PageHeader extends Vue {
  @Prop({ default: null })
  private title!: string

  @Prop({ default: () => ([]) })
  private breadcrumbs!: ILink[]

  @Prop({ default: null })
  private back!: number
}
