import { viewMasters, viewUser } from '@/router/_middleware/manageUsers'
import auth from '@/router/_middleware/auth'

export default [
  {
    children: [
      {
        component: () => import('@/views/manager/users/masters/item/Information.vue'),
        meta: { middleware: [auth, viewUser] },
        name: 'manager.users.masters.item',
        path: '',
      },
      {
        component: () => import('@/views/manager/users/masters/item/Orders.vue'),
        meta: { middleware: [auth, viewUser] },
        name: 'manager.users.masters.item.orders',
        path: '/manager/users/masters/:masterUUID/orders',
      },
    ],
    component: () => import('@/views/manager/users/masters/Item.vue'),
    meta: { middleware: [auth, viewUser] },
    path: '/manager/users/masters/:masterUUID',
  },
  {
    component: () => import('@/views/manager/users/masters/List.vue'),
    meta: { middleware: [auth, viewMasters] },
    name: 'manager.users.masters',
    path: '/manager/users/masters',
  },
]
