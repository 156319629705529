import Vue from 'vue'
import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'

export const plyrConfig = {
  controls: ['play-large', 'play', 'rewind', 'fast-forward', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
  fullscreen: {
    iosNative: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
  },
  i18n: {
    advertisement: 'Реклама',
    all: 'Все',
    buffered: 'Buffered',
    captions: 'Captions',
    currentTime: 'Текущее время',
    disableCaptions: 'Disable captions',
    disabled: 'Отключено',
    download: 'Скачать',
    duration: 'Продолжительность',
    enableCaptions: 'Enable captions',
    enabled: 'Доступно',
    end: 'Конец',
    enterFullscreen: 'Полноэкранный режим (F)',
    exitFullscreen: 'Выйти из полноэкранного режима (F)',
    fastForward: '{seektime} сек. вперёд (→)',
    frameTitle: 'Player for {title}',
    loop: 'Зациклить',
    menuBack: 'Вернуться назад',
    mute: 'Отключить звук (M)',
    normal: 'Нормальная',
    pause: 'Пауза (Пробел)',
    pip: 'В отдельном окне',
    play: 'Запуск (Пробел)',
    played: 'Воспроизводится',
    quality: 'Качество',
    qualityBadge: {
      480: 'SD',
      576: 'SD',
      720: 'HD',
      1080: 'HD',
      1440: 'HD',
      2160: '4K',
    },
    reset: 'Обновить',
    restart: 'Перезапустить',
    rewind: '{seektime} сек. назад (←)',
    seek: 'Seek',
    seekLabel: '{currentTime} из {duration}',
    settings: 'Настройки',
    speed: 'Скорость',
    start: 'Начало',
    unmute: 'Включить звук (M)',
    volume: 'Громкость',
  },
  invertTime: false,
  keyboard: {
    focused: true,
    global: true,
  },
  quality: {
    default: 720,
  },
  speed: {
    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3, 4],
    selected: 1,
  },
  storage: {
    enabled: false,
  },
  tooltips: {
    controls: true,
    seek: true,
  },
  youtube: {
    isLoadPolicy: 3,
    modestbranding: 1,
    noCookie: false,
    rel: 0,
    showinfo: 0,
  },
}

Vue.use(VuePlyr, {
  plyr: plyrConfig,
})
