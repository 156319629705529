import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'

/**
* Работа с состоянием текстового редактора
* - запись и чтение активных подменю (выбор цвета/маркера, подменю таблиц, модалки таблиц/ссылки/картинки)
*/

export interface FocusMenu {
  colorChoose: boolean,
  imageModal: boolean,
  linkModal: boolean,
  markerChoose: boolean,
  tableChoose: boolean,
  tableModal: boolean,
}

@Module({
  dynamic: true,
  name: 'Editor',
  namespaced: true,
  store,
})
class Editor extends VuexModule {
  focusMenu: FocusMenu = {
    colorChoose: false,
    imageModal: false,
    linkModal: false,
    markerChoose: false,
    tableChoose: false,
    tableModal: false,
  }

  @Mutation
  setFocusMenu(payload: { key: keyof FocusMenu, value: boolean }) {
    // fix sentry issue:
    // https://sn.atwinta.online/organizations/atwinta/issues/30028/?project=22&query=&referrer=project-issue-stream
    // https://sn.atwinta.online/organizations/atwinta/issues/30032/?project=22&query=&referrer=project-issue-stream
    if (this.focusMenu)
      this.focusMenu[payload.key] = payload.value
  }
}

const EditorModule = getModule(Editor)

export default EditorModule
