import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Vue from 'vue'

import store from '@/store'
import {
  AchievementResource,
  AchievementsGetRequest,
  AchievementsUsersGetRequest,
} from '@/store/types/schema.progress'

/**
 * Достижения мастера
 */

@Module({
  dynamic: true,
  name: 'MasterProgressAchievements',
  namespaced: true,
  store,
})
class MasterProgressAchievements extends VuexModule {
  achievements: AchievementResource[] = []
  usersAchievements: AchievementResource[] = []

  @Mutation
  setAchievements (payload: AchievementResource[]) {
    this.achievements = payload
  }

  @Mutation
  setUsersAchievements (payload: AchievementResource[]) {
    this.usersAchievements = payload
  }

  @Action({ rawError: true })
  async getAchievements () {
    const { data } = await AchievementsGetRequest()

    this.setAchievements(data)

    return data
  }

  @Action({ rawError: true })
  async getUsersAchievements () {
    const { data } = await AchievementsUsersGetRequest()

    this.setUsersAchievements(data)

    return data
  }

  // --------------------------------------- Toasts Buffer ---------------------------------------->
  toastsBuffer: AchievementResource[] = []

  @Mutation
  setToastsBuffer (payload: AchievementResource[]) {
    Vue.set(this, 'toastsBuffer', payload)
  }

  @Action({ rawError: true })
  addToastToBuffer (payload: AchievementResource) {
    this.setToastsBuffer([...this.toastsBuffer, payload])
  }

  @Action({ rawError: true })
  removeItemsFromToastsBuffer (itemsSlugs: string[]) {
    const result = this.toastsBuffer.filter(item => !itemsSlugs.includes(item.slug))

    this.setToastsBuffer(result)
  }

  @Action({ rawError: true })
  clearToastsBuffer () {
    this.setToastsBuffer([])
  }
}

const MasterProgressAchievementsModule = getModule(MasterProgressAchievements)

export default MasterProgressAchievementsModule
