









import { Component, Prop, Vue } from 'vue-property-decorator'

import { SocialType, UserShortResource } from '@/store/types'
import { thumbs } from '@/utils/constants'

@Component({
  inheritAttrs: false,
})
export default class Avatar extends Vue {
  @Prop({ required: true })
  private user!: UserShortResource

  @Prop({ default: SocialType.VKONTAKTE })
  private socialMediaName!: string

  @Prop({ default: false })
  private showPartyHat!: boolean

  @Prop({ default: true })
  private showSocialIcon!: boolean

  @Prop({ default: true })
  private withSocialBadge!: boolean

  @Prop({
    default: 'md',
    validator (value: string): boolean {
      return !!value.match(/(sm|md|lg)/)
    },
  })
  private size!: 'sm' | 'md' | 'lg'

  private thumbs = thumbs

  private get sizeValue () {
    switch (this.size) {
    case 'sm': return 30
    case 'md': return 40
    case 'lg': return 64
    default: return 40
    }
  }

  private get socialImage () {
    return require(`@/assets/images/social/${this.socialMediaName}.png`)
  }
}
