import AuthModule from '@/store/modules/auth'

// Проверка авторизован ли пользователь
export default async function auth ({ next, nextMiddleware, to }: any) {
  if (!AuthModule.self) {
    return next({ name: 'auth.login', query: { redirect: to.fullPath } })
  }

  return nextMiddleware()
}
