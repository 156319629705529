import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { IProgress, IProgressExtended } from '@/mixins/master/progress/MasterProgressMixin'
import {
  CourseCourseIdRanksGetRequest,
  CourseCourseIdRanksStatisticsGetRequest,
  CourseCourseIdStatisticsGetRequest, CourseInfoResource, UserCoursesGetRequest,
  UserCourseStatisticResource,
  UserRankResource,
  UserRankWithInfoResource,
  UserUpdatesGetRequest,
} from '@/store/types/schema.progress'

/**
 * Модуль для работы с прогрессом ученика
 */

@Module({
  dynamic: true,
  name: 'MasterProgress',
  namespaced: true,
  store,
})
class MasterProgress extends VuexModule {
  coursesRanks: UserRankResource[] = []

  @Mutation
  setCourseRanks(coursesRanks: UserRankResource[]) {
    this.coursesRanks = [...coursesRanks]
  }

  courseStatistic: UserCourseStatisticResource | null = null
  courseRanksProgress: UserRankWithInfoResource[] = []

  @Mutation
  setCourseStatistic(courseStatistic: UserCourseStatisticResource) {
    this.courseStatistic = courseStatistic
  }

  @Mutation
  unsetCourseStatistic() {
    this.courseStatistic = null
  }

  @Mutation
  setRanksProgress(courseRanksProgress: UserRankWithInfoResource[]) {
    this.courseRanksProgress = [...courseRanksProgress]
  }

  @Action({ rawError: true })
  async fetchCourseProgressStatistic(payload: {courseId: number, loading?: boolean}) {
    const { data } = await CourseCourseIdStatisticsGetRequest(
      payload.courseId,
      {
        loading: payload.loading,
      },
    )

    this.setCourseStatistic(data)

    return data
  }

  @Action({ rawError: true })
  async fetchCourseRanksProgressStatistic(courseId: number) {
    const { data } = await CourseCourseIdRanksStatisticsGetRequest(courseId)

    this.setRanksProgress(data)

    return data
  }

  @Action({ rawError: true })
  async fetchCourseRanksData(courseId: number) {
    const { data } = await CourseCourseIdRanksGetRequest(courseId)
    this.setCourseRanks(data)
    return data
  }

  courses: IProgress[] = [
    {
      courseId: 1,
      description: 'Курс по Юридической  школе',
      fullDescription: 'Ты достиг многих поставленных целей, поэтому присуждаем тебе звание Достигатор. Почет и уважение прилагаются.',
      hasStarted: true,
      name: 'Отличник',
      rank: 6,
      subject: 'law',
    },
    {
      courseId: 2,
      description: 'Подготовка к Истории',
      fullDescription: 'Ты достиг многих поставленных целей, поэтому присуждаем тебе звание Достигатор. Почет и уважение прилагаются.',
      hasStarted: true,
      name: 'Почемучка',
      rank: 4,
      subject: 'history',
    },
    {
      courseId: 3,
      description: 'Спецкурс по Литературе',
      fullDescription: 'Ты достиг многих поставленных целей, поэтому присуждаем тебе звание Достигатор. Почет и уважение прилагаются.',
      hasStarted: true,
      name: 'Спецкурсник',
      rank: 1,
      subject: 'literature',
    },
    {
      courseId: 4,
      description: 'Олимпиада по Обществознанию',
      fullDescription: 'Ты достиг многих поставленных целей, поэтому присуждаем тебе звание Достигатор. Почет и уважение прилагаются.',
      hasStarted: true,
      name: 'Энтузиаст',
      rank: 2,
      subject: 'social-science',
    },
    {
      courseId: 5,
      description: 'Подготовка к ЕГЭ по Русскому языку',
      fullDescription: 'Ты достиг многих поставленных целей, поэтому присуждаем тебе звание Достигатор. Почет и уважение прилагаются.',
      hasStarted: true,
      name: 'Хорошист',
      rank: 5,
      subject: 'russian-language',
    },
  ]

  coursesFull: IProgressExtended[] = this.courses.map(course => {
    return {
      ...course,
      exercisesToComplete: 4,
      lessonsToComplete: 3,
    }
  })

  currentCourseId = 1

  @Mutation
  setCurrentCourseId(courseID: number) {
    this.currentCourseId = courseID
  }

  get currentCourse() {
    return this.coursesFull.find(item => item.courseId === this.currentCourseId)
  }


  // --------------------------------------- First Visit ---------------------------------------- >>
  @Action({ rawError: true })
  async checkUpdates() {
    const { data } = await UserUpdatesGetRequest({ loading: false })

    return data
  }

  // --------------------------------------- Courses List ---------------------------------------- >>
  coursesList: CourseInfoResource[] = []

  @Mutation
  setCoursesList(payload: CourseInfoResource[]) {
    this.coursesList = payload
  }

  @Action({ rawError: true })
  async fetchUserCourses() {
    const { data } = await UserCoursesGetRequest()
    this.setCoursesList(data)
    return data
  }
}

const MasterProgressModule = getModule(MasterProgress)

export default MasterProgressModule
