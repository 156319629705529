import auth from '@/router/_middleware/auth'
import { viewMasterGroups } from '@/router/_middleware/manageControl'

export default [
  {
    children: [
      {
        component: () => import('@/views/manager/control/groups/List.vue'),
        meta: { middleware: [auth, viewMasterGroups] },
        name: 'manager.control.groups.list',
        path: '/manager/control/groups/list',
      }, {
        component: () => import('@/views/manager/control/groups/Masters.vue'),
        meta: { middleware: [auth, viewMasterGroups] },
        name: 'manager.control.groups.masters',
        path: '/manager/control/groups/masters',
      },
    ],
    component: () => import('@/views/manager/control/groups/Groups.vue'),
    meta: { middleware: [auth, viewMasterGroups] },
    name: 'manager.control.groups',
    path: '/manager/control/groups',
  },
]
