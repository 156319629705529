




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// types
import { IImageForm } from '@/components/_uikit/editor/tiptap/ImageModal.vue'

@Component({
  components: {
    ButtonTextIcon,
    TextInput,
    ValidationProvider,
  },
})
export default class UploadImageForm extends Vue {
  @Prop({ required: true })
  private form!: IImageForm

  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: 0 })
  private currentItem!: number

  @Watch('form.url')
  private watchUrl(value: string) {
    const type = value.match(/\.\w+$/m)
    if (!this.form.alt.length && type && type[0].toLowerCase() === '.heic')
      this.$emit('heicAlt')
    if (type && type[0] !== '.heic' && this.form.alt === 'формат изображения heic')
      this.$emit('clearAlt')
  }
}
