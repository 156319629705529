import Vue from 'vue'
import * as Sentry from '@sentry/vue'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    beforeSend: event => {
      const exception = event.exception?.values && event.exception?.values[0]
      if (exception?.value &&
        (exception.value === 'Non-Error exception captured with keys: currentTarget, detail, isTrusted, target' ||
        exception.value === 'undefined is not an object (evaluating \'e.duration.toString\')' ||
        exception.value.includes('AbortError: The play() request was interrupted by a call to pause().'))
      ) {
        return null
      }
      return event
    },
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: process.env.VUE_APP_SENTRY_RATE,
  })
}

