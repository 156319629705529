import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { EducationLargeTaskDecideRepetitionQuestionRequest, EducationLargeTaskRepetitionResource, MasterCoursesAmbulanceGetRequest, MasterDashboardCourseIdGetRequest, MasterDashboardResource, MasterEducationMasterGroupIdExercisesRepetitionGetRequest, MasterEducationMasterGroupIdExercisesRepetitionPutRequest } from '@/store/types'

/**
 * Главная страница мастера
 *
 */

@Module({
  dynamic: true,
  name: 'MasterDashboardModule',
  namespaced: true,
  store,
})
class MasterDashboard extends VuexModule {
  // ---------------------------- Dashboard Master ---------------------------- >>
  dashboard: MasterDashboardResource | null = null

  findIndexCourse = -1

  @Mutation
  setDashboard(payload: MasterDashboardResource) {
    this.dashboard = payload
  }

  @Mutation
  setFindIndex(payload: number) {
    this.findIndexCourse = payload
  }

  @Action({ rawError: true })
  async fetchDashboard(courseId: number) {
    const { data } = await MasterDashboardCourseIdGetRequest(courseId)
    this.setDashboard(data)
    return data
  }

  // ---------------------------- Repetition Test Master ---------------------------- >>
  repetitionTest: EducationLargeTaskRepetitionResource | null = null

  @Mutation
  setRepetitionTest(payload: EducationLargeTaskRepetitionResource) {
    this.repetitionTest = payload
  }

  @Mutation
  unsetRepetitionTest() {
    this.repetitionTest = null
  }

  // Получить/создать тест повторение
  @Action({ rawError: true })
  async fetchRepetitionTest(masterGroupId: number) {
    const { data } = await MasterEducationMasterGroupIdExercisesRepetitionGetRequest(masterGroupId)
    this.setRepetitionTest(data)
    return data
  }

  @Action({ rawError: true })
  async sendRepetitionTest(payload: { body: EducationLargeTaskDecideRepetitionQuestionRequest, masterGroupId: number }) {
    const { data } = await MasterEducationMasterGroupIdExercisesRepetitionPutRequest(payload.masterGroupId, payload.body)
    return data
  }

  // Получение достижения за посещение скорой помощи
  @Action({ rawError: true })
  async ambulanceAchievement() {
    const { data } = await MasterCoursesAmbulanceGetRequest()
    return data
  }
}

const MasterDashboardModule = getModule(MasterDashboard)

export default MasterDashboardModule
