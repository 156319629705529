import { viewEmployees, viewUser } from '@/router/_middleware/manageUsers'
import auth from '@/router/_middleware/auth'

export default [
  {
    children: [
      {
        component: () => import('@/views/manager/users/employees/item/Information.vue'),
        meta: { middleware: [auth, viewUser] },
        name: 'manager.users.employees.item',
        path: '',
      },
    ],
    component: () => import('@/views/manager/users/employees/Item.vue'),
    meta: { middleware: [auth, viewUser] },
    path: '/manager/users/employees/:employeeUUID',
  },
  {
    component: () => import('@/views/manager/users/employees/List.vue'),
    meta: { middleware: [auth, viewEmployees] },
    name: 'manager.users.employees',
    path: '/manager/users/employees',
  },
]
