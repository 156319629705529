import AuthModule from '@/store/modules/auth'
import { hasPermission } from '@/utils/functions'
import { Permission } from '@/store/types'
import SystemModule from '@/store/modules/system'

// Права на управление разделом "Курсы"
export function manageControl ({ next, nextMiddleware }: any) {
  if (![
    Permission.COURSE_VIEW,
    Permission.MG_VIEW_ALL,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр курсов
export function viewCourses ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.COURSE_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование курсов
export function editCourse ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.COURSE_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на удаление курсов и групп курсов
export function deleteCourse ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.COURSE_DELETE, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование и удаление домашних заданий курса
export function editCourseExercise ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.COURSE_EXERCISES_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на редактирование и удаление уроков курса
export function editCourseLesson ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.COURSE_LESSONS_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права просмотр мастер-групп
export function viewMasterGroups ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MG_VIEW_ALL, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка новостей
export function viewNews({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.NEWS_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на создание/редактирование/удаление новостей
export function editNews({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.NEWS_EDIT, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
