

































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

@Component
export default class ModalWrapper extends Vue {
  @Ref() scrollModal!: vuescroll

  @Prop({ required: true })
  private title!: string

  @Prop({ default: true })
  private buttonCancelVisible!: boolean

  @Prop({ default: 'Вернуться' })
  private buttonCancelText!: string

  @Prop({ default: true })
  private buttonConfirmVisible!: boolean

  @Prop({ default: 'Сохранить' })
  private buttonConfirmText!: string

  @Prop({ default: false })
  private buttonConfirmDisabled!: boolean

  @Prop({ default: false })
  private buttonReverse!: boolean

  @Prop({ default: 'secondary' })
  private skin!: 'primary'|'secondary'|'teal'

  @Prop({ default: false })
  private hideDefaultFooter!: boolean

  @Prop({ default: false })
  private hideDefaultClose!: boolean

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private mounted() {
    this.$bus.$on('scrollIntoViewModal', this.handleScrollIntoView)
  }

  private destroyed() {
    this.$bus.$off('scrollIntoViewModal', this.handleScrollIntoView as any)
  }

  private handleScrollIntoView(selector: string) {
    const elem = document.querySelector(selector)
    const modalElem = document.querySelector('.v-dialog')
    if (elem && modalElem) {
      const { scrollTop } = this.scrollModal.getPosition()
      const { y } = elem.getBoundingClientRect()
      const modalElemY = modalElem.getBoundingClientRect().y
      const scrollYPosition = (scrollTop || 0) + y - modalElemY
      this.scrollModal.scrollTo({ y: scrollYPosition }, 500, 'easeInOutQuad')
    }
  }
}
