import { render, staticRenderFns } from "./ButtonArrow.vue?vue&type=template&id=4f6b28ba&scoped=true&lang=pug&"
import script from "./ButtonArrow.vue?vue&type=script&lang=ts&"
export * from "./ButtonArrow.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonArrow.vue?vue&type=style&index=0&id=4f6b28ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6b28ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
