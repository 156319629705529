import {
  editCourse,
  editCourseExercise,
  editCourseLesson,
  viewCourses,
} from '@/router/_middleware/manageControl'
import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/manager/control/courses/New.vue'),
    meta: { middleware: [auth, editCourse] },
    name: 'manager.control.courses.new',
    path: '/manager/control/courses/new',
  },
  {
    meta: { middleware: [auth, viewCourses] },
    name: 'manager.control.courses.item.program',
    path: '/manager/control/courses/:courseID/program',
    redirect: '/manager/control/courses',
  },
  {
    component: () => import('@/views/manager/control/courses/item/program/lessons/New.vue'),
    meta: { middleware: [auth, editCourseLesson] },
    name: 'manager.control.courses.item.program.month.lessons.new',
    path: '/manager/control/courses/:courseID/program/:monthID/lessons/new',
  },
  {
    component: () => import('@/views/manager/control/courses/item/program/lessons/Edit.vue'),
    meta: { middleware: [auth, editCourseLesson] },
    name: 'manager.control.courses.item.program.month.lessons.item.edit',
    path: '/manager/control/courses/:courseID/program/:monthID/lessons/:lessonID',
  },
  {
    component: () => import('@/views/manager/control/courses/item/program/exercises/New.vue'),
    meta: { middleware: [auth, editCourseExercise] },
    name: 'manager.control.courses.item.program.month.exercises.new',
    path: '/manager/control/courses/:courseID/program/:monthID/exercises/new',
  },
  {
    component: () => import('@/views/manager/control/courses/item/program/exercises/Edit.vue'),
    meta: { middleware: [auth, editCourseExercise] },
    name: 'manager.control.courses.item.program.month.exercises.item.edit',
    path: '/manager/control/courses/:courseID/program/:monthID/exercises/:exerciseUUID',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/control/courses/item/program/month/Lessons.vue'),
        meta: { middleware: [auth, viewCourses] },
        name: 'manager.control.courses.item.program.month.lessons',
        path: '/manager/control/courses/:courseID/program/:monthID/lessons',
      },
      {
        component: () => import('@/views/manager/control/courses/item/program/month/Exercises.vue'),
        meta: { middleware: [auth, viewCourses] },
        name: 'manager.control.courses.item.program.month.exercises',
        path: '/manager/control/courses/:courseID/program/:monthID/exercises',
      },
    ],
    component: () => import('@/views/manager/control/courses/item/program/Month.vue'),
    meta: { middleware: [auth, viewCourses] },
    name: 'manager.control.courses.item.program.month',
    path: '/manager/control/courses/:courseID/program/:monthID',
  },
  {
    children: [
      {
        children: [
          {
            component: () => import('@/views/manager/control/courses/item/group/Common.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group',
            path: '',
          },
          {
            component: () => import('@/views/manager/control/courses/item/group/Description.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group.description',
            path: '/manager/control/courses/:courseID/:groupType/description',
          },
          {
            component: () => import('@/views/manager/control/courses/item/group/Prices.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group.prices',
            path: '/manager/control/courses/:courseID/:groupType/prices',
          },
          {
            component: () => import('@/views/manager/control/courses/item/group/Program.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group.program',
            path: '/manager/control/courses/:courseID/:groupType/program',
          },
          {
            component: () => import('@/views/manager/control/courses/item/group/Reviews.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group.reviews',
            path: '/manager/control/courses/:courseID/:groupType/reviews',
          },
          {
            component: () => import('@/views/manager/control/courses/item/group/Recommendations.vue'),
            meta: { middleware: [auth, viewCourses] },
            name: 'manager.control.courses.item.group.recommendations',
            path: '/manager/control/courses/:courseID/:groupType/recommendations',
          },
        ],
        component: () => import('@/views/manager/control/courses/item/Group.vue'),
        meta: { middleware: [auth, viewCourses] },
        path: '/manager/control/courses/:courseID/:groupType',
      },
    ],
    component: () => import('@/views/manager/control/courses/Item.vue'),
    meta: { middleware: [auth, viewCourses] },
    name: 'manager.control.courses.item',
    path: '/manager/control/courses/:courseID',
  },
  {
    component: () => import('@/views/manager/control/courses/List.vue'),
    meta: { middleware: [auth, viewCourses] },
    name: 'manager.control.courses',
    path: '/manager/control/courses',
  },
]
