import auth from '@/router/_middleware/auth'
import { viewMaster, viewMasterExercises, viewMasterLessons, viewMasters } from '@/router/_middleware/manageMasters'

export default [
  {
    children: [
      {
        component: () => import('@/views/manager/education/masters/list/MentorMasters.vue'),
        meta: { middleware: [auth, viewMasters] },
        name: 'manager.education.masters.list.mentors',
        path: '/manager/education/masters/mentors',
      },
      {
        component: () => import('@/views/manager/education/masters/list/MyMasters.vue'),
        meta: { middleware: [auth, viewMasters] },
        name: 'manager.education.masters.list.my',
        path: '/manager/education/masters/my',
      },
    ],
    component: () => import('@/views/manager/education/masters/List.vue'),
    meta: { middleware: [auth, viewMasters] },
    name: 'manager.education.masters.list',
    path: '/manager/education/masters',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/education/masters/item/Lessons.vue'),
        meta: { middleware: [auth, viewMaster, viewMasterLessons] },
        name: 'manager.education.masters.item.lessons',
        path: '',
      },
      {
        component: () => import('@/views/manager/education/masters/item/Information.vue'),
        meta: { middleware: [auth, viewMaster] },
        name: 'manager.education.masters.item.information',
        path: '/manager/education/masters/:masterUUID/information',
      },
      {
        component: () => import('@/views/manager/education/masters/item/Exercises.vue'),
        meta: { middleware: [auth, viewMaster, viewMasterExercises] },
        name: 'manager.education.masters.item.exercises',
        path: '/manager/education/masters/:masterUUID/exercises',
      },
      {
        component: () => import('@/views/manager/education/masters/item/Payments.vue'),
        meta: { middleware: [auth, viewMaster] },
        name: 'manager.education.masters.item.payments',
        path: '/manager/education/masters/:masterUUID/payments',
      },
    ],
    component: () => import('@/views/manager/education/masters/Item.vue'),
    meta: { middleware: [auth, viewMaster] },
    path: '/manager/education/masters/:masterUUID',
  },
]
