import MasterCharacterModule from '@/store/modules/master/character'
import MasterProgressWithCharacterModule from '@/store/modules/master/progress_course'

export default async function firstVisit ({ next, nextMiddleware, to }: any) {
  if (MasterCharacterModule.isFirstVisit) {
    await MasterProgressWithCharacterModule.fetchProgressWithCharacter()
    await MasterCharacterModule.fetchCharacterInventory()

    // Note: переспрашиваем во избежание ошибки
    if (MasterCharacterModule.isFirstVisit) {
      return next({ name: 'master.progress.first.visit', query: { redirect: to.fullPath } })
    }
  }

  return nextMiddleware()
}
