




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// Components
import Achievement from '@/components/views/progress/achievements/Achievement.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// types
import { AchievementResource } from '@/store/types/schema.progress'

interface ISector {
  left: number,
  top: number,
  maxSpeed: number,
  minSpeed: number,
}

interface IIconPos {
  left: number,
  speed: number,
  top: number,
}

@Component({
  components: {
    Achievement,
    Dialog,
    ModalWrapper,
    ProgressSubjectChip,
  },
})
export default class ProgressNewAchievementModal extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private achievementInfo!: AchievementResource

  private isRainDisabled = false
  private isShowAchievement = false
  private iconsNumber = 16
  private sectorSize = 335
  private iconSize = 80
  private animeID = 0
  private canPlay = false

  private rainTopOffset = 0

  private iconsPosition: IIconPos[] = []

  private sectorsPosition: ISector[][] = []

  private localVisible = this.visible

  private get isFullscreen () {
    return this.$vuetify.breakpoint.width < 768
  }

  private get dialogWidth () {
    if (this.$vuetify.breakpoint.width > 1365) {
      return 600
    } else if (this.$vuetify.breakpoint.width > 767) {
      return 500
    } else {
      return 'auto'
    }
  }

  private get contentClass () {
    return this.isFullscreen ? 'no-margin-mobile v-dialog--fullscreen mobile-fullscreen' : 'no-margin-mobile'
  }

  private get src (): string {
    return process.env.VUE_APP_SND_ACHIEVEMENTS_BASE_URL + this.achievementInfo.slug + '/svg.svg'
      ?? 'https://cdn.rive.app/animations/vehicles.svg'
  }

  private mounted () {
    window.addEventListener('resize', this.handleResize.bind(this))

    this.handleResize()
  }

  private handleResize () {
    this.isRainDisabled = false

    if (window.innerWidth > 1440) {
      this.iconsNumber = 16
    } else if (window.innerWidth > 1024) {
      this.iconsNumber = 12
    } else if (window.innerWidth > 640) {
      this.iconsNumber = 10
    } else if (window.innerWidth > 374) {
      this.iconsNumber = 8
    } else {
      this.isRainDisabled = true
    }

    if (!this.isRainDisabled) {
      this.generateSectors()
      this.generatePositions()
    }
  }

  private generateSectors () {
    this.sectorsPosition = []
    this.sectorSize = Math.floor(Math.sqrt((window.innerWidth * window.innerHeight) / this.iconsNumber))

    const rows = Math.ceil(window.innerHeight / this.sectorSize)
    const columns = Math.ceil(window.innerWidth / this.sectorSize)

    const leftOffset = (window.innerWidth - this.sectorSize * columns) / 2
    const topOffset = (window.innerHeight - this.sectorSize * rows) / 2

    if (topOffset < 0) {
      this.rainTopOffset = topOffset * -2
    }

    for(let i = 0; i < rows; i++) {
      this.sectorsPosition[i] = []

      for(let j = 0; j < columns; j++) {
        this.sectorsPosition[i][j] = {
          left: leftOffset + this.sectorSize * j,
          maxSpeed: 5 + i / 2,
          minSpeed: 3 + i / 2,
          top: topOffset + this.sectorSize * i,
        }
      }
    }
  }

  private generatePositions () {
    this.iconsPosition = [];

    [...this.sectorsPosition].flat().reverse().forEach((item, i, array) => {
      const index = (array.length - 1) - i

      const maxTop = item.top + this.sectorSize - this.iconSize
      const maxLeft = item.left + this.sectorSize - this.iconSize

      const top = Math.round(Math.random() * (maxTop - item.top) + item.top)
      const left = Math.round(Math.random() * (maxLeft - item.left) + item.left)
      const speed = Math.random() * (item.maxSpeed - item.minSpeed) + item.minSpeed

      this.iconsPosition[index] = { left, speed, top }
    })
  }

  private fallingDown () {
    let counter = 0
    this.iconsPosition = this.iconsPosition.map(item => {
      const result = {
        ...item,
        top: item.top + item.speed,
      }

      if (result.top > (window.innerHeight * 2.5 + this.rainTopOffset)) {
        counter++
      }

      return result
    })

    if (counter < this.iconsPosition.length) {
      this.animeID = requestAnimationFrame(this.fallingDown)
    }
  }

  private handleClose() {
    this.localVisible = false
    this.$emit('closeModal')
  }

  private goToAllAchievements() {
    this.handleClose()
    this.$router.push({ name: 'master.progress.achievements' })
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value
    setTimeout(() => {
      this.isShowAchievement = value
    }, 100)

    setTimeout(() => {
      this.canPlay = value
      if (value) {
        this.handleStart()
      }
    }, 500)
  }

  private handleStart () {
    this.handleResize()
    if (this.animeID) cancelAnimationFrame(this.animeID)
    this.fallingDown()
  }
}
