














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class SwitchInput extends Vue {
  @Prop({ default: '' })
  private tooltip!: string
}
