



























import { Component, Prop } from 'vue-property-decorator'
import shave from 'shave'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import { NoteResource, UpdateNoteRequest } from '@/store/types'

@Component({
  components: {
    ButtonIconAction,
    TiptapEditor,
  },
})
export default class Note extends NotifyMixin {
  @Prop({ required: true })
  private note!: NoteResource

  @Prop({ required: true })
  private form!: UpdateNoteRequest

  private mounted () {
    shave('#note-item-title', 24)
  }

  private toggleFavorite() {
    this.form.favorite = !this.form.favorite
  }

  private saveNote() {
    this.$emit('saveNote', this.form)
  }
}
