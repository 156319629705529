import Vue from 'vue'

Vue.directive('number', {
  bind: (el: HTMLElement, binding) => {
    el.innerHTML = binding.value
      ? `${binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')}`
      : binding.value
  },
  update: (el: HTMLElement, binding) => {
    if (binding.value !== binding.oldValue) {
      el.innerHTML = binding.value
        ? `${binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')}`
        : binding.value
    }
  },
})
