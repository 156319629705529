import AuthModule from '@/store/modules/auth'
import { hasPermission } from '@/utils/functions'
import { Permission } from '@/store/types'
import SystemModule from '@/store/modules/system'

// Права на просмотр списка наставников (своих и чужих)
export function viewMentors ({ next, nextMiddleware }: any) {
  if (![
    Permission.MENTOR_LIST_ALL,
    Permission.MENTOR_LIST_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка мастеров наставника
export function viewMentorMasters ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MENTOR_MASTER_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашних заданий наставника
export function viewMentorExercises ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MENTOR_EXERCISES_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка уроков наставника
export function viewMentorLessons ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MENTOR_LESSONS_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
