import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR } from '@/store'
import { CreateNoteRequest, MasterNoteNoteIdDeleteRequest, MasterNoteNoteIdGetRequest, MasterNotePatchRequest, MasterNotePostRequest, MasterNotesGetParams, MasterNotesGetRequest, MasterNoteNoteIdFavoritePatchRequest, NoteColors, NoteListResourcePaginator, NoteResource, NoteShortResource, UpdateNoteRequest } from '@/store/types'

interface UpdateNote {
  color?: NoteColors,
  favorite?: boolean,
  title?: string,
}

@Module({
  dynamic: true,
  name: 'MasterNotes',
  namespaced: true,
  store,
})
class MasterNotes extends VuexModule {
  notes: NoteListResourcePaginator = GET_DEFAULT_PAGINATOR()
  note: NoteResource | null = null

  @Mutation
  setNotes(payload: { response: NoteListResourcePaginator, search: boolean }) {
    payload.search ? this.notes.data = payload.response.data : this.notes.data.push(...payload.response.data)
    this.notes.meta = Object.assign({}, payload.response.meta)
  }

  @Mutation
  setNote(payload: NoteResource) {
    this.note = payload
  }

  @Mutation
  editNote(payload: { id: number, data: UpdateNote }) {
    const findIndex = this.notes.data.findIndex(note => note.id === payload.id)
    if (findIndex !== -1) {
      this.notes.data = this.notes.data.map((note: NoteShortResource, index: number) => {
        if (index === findIndex) {
          return {
            ...note,
            color: payload.data.color || note.color,
            favorite: 'favorite' in payload.data ? payload.data.favorite as boolean : note.favorite,
            title: payload.data.title || note.title,
          }
        }
        return note
      })
    }
  }

  @Mutation
  addNote(note: NoteResource) {
    this.notes.data = [...this.notes.data, {
      color: note.color,
      createdAt: note.createdAt,
      favorite: note.favorite,
      id: note.id,
      title: note.title,
    }]
  }

  @Mutation
  removeNote(noteID: number) {
    this.notes.data = this.notes.data.filter(note => note.id !== noteID)
  }

  // Получение всех заметок мастера в пагинаторе
  @Action({ rawError: true })
  async fetchNotes(payload: { filter?: MasterNotesGetParams, search: boolean }) {
    const { data } = await MasterNotesGetRequest(payload.filter, { loading: false })
    this.setNotes({ response: data, search: payload.search })
    return data
  }

  // Создание новой заметки
  @Action({ rawError: true })
  async createNotes(payload: CreateNoteRequest) {
    const { data } = await MasterNotePostRequest(payload)
    this.setNote(data)
    this.addNote(data)
    return data
  }

  // Получение конкретной заметки
  @Action({ rawError: true })
  async fetchNote(noteID: number) {
    const { data } = await MasterNoteNoteIdGetRequest(noteID)
    this.setNote(data)
    return data
  }

  // Обновление существующей заметки
  @Action({ rawError: true })
  async updateNote(payload: UpdateNoteRequest) {
    const { data } = await MasterNotePatchRequest(payload)
    this.setNote(data)
    this.editNote({ data: { color: data.color, favorite: data.favorite, title: data.title }, id: data.id })
    return data
  }

  // Удаление конкретной заметки
  @Action({ rawError: true })
  async deleteNote(noteID: number) {
    const { data } = await MasterNoteNoteIdDeleteRequest(noteID)
    this.removeNote(noteID)
    return data
  }

  // Переключение избранности заметки
  @Action({ rawError: true })
  async toggleFavoriteNote(noteID: number) {
    const { data } = await MasterNoteNoteIdFavoritePatchRequest(noteID, { loading: false })
    this.editNote({ data: { favorite: data.favorite }, id: noteID })
    return data
  }
}

const MasterNotesModule = getModule(MasterNotes)

export default MasterNotesModule
