




















import { Component, Prop, Vue } from 'vue-property-decorator'

import MasterProgressModule from '@/store/modules/master/progress'
import { declension } from '@/utils/functions'

@Component
export default class ProgressCourseStatsBlock extends Vue {
  @Prop({ default: false })
  private isInModal!: boolean

  private get statsSource() {
    return MasterProgressModule.courseStatistic && MasterProgressModule.courseStatistic.statistic
  }

  private get outOfMonth(): number {
    return MasterProgressModule.courseStatistic?.statistic.month.all as number
  }

  private get outOfMonthPercent(): number {
    return MasterProgressModule.courseStatistic?.statistic.month.current as number
  }

  private get progressMonth() {
    const amountOfMonths = Math.floor(this.outOfMonthPercent)

    if (amountOfMonths === 0) {
      return 'менее месяца'
      // return '< 1 месяца'
    }

    return `${amountOfMonths} ${declension(amountOfMonths, ['месяц', 'месяца', 'месяцев'])}`
  }

  private get lostLivesCurrent() {
    return MasterProgressModule.courseStatistic?.statistic.livesLost.current as number
  }

  private get lostLives() {
    return `${this.lostLivesCurrent} ${declension(this.lostLivesCurrent, ['раз', 'раза', 'раз'])}`
  }

  private get lessonsCurrent() {
    return MasterProgressModule.courseStatistic?.statistic.lessons.current as number
  }

  private get lessons() {
    return `${this.lessonsCurrent} ${declension(this.lessonsCurrent, ['урок', 'урока', 'уроков'])}`
  }

  private get currentExercieses() {
    return MasterProgressModule.courseStatistic?.statistic.exercises.current as number
  }

  private get exercises() {
    return `${this.currentExercieses} ${declension(this.currentExercieses, ['задание', 'задания', 'заданий'])}`
  }

  private get stats() {
    return [
      {
        iconSrc: require('@/assets/images/icons/sidebar/calendar.svg'),
        outOf: MasterProgressModule.courseStatistic?.statistic.month.all,
        subtitle: 'пройдено',
        title: this.progressMonth,
        type: 'duration',
      },
      {
        iconSrc: require('@/assets/images/icons/heart.svg'),
        outOf: MasterProgressModule.courseStatistic?.statistic.livesLost.all,
        subtitle: 'потеряна жизнь',
        title: this.lostLives,
        type: 'life',
      },
      {
        iconSrc: require('@/assets/images/icons/sidebar/study.svg'),
        outOf: MasterProgressModule.courseStatistic?.statistic.lessons.all,
        subtitle: 'просмотрено',
        title: this.lessons,
        type: 'lesson',
      },
      {
        iconSrc: require('@/assets/images/icons/sidebar/exercises.svg'),
        outOf: MasterProgressModule.courseStatistic?.statistic.exercises.all,
        subtitle: 'выполнено',
        title: this.exercises,
        type: 'exercise',
      },
    ]
  }
}
