









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: true,
})
export default class CardWrapper extends Vue {
  @Prop({ default: true })
  private soloFooter!: boolean

  @Prop({ default: false })
  private orangeFooter!: boolean

  @Prop({ default: '' })
  private customBgcFooter!: string
}
