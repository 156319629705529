import auth from '@/router/_middleware/auth'
import { manageDiscounts } from '@/router/_middleware/manageCommerce'

export default [
  {
    component: () => import('@/views/manager/commerce/discount/New.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount.item.new',
    path: '/manager/commerce/discounts/new',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/commerce/discount/List.vue'),
        meta: { middleware: [auth, manageDiscounts] },
        name: 'manager.commerce.discount.list',
        path: '/manager/commerce/discounts/list',
      },
      {
        component: () => import('@/views/manager/commerce/discount/Stats.vue'),
        meta: { middleware: [auth, manageDiscounts] },
        name: 'manager.commerce.discount.stats',
        path: '/manager/commerce/discounts/stats',
      },
    ],
    component: () => import('@/views/manager/commerce/discount/Discounts.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount',
    path: '/manager/commerce/discounts',
  },
  {
    component: () => import('@/views/manager/commerce/discount/Item.vue'),
    meta: { middleware: [auth, manageDiscounts] },
    name: 'manager.commerce.discount.item',
    path: '/manager/commerce/discounts/:saleID',
  },
]
