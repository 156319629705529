import auth from '@/router/_middleware/auth'
import manageSocials from '@/router/_middleware/manageSocials'

export default [
  {
    component: () => import('@/views/master/lessons/Item.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.courses.lessons.item',
    path: '/master/courses/lessons/:lessonID',
  },
  {
    component: () => import('@/views/master/lessons/List.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.courses.lessons',
    path: '/master/courses/lessons',
  },
  {
    component: () => import('@/views/master/courses/MyCourses.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.courses.my',
    path: '/master/courses',
  },
  {
    meta: { middleware: [auth, manageSocials] },
    name: 'master.courses.item',
    path: '/master/courses/:courseID',
    redirect: '/master/courses',
  },
]
