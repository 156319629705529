var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-image-form"},[_c('ValidationProvider',{staticClass:"space-b-4 mb-8",attrs:{"name":"url","rules":{ required: _vm.visible && _vm.currentItem === 0},"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"https://image.jpg","clearable":"","invalid":errors.length > 0,"error":errors[0],"label":"Ссылка"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})]}}])}),_c('ValidationProvider',{staticClass:"space-b-4",attrs:{"name":"alt","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Пётр I","clearable":"","invalid":errors.length > 0,"error":errors[0],"label":"Альтернативный текст"},model:{value:(_vm.form.alt),callback:function ($$v) {_vm.$set(_vm.form, "alt", $$v)},expression:"form.alt"}})]}}])}),_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"width","rules":{ max_value: 800, min_value: 0, numeric: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Ширина","clearable":"","messages":"Рекомендуемая ширина картинки до 800px","type":"number","invalid":errors.length > 0,"error":errors[0],"label":"Ширина"},model:{value:(_vm.form.width),callback:function ($$v) {_vm.$set(_vm.form, "width", $$v)},expression:"form.width"}})]}}])}),_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"height","rules":{ max_value: 500, min_value: 0, numeric: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"placeholder":"Высота","clearable":"","messages":"Рекомендуемая высота картинки до 500px","type":"number","invalid":errors.length > 0,"error":errors[0],"label":"Высота"},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", $$v)},expression:"form.height"}})]}}])}),(_vm.form.url)?_c('VImg',{staticClass:"img-modal__img",attrs:{"src":_vm.form.url,"height":_vm.form.height || undefined,"width":_vm.form.width || undefined}}):_vm._e(),_c('ButtonTextIcon',{staticClass:"upload-image-form__remove",attrs:{"color":"red","icon":"$trash"},on:{"click":function($event){return _vm.$emit('removeImg')}}},[_vm._v("Удалить")]),_c('div',{staticClass:"upload-image-form__divider"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }