









import { Component, Vue } from 'vue-property-decorator'

import BriefNotificationCard from '@/components/notifications/BriefNotificationCard.vue'
import NotificationModule from '@/store/modules/notification'

@Component({
  components: { BriefNotificationCard },
})
export default class BriefNotifications extends Vue {
  private get notifications () {
    return [...NotificationModule.buffer.filter(item => !item.kind || item.kind !== 'news').slice(0, 3)]
  }
}
