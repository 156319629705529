import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { POSITION } from 'vue-toastification'
// import Cookies from 'js-cookie'

// import { authTokenName } from '@/utils/services/config'

@Component
export default class NotifyMixin extends Vue {
  private config = {
    hideProgressBar: true,
    position: POSITION.BOTTOM_RIGHT,
    timeout: 7000,
  }

  protected notifySuccess (message: string) {
    this.$toast.success(message, this.config)
  }

  protected notifyInfo (message: string) {
    this.$toast.info(message, this.config)
  }

  protected notifyWarning(message: string) {
    this.$toast.warning(message, this.config)
  }

  protected notifyError(error: any) {
    let message = 'Что-то пошло не так. Повторите попытку позднее или перезагрузите страницу'

    if (error.response && error.response.data) {
      message = error.response.data.message

      // if (error.response.status === 403 && process.env.NODE_ENV === 'development') {
      //   Cookies.remove(authTokenName)
      // }
    } else if (typeof error === 'string') {
      message = error
    } else {
      // eslint-disable-next-line
      console.error(error)
    }

    this.$toast.error(message, this.config)

    // Note: данный коллбек позволяет делать логику после того, как показали snackbar черзе
    // половину таймаута
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, this.config.timeout / 2)
    })
  }
}
