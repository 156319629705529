import Vue from 'vue'
import Toast, { POSITION } from 'vue-toastification'

import '/src/styles/vendor/vue-toastification.scss'

const options: any = {
  // container: function () { return document.getElementsByClassName('new_achievements_popups')[0] ?? document.body },
  position: POSITION.BOTTOM_RIGHT,
}

Vue.use(Toast, options)
