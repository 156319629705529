import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'

// Проверка в роутах на наличие привязанной социальной сети
export default function manageSocials ({ next, nextMiddleware }: any) {
  if (AuthModule.self && !AuthModule.self.hasSocials) {
    SystemModule.setSnackbarText('Привяжите аккаунты социальных сетей')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
