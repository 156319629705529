

























































import { Component, Prop, Watch } from 'vue-property-decorator'

// Mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// Components
import Dialog from '@/components/modals/Dialog.vue'
import FireworksIcon from '@/components/_uikit/icons/FireworksIcon.vue'
import StarIcon from '@/components/_uikit/icons/StarIcon.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import ProgressCourseStatsBlock from '@/components/views/progress/course/sidebar/ProgressCourseStatsBlock.vue'
import ProgressSubjectChip from '@/components/_uikit/progress/ProgressSubjectChip.vue'
// Types
import { UserRankWithInfoResource } from '@/store/types/schema.progress'
// Helpers
import { declension } from '@/utils/functions'

@Component({
  components: {
    Dialog,
    FireworksIcon,
    ModalWrapper,
    ProgressCourseStatsBlock,
    ProgressSubjectChip,
    StarIcon,
  },
})
export default class ProgressNewLevelModal extends MasterProgressCourseMixin {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: null })
  private levelInfo!: UserRankWithInfoResource

  private localVisible = this.visible

  private get finalLevel (): number {
    if (this.levelInfo.course?.type === 'special') {
      return 2
    }
    return 37
  }

  private get rankImageSrc () {
    if (this.levelInfo?.image) {
      return this.levelInfo.image
    } else {
      return this.getRankIcon(this.levelInfo.position, this.finalLevel === 2)
    }
  }

  private get isFinalLevel () {
    return this.levelInfo.position === this.finalLevel
  }

  private handleClose() {
    this.$emit('update:visible', false)
    this.$emit('closeModal')
  }

  private declension (n: number, t: string[]) {
    return declension(n, t)
  }

  private get dialogWidth () {
    if (this.$vuetify.breakpoint.width > 1365) {
      return 600
    } else {
      return 500
    }
  }

  private goToAllRanks () {
    if (this.levelInfo.course?.id) {
      this.$router.push({
        name: 'master.progress.course',
        params: {
          courseID: this.levelInfo.course.id.toString(),
        },
      })
    } else {
      this.$router.push({
        name: 'master.progress.course',
      })
    }

    this.handleClose()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value
  }
}
