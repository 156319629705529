































import { Component, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterExercisesModule from '@/store/modules/master/exercises'
import SystemModule from '@/store/modules/system'
// utils
import { convertSecondsToHoursMinutes } from '@/utils/functions'

@Component
export default class Timer extends NotifyMixin {
  private get exercise() {
    return MasterExercisesModule.exercise
  }

  private get time() {
    return convertSecondsToHoursMinutes(this.seconds, true)
  }

  /*private get progress() {
    const p = this.seconds / this.timer * 100
    return this.timer ? p.toFixed(2) : 0
  }*/

  /*private get timer() {
    return SystemModule.exerciseDuration
  }*/

  private get timePassed() {
    return SystemModule.timePassed
  }

  private seconds = this.timePassed

  private interval: number | null = null
  private isHide = false

  private mounted() {
    if (this.seconds > 0) {
      this.interval = setInterval(this.runTimer, 1000)
      document.addEventListener('visibilitychange', this.updateTimer)
    }
  }

  private destroyed() {
    if (this.interval)
      clearInterval(this.interval)
    document.removeEventListener('visibilitychange', this.updateTimer)
  }

  private runTimer() {
    if (this.seconds === 0 && this.interval) {
      this.notifyWarning('Время вышло! 😔')
      this.isHide = false
      clearInterval(this.interval)
      return
    }
    this.seconds -= 1
  }

  private closeTimer() {
    SystemModule.hideTimer()
  }

  private updateTimer({ type }: Event) {
    if (type === 'visibilitychange' && document.visibilityState === 'visible' && this.exercise) {
      const workStartedTS = (DateTime.fromSQL(this.exercise.progress.workStartedAt as string, { zone: 'Europe/Moscow' }) as any).ts
      const timePassed = this.exercise.avgExecutionSeconds - Math.floor((Date.now() - workStartedTS) / 1000)
      SystemModule.updateTimerPassed(timePassed > 0 ? timePassed : 0)
    }
  }

  @Watch('timePassed')
  private watchTimePassed(value: number) {
    this.seconds = value
  }
}
