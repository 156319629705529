import auth from '@/router/_middleware/auth'
import { manageRoles } from '@/router/_middleware/manageUsers'

export default [
  {
    component: () => import('@/views/manager/users/roles/New.vue'),
    meta: { middleware: [auth, manageRoles] },
    name: 'manager.users.roles.new',
    path: '/manager/users/roles/new',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/users/roles/item/Permissions.vue'),
        meta: { middleware: [auth, manageRoles] },
        name: 'manager.users.roles.item.permissions',
        path: '/manager/users/roles/:roleUUID/permissions',
      },
      {
        component: () => import('@/views/manager/users/roles/item/Users.vue'),
        meta: { middleware: [auth, manageRoles] },
        name: 'manager.users.roles.item.users',
        path: '/manager/users/roles/:roleUUID/users',
      },
    ],
    component: () => import('@/views/manager/users/roles/Item.vue'),
    meta: { middleware: [auth, manageRoles] },
    name: 'manager.users.roles.item',
    path: '/manager/users/roles/:roleUUID',
  },
  {
    component: () => import('@/views/manager/users/roles/List.vue'),
    meta: { middleware: [auth, manageRoles] },
    name: 'manager.users.roles',
    path: '/manager/users/roles',
  },
]
