






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class LinkPrimary extends Vue {
  @Prop({
    default: 'router-link',
    validator (value: string): boolean {
      return !!value.match(/(button|a|router-link|div)/)
    },
  })
  readonly tag!: string
}
