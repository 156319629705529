export enum DocsEnum {
  MENTOR_TERMS = 'https://smitup.ru/teachers',
  POLICY = 'https://smitup.ru/privacy',
  PERSONAL = 'https://smitup.ru/personal',
  OFFER_ANTONOV = 'https://smitup.ru/offer_antonov',
  OFFER_BABICH = 'https://smitup.ru/offer_babich',
  OFFER_EYTUTIS = 'https://smitup.ru/offer_eytutis',
  OFFER_MALYSHEV = 'https://smitup.ru/offer_malyshev',
  OFFER_ROGOVENKO = 'https://smitup.ru/offer_rogovenko',
  OFFER_SERGEEVA = 'https://smitup.ru/offer_sergeeva',
  OFFER_SHARAFIEVA = 'https://smitup.ru/offer_sharafieva',
  OFFER_SHVETSOV = 'https://smitup.ru/offer_shvetsov',
  OFFER_STRELKOVA = 'https://smitup.ru/offer_strelkova',
  OFFER_VOLVAKA = 'https://smitup.ru/offer_volvaka',
  USER_TERMS = 'https://smitup.ru/users',
}

export const OffersLink: Record<number, string> = {
  1: 'https://smitup.ru/offer_strelkova',
  2: 'https://smitup.ru/offer_rogovenko',
  3: 'https://smitup.ru/offer_sharafieva',
  4: 'https://smitup.ru/offer_eytutis',
  5: 'https://smitup.ru/offer_malyshev',
  6: 'https://smitup.ru/offer_sergeeva',
  7: 'https://smitup.ru/offer_volvaka',
  8: 'https://smitup.ru/offer_babich',
  9: 'https://smitup.ru/offer_shvetsov',
  10: 'https://smitup.ru/offer_antonov',
}
