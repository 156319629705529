import { Component, Vue } from 'vue-property-decorator'

const { detect } = require('detect-browser')

@Component
export default class DetectSafariMixin extends Vue {
  protected get isSafari () {
    const browser = detect()
    //==============================================================================
    //  Note: добавлена дополнительная проверка на OS, с которой сидит пользователь
    //  поскольку все браузеры под IOS используют web-kit, то часть багов поялвться только
    //  на ios девайсах на любом из  браузеров
    return browser.name === 'safari' || browser.name === 'ios'
  }

  protected get isIosWebkit() {
    const browser = detect()
    //==============================================================================
    //  Note: добавлена дополнительная проверка на OS, с которой сидит пользователь
    //  поскольку все браузеры под IOS используют web-kit, то часть багов поялвться только
    //  на ios девайсах на любом из  браузеров
    return browser.os === 'iOS'
  }

  protected get isSafariOnIOS() {
    const browser = detect()
    // Проверка, что пользователь сидит на iOS в сафари
    return browser.name === 'ios' && browser.os === 'iOS'
  }
}
