











// Core
import { Component, Mixins, Prop } from 'vue-property-decorator'

// Mixins
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
// Variables
import colors from '@/utils/plugins/colors'

// Types
// NOTE: Типы создаются из массивов строк, которые используются для валидации пропсов.
const ColorTransparencyWhiteList = [
  'darken-1', 'darken-2',
  'darken-3', 'darken-4',
  'lighten-1', 'lighten-2',
  'lighten-3', 'lighten-4',
  'lighten-5',
] as const
type ColorTransparencyType = typeof ColorTransparencyWhiteList[number]

// Массив ключей объекта цветов и тип для него
type ColorVariablesType = keyof typeof colors.light
const ColorVariablesWhiteList = Object.keys(colors.light) as ColorVariablesType[]

// Include быстрее string.match по этому всё пихаем в масивы
const ChipSkinWhiteList = [
  'fill', 'bright', 'stroke',
  'stroke-bcg', 'not-available',
] as const
type ChipSkinType = typeof ChipSkinWhiteList[number]

@Component({
  inheritAttrs: false,
})
export default class ProgressSubjectChip extends Mixins(MasterProgressCourseMixin) {
  @Prop({
    default: 'orange',
    validator (value: ColorVariablesType): boolean {
      return ColorVariablesWhiteList.includes(value)
    },
  })
  private color!: ColorVariablesType

  @Prop({
    default: 'fill',
    validator (value: ChipSkinType): boolean {
      return ChipSkinWhiteList.includes(value)
    },
  })
  private type!: ChipSkinType

  @Prop({
    default: 'lighten-4',
    validator (value: ColorTransparencyType): boolean {
      return ColorTransparencyWhiteList.includes(value)
    },
  })
  private transparency!: ColorTransparencyType

  @Prop({
    default: 'darken-4',
    validator (value: ColorTransparencyType): boolean {
      return ColorTransparencyWhiteList.includes(value)
    },
  })
  private brightTransparency!: ColorTransparencyType

  @Prop({ default: true })
  private isPointerEvents!: boolean

  private get cssColorVariable () {
    return `var(--v-${this.color}-${this.type !== 'fill' ? this.transparency : 'base'})`
  }

  private get cssBrightColorVariable () {
    return `var(--v-${this.color}-${this.brightTransparency})`
  }
}
