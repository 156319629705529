











import { Component, Prop, Vue } from 'vue-property-decorator'

import { MediaResource } from '@/store/types'

@Component
export default class FilesList extends Vue {
  @Prop({ default: () => ([]) })
  private files!: MediaResource[]

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: false })
  private isDepository!: boolean

  private get filteredFileList () {
    return this.files.sort((a, b) => {
      if (a.filename.toLowerCase() > b.filename.toLowerCase()) {
        return 1
      } else if (a.filename.toLowerCase() < b.filename.toLowerCase()) {
        return -1
      }
      return 0
    })
  }
}
