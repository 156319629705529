import { render, staticRenderFns } from "./BriefNotificationCard.vue?vue&type=template&id=b6981796&scoped=true&lang=pug&"
import script from "./BriefNotificationCard.vue?vue&type=script&lang=ts&"
export * from "./BriefNotificationCard.vue?vue&type=script&lang=ts&"
import style0 from "./BriefNotificationCard.vue?vue&type=style&index=0&id=b6981796&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6981796",
  null
  
)

export default component.exports