import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

// Права на просмотр списка мастеров в разделе "Учебный процесс"
export function viewMasters ({ next, nextMiddleware }: any) {
  if (![
    Permission.MASTER_VIEW_ALL,
    Permission.MASTER_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр данных мастера и данных по оплате в разделе "Учебный процесс"
export function viewMaster ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MASTER_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр уроков мастера в разделе "Учебный процесс"
export function viewMasterLessons ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MASTER_LESSONS_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр домашних заданий мастера в разделе "Учебный процесс"
export function viewMasterExercises ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.MASTER_EXERCISES_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
