import auth from '@/router/_middleware/auth'
import {
  editManuals,
  manageDepository,
  viewManuals,
  viewMaterials,
} from '@/router/_middleware/manageBanks'

export default [
  {
    component: () => import('@/views/manager/bank/depository/courses/materials/CreateMaterials.vue'),
    exact: true,
    meta: { middleware: [auth, viewMaterials] },
    name: 'manager.bank.depository.courses.item.materials.create',
    path: '/manager/bank/depository/courses/:courseID/materials/create',
  },
  {
    component: () => import('@/views/manager/bank/depository/courses/materials/EditMaterial.vue'),
    exact: true,
    meta: { middleware: [auth, viewMaterials] },
    name: 'manager.bank.depository.courses.item.materials.edit',
    path: '/manager/bank/depository/courses/:courseID/materials/:materialID/edit',
  },
  {
    component: () => import('@/views/manager/bank/depository/courses/materials/ViewMaterial.vue'),
    exact: true,
    meta: { middleware: [auth, viewMaterials] },
    name: 'manager.bank.depository.courses.item.materials.view',
    path: '/manager/bank/depository/courses/:courseID/materials/:materialID',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/bank/depository/courses/item/Materials.vue'),
        exact: true,
        meta: { middleware: [auth, viewMaterials] },
        name: 'manager.bank.depository.courses.item.materials',
        path: '/manager/bank/depository/courses/:courseID/materials',
      },
      {
        component: () => import('@/components/tables/TrainingFilesTable.vue'),
        exact: true,
        meta: { middleware: [auth, viewMaterials] },
        name: 'manager.bank.depository.courses.item.files',
        path: '/manager/bank/depository/courses/:courseID/files',
      },
    ],

    component: () => import('@/views/manager/bank/depository/courses/Item.vue'),
    meta: { middleware: [auth, viewMaterials] },
    name: 'manager.bank.depository.courses.item',
    path: '/manager/bank/depository/courses/:courseID',
    redirect: '/manager/bank/depository/courses/:courseID/materials',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/bank/depository/courses/item/FolderNew.vue'),
        meta: { middleware: [auth, viewMaterials] },
        name: 'manager.bank.depository.courses.item.files.folder.new',
        path: '/manager/bank/depository/courses/:courseID/files/:monthID/new',
      },
      {
        component: () => import('@/views/manager/bank/depository/courses/item/Folder.vue'),
        meta: { middleware: [auth, viewMaterials] },
        name: 'manager.bank.depository.courses.item.files.folder',
        path: '/manager/bank/depository/courses/:courseID/files/:monthID/:folderID',
      },
    ],
    component: () => import('@/views/manager/bank/depository/courses/item/Month.vue'),
    exact: true,
    meta: { middleware: [auth, viewMaterials] },
    name: 'manager.bank.depository.courses.item.files.month',
    path: '/manager/bank/depository/courses/:courseID/files/:monthID',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/bank/depository/manual/New.vue'),
        meta: { middleware: [auth, editManuals] },
        name: 'manager.bank.depository.manual.new',
        path: '/manager/bank/depository/manual/new',
      },
      {
        component: () => import('@/views/manager/bank/depository/manual/Item.vue'),
        meta: { middleware: [auth, viewManuals] },
        name: 'manager.bank.depository.manual.item',
        path: '/manager/bank/depository/manual/:manualID',
      },
    ],
    component: () => import('@/views/manager/bank/depository/manual/Tree.vue'),
    meta: { middleware: [auth, viewManuals] },
    name: 'manager.bank.depository.manual',
    path: '/manager/bank/depository/manual',
  },
  {
    children: [
      {
        component: () => import('@/views/manager/bank/depository/courses/List.vue'),
        meta: { middleware: [auth, viewMaterials] },
        name: 'manager.bank.depository.courses',
        path: '/manager/bank/depository/courses',
      },
    ],
    component: () => import('@/views/manager/bank/Depository.vue'),
    meta: { middleware: [auth, manageDepository] },
    name: 'manager.bank.depository',
    path: '/manager/bank/depository',
  },
]
