





































import vuescroll from 'vuescroll'
import { Component, Ref } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component
export default class SidenavMasterTour extends DetectSafariMixin {
  @Ref() scroll!: vuescroll

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
    vuescroll: {
      sizeStrategy: 'number',
    },
  }

  private mounted() {
    this.$bus.$on('scrollSidenav', this.scrollSidenav)
  }

  private scrollSidenav(top: number) {
    let y: number
    const { scrollTop } = this.scroll.getPosition()
    if (this.$vuetify.breakpoint.width >= 700 && this.$vuetify.breakpoint.width < 1025) {
      y = top - this.$vuetify.breakpoint.height / 2 - 20
    } else {
      y = (scrollTop || 0) + top - 120
    }
    this.scroll.scrollTo({ y }, 500, 'easeInOutQuad')
  }
}
