import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { ColorHEX } from '@/store/types'
import { CharacterInventoryGetRequest, CharacterPartingWordGetParams, CharacterPartingWordGetRequest, CharacterPatchRequest, CharacterUpdateRequest, ItemLargeResource, PartingWordResource } from '@/store/types/schema.progress'
import { filterOptionsToParams } from '@/utils/functions'

/**
 * Модуль для работы с маскотом
 */

export interface ICharacter {
  background?: null | string,
  border?: null | string,
  bodyColor: ColorHEX,
  bodyStroke: ColorHEX,
  faceColor: ColorHEX,
  borderRadius: string,
  username: string | null,
  about: string | null,
  pose: string,// TODO: type it
}

export type ICharacterField = keyof ICharacter
export type ICharacterValue = ICharacter[ICharacterField]

export type ICharacterConfigAction = {
  field: ICharacterField | string,
  payload: ICharacterValue,
}

@Module({
  dynamic: true,
  name: 'MasterCharacter',
  namespaced: true,
  store,
})
class MasterCharacter extends VuexModule {
  // ---------------------------- Character Configuration ---------------------------- >>
  character: CharacterUpdateRequest = {
    about: '',
    equipments: [],
    username: '',
  }

  get isFirstVisit () {
    return !(this.character.about && this.character.username)
  }

  @Mutation
  setCharacter(payload: CharacterUpdateRequest) {
    this.character = { ...payload }
  }

  inventory: ItemLargeResource[] = []

  @Mutation
  setInventory(payload: ItemLargeResource[]) {
    this.inventory = [...payload]
  }

  partingWord: PartingWordResource = {
    author: '',
    text: '',
  }

  @Mutation
  setPartingWord(payload: PartingWordResource) {
    this.partingWord = payload
  }

  isViewedPartingWord = false

  @Mutation
  setIsViewedPartingWord(payload: boolean) {
    this.isViewedPartingWord = payload
  }

  @Action({ rawError: true })
  public async fetchPartingWord(payload: CharacterPartingWordGetParams) {
    const { data } = await CharacterPartingWordGetRequest(filterOptionsToParams(payload), { loading: false })

    this.setPartingWord(data)

    return data
  }

  @Action({ rawError: true })
  public async fetchCharacterInventory() {
    const { data } = await CharacterInventoryGetRequest()
    this.setInventory(data)
    return data
  }

  @Action({ rawError: true })
  public async saveCharacter(payload: CharacterUpdateRequest) {
    const { data } = await CharacterPatchRequest(payload)

    MasterCharacterModule.setCharacter({
      about: data.character.about,
      equipments: [...data.items.map(item => item.uuid)],
      username: data.character.username,
    } as CharacterUpdateRequest)
    return data
  }
}

const MasterCharacterModule = getModule(MasterCharacter)

export default MasterCharacterModule
