











import { Vue, Component, Prop } from 'vue-property-decorator'

import Heart from '@/components/Heart.vue'
import DictionaryModule from '@/store/modules/dictionary'
import { formatDate } from '@/utils/functions'
import MasterEducationModule from '@/store/modules/master/education'
import MentorEducationModule from '@/store/modules/mentor/education'
import SystemModule from '@/store/modules/system'

@Component({
  components: {
    Heart,
  },
})
export default class HeartsWithMonth extends Vue {
  @Prop({ required: true })
  private heartCount!: number

  @Prop({ default: true })
  private withMonth!: boolean

  // Note: в ряде случаев нужно, чтобы копоненте отображался реверснутым на мобилках
  @Prop({ default: false })
  private reversedMobile!: boolean

  @Prop({ default: null })
  private monthId!: number | null

  private get currentMasterGroupID() {
    return +this.$route.query.groupID || this.currentMasterGroup
  }

  protected get currentMasterGroup () {
    return SystemModule.interface === 'master'
      ? MasterEducationModule.currentMasterGroup?.id as number
      : MentorEducationModule.currentMasterGroup?.id as number
  }

  private get currentMonth() {
    const months = ['Январь', 'Февраль', 'март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    return months[(new Date()).getMonth()].toLowerCase()
  }

  private get selectMonth() {
    if (DictionaryModule.masterGroupsByUserId.length && this.monthId) {
      const foundGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
      if (foundGroup) {
        const month = foundGroup.months.find(month => month.value === this.monthId)
        return month ? formatDate(month.name, 'LLLL').toLowerCase() : ''
      }
      return ''
    }

    if (this.monthId && MasterEducationModule.currentMasterGroup) {
      const findMonth = MasterEducationModule.currentMasterGroup.months.find(month => month.value === this.monthId)
      if (findMonth) {
        return formatDate(findMonth.name, 'LLLL').toLowerCase()
      }
      return ''
    }

    return ''
  }

  private get month() {
    return this.selectMonth ? this.selectMonth : this.currentMonth
  }
}
