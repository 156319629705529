





import { Component, Prop, Vue } from 'vue-property-decorator'

import NotificationModule from '@/store/modules/notification'
import NotificationCard from '@/components/notifications/NotificationCard.vue'
import { NotificationResource } from '@/store/types'

@Component({
  components: { NotificationCard },
})
export default class BriefNotificationCard extends Vue {
  @Prop({ required: true })
  private notification!: NotificationResource

  private timeout = 5000

  private mounted () {
    setTimeout(() => {
      NotificationModule.deleteNotificationFromBuffer()
    }, this.timeout)
  }

  private handleDelete () {
    NotificationModule.deleteNotificationFromBuffer()
  }
}
