








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class OptionWithMark extends Vue {
  @Prop({ required: true })
  private marked!: boolean

  @Prop({ default: false })
  private withBorder!: boolean

  @Prop({ default: false })
  private isText!: boolean

  private get boxClasses(): Record<string, boolean>{
    const classes = {
      border: this.withBorder,
      box: !this.isText,
      chosen: this.marked,
      text: this.isText,
    }

    return classes
  }
}
