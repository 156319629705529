import AuthModule from '@/store/modules/auth'

// Проверка имеет ли пользователь менеджерские права
export default function isManager ({ next, nextMiddleware }: any) {
  if (!AuthModule.isManager) {
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
