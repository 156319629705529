import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  MasterEducationMasterGroupIdExercisesExerciseUuidStartWorkingGetRequest,
  MediaChunkResource,
  UploadFile,
  UploadFileChunk,
  UploadPostRequest,
} from '@/store/types'
import { baseURL, getToken } from '@/utils/services/config'

/**
 * Общие для всего приложения функции
 * - сохранение интерфейса пользователя (мастер/менеджер). Чтобы на общих страницах (профиль) отображался правильный сайдбар
 * - спиннер загрузки
 * - загрузка файлов целиком и по чанкам
 */

@Module({
  dynamic: true,
  name: 'System',
  namespaced: true,
  store,
})
class System extends VuexModule {
  // ---------------------------- Interface ---------------------------- >>

  interface: 'master' | 'manager' = 'manager'

  @Mutation
  initInterface () {
    const lsInterface = localStorage.getItem('interface')
    this.interface = lsInterface && lsInterface === 'manager' ? 'manager' : 'master'
    localStorage.setItem('interface', this.interface)
  }

  @Mutation
  setInterface (payload: 'master' | 'manager') {
    this.interface = payload
    localStorage.setItem('interface', this.interface)
  }

  // ---------------------------- Loading ---------------------------- >>

  loading = false
  loadingCounter = 0

  @Mutation
  incrementLoading () {
    this.loadingCounter += 1
    this.loading = true
  }

  @Mutation
  decrementLoading () {
    if (this.loadingCounter > 0) {
      this.loadingCounter -= 1
      if (this.loadingCounter <= 0) {
        this.loading = false
      }
    }
  }

  @Action({ rawError: true })
  loadingStart () {
    this.incrementLoading()
  }

  @Action({ rawError: true })
  loadingEnd () {
    // Фича от Вероники
    setTimeout(this.decrementLoading, 150)
  }

  // ---------------------------- UploadFiles ---------------------------- >>

  @Action({ rawError: true })
  async upload (payload: UploadFile) {
    const { data } = await UploadPostRequest(payload)

    return data
  }

  @Action({ rawError: true })
  async uploadChunk (payload: UploadFileChunk): Promise<MediaChunkResource> {
    const authToken = getToken()
    const formData: FormData = new FormData()
    formData.set('file', payload.file)
    formData.set('type', 'audio')
    formData.set('filename', payload.filename)
    formData.set('uuid', payload.uuid)
    formData.set('chunk', payload.chunk.toString())
    formData.set('chunks', payload.chunks.toString())
    formData.set('size', payload.size.toString())
    const { data } = await axios.post(`${baseURL}/upload/chunk`, formData, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    })

    return data
  }

  snackbarText = ''

  @Mutation
  setSnackbarText(payload: string) {
    this.snackbarText = payload
  }

  // Текущее положение скролла
  scrollY = 0

  @Mutation
  setScrollY(y: number) {
    this.scrollY = y
  }

  // ---------------------------- Audio Player ---------------------------- >>
  isPlaying: number | null = null
  isRecording = false
  audioList: Record<number, number> = {}

  @Mutation
  setIsPlaying(payload: number | null) {
    this.isPlaying = payload
  }

  @Mutation
  setIsRecording(payload: boolean) {
    this.isRecording = payload
  }

  @Mutation
  setAudioList(audio: { currentTime: number, id: number }) {
    this.audioList[audio.id] = audio.currentTime
  }

  // ------------------------------ Notes --------------------------------- >>
  notesVisibility = false
  isCollapse = false

  @Mutation
  toggleDrawerVisibility() {
    this.notesVisibility = !this.notesVisibility
  }

  @Mutation
  hideDrawer() {
    this.notesVisibility = false
  }

  @Mutation
  setDrawerVisibility(value: boolean) {
    this.notesVisibility = value
  }

  @Mutation
  toggleCollapse() {
    this.isCollapse = !this.isCollapse
  }

  // ---------------------------- Timer ---------------------------- >>
  isShowTimer = false
  exerciseDuration = 0
  timePassed = 0

  @Mutation
  showTimer() {
    this.isShowTimer = true
  }

  @Mutation
  hideTimer() {
    this.isShowTimer = false
  }

  @Mutation
  setTimer(payload: { exerciseDuration: number, timePassed?: number }) {
    this.exerciseDuration = payload.exerciseDuration
    this.timePassed = payload.timePassed || 0
  }

  @Mutation
  updateTimerPassed(timePassed: number) {
    this.timePassed = timePassed
  }

  @Mutation
  clearTimer() {
    this.exerciseDuration = 0
    this.timePassed = 0
  }

  @Action({ rawError: true })
  async startWorking(payload: { duration: number, exerciseUUID: string, masterGroupID: number, timePassed: number }) {
    const { data } = await MasterEducationMasterGroupIdExercisesExerciseUuidStartWorkingGetRequest(payload.masterGroupID, payload.exerciseUUID, { loading: false })
    this.showTimer()
    this.setTimer({
      exerciseDuration: payload.duration,
      timePassed: payload.timePassed,
    })
    return data
  }
}

const SystemModule = getModule(System)

export default SystemModule
