





import { Component, Vue } from 'vue-property-decorator'

/* eslint-disable-next-line */
/* @ts-ignore:next-line */
import * as tsParticles from '@/utils/plugins/tsparticles.engine'

@Component
export default class EndYearAnimation extends Vue {
  private animation: any = null

  private mounted() {
    this.particlesInit()
  }

  private icon100 = require('/src/assets/images/icons/100.png')

  private opt: any = {
    fullScreen: {
      zIndex: 0,
    },
    particles: {
      move: {
        direction: 'bottom',
        enable: true,
        gravity: {
          inverse: true,
        },
        outModes: {
          default: 'out',
        },
        size: true,
        speed: {
          min: 1,
          max: 1,
        },
      },
      number: {
        value: 30,
        density: {
          enable: true,
          area: 800,
        },
      },
      opacity: {
        value: 1,
        animation: {
          enable: false,
          startValue: 'max',
          destroy: 'min',
          speed: 0.3,
          sync: true,
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        move: true,
        animation: {
          enable: true,
          speed: 30,
        },
      },
      shape: {
        type: 'image',
        options: {
          image: [
            {
              height: 32,
              particles: {
                opacity: {
                  value: {
                    max: 1,
                    min: 0.1,
                  },
                },
                size: {
                  value: {
                    max: 30,
                    min: 10,
                  },
                },
              },
              src: this.icon100,
              width: 32,
            },
          ],
        },
      },
      size: {
        value: {
          min: 2,
          max: 4,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        move: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
    },
  }

  private async particlesInit() {
    await tsParticles.loadFull(tsParticles.tsParticles)

    this.animation = await tsParticles.tsParticles.load({
      id: 'tsparticles',
      options: this.opt,
    })
  }
}
