import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

@Component
export default class PermissionsMixin extends Mixins(NotifyMixin) {
  protected Permission = Permission

  protected get permissions () {
    return AuthModule.self ? AuthModule.self.permissions : []
  }

  protected hasPermission (rule: Permission): boolean {
    return hasPermission(rule, this.permissions)
  }

  protected hasEveryPermissions (rules: Permission[]): boolean {
    return rules.every(this.hasPermission)
  }

  protected hasSomePermissions (rules: Permission[]): boolean {
    return rules.some(this.hasPermission)
  }

  protected checkPermission (rule: Permission, routeName: string, callback?: any): void {
    if (!this.hasPermission(rule)) {
      this.$router.push({ name: routeName })
      this.notifyError('У вас недостаточно прав')
    } else if (callback) {
      callback()
    }
  }

  protected checkPermissionCondition (condition: boolean, routeName: string, callback?: any): void {
    if (!condition) {
      this.$router.push({ name: routeName })
      this.notifyError('У вас недостаточно прав')
    } else if (callback) {
      callback()
    }
  }
}
