/**
 * Note: Роутинг для раздела "геймификация"
 */

import auth from '@/router/_middleware/auth'
import firstVisit from '@/router/_middleware/firstVisit'
import manageSocials from '@/router/_middleware/manageSocials'

export default [
  {
    children: [
      {
        component: () => import('@/views/master/progress/ProgressFirstVisit.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.progress.first.visit',
        path: '/master/progress/first-visit',
      },
      {
        component: () => import('@/views/master/progress/ProgressDashboard.vue'),
        meta: { middleware: [auth, firstVisit, manageSocials] },
        name: 'master.progress.dashboard',
        path: '/master/progress/dashboard',
      },
      {
        component: () => import('@/views/master/progress/ProgressAchievements.vue'),
        meta: { middleware: [auth, firstVisit, manageSocials] },
        name: 'master.progress.achievements',
        path: '/master/progress/achievements',
      },
      {
        component: () => import('@/views/master/progress/ProgressCourse.vue'),
        meta: { middleware: [auth, firstVisit, manageSocials] },
        name: 'master.progress.course',
        path: '/master/progress/:courseID',
      },
    ],
    component: () => import('@/views/master/progress/Progress.vue'),
    name: 'master.progress.base',
    path: '/master/progress',
    redirect: {
      name: 'master.progress.dashboard',
    },
  },
]
