












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonArrow extends Vue {
  @Prop({
    default: 'button',
    validator (value: string): boolean {
      return !!value.match(/(button|a|router-link)/)
    },
  })
  readonly tag!: string

  @Prop({
    default: 'black',
    validator (value: string): boolean {
      return !!value.match(/(primary|black|grey-base)/)
    },
  })
  readonly skin!: string

  @Prop({ default: 'right' })
  private arrow!: 'right' | 'left'
}
