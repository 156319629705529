

















import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import Note from '@/components/notes/Note.vue'
import NoteEdit from '@/components/notes/NoteEdit.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterNotesModule from '@/store/modules/master/notes'
import { NoteColors, UpdateNoteRequest } from '@/store/types'

@Component({
  components: {
    Note,
    NoteEdit,
  },
})
export default class NoteItem extends NotifyMixin {
  @Prop({ required: true })
  private view!: 'edit' | 'note'

  private get note() {
    return MasterNotesModule.note
  }

  private innerView = this.view
  private isLoaded = false

  private form: UpdateNoteRequest = {
    color: '' as NoteColors,
    content: '',
    favorite: false,
    id: 0,
    title: '',
  }

  private mounted() {
    if (this.note) {
      this.form = {
        color: this.note.color,
        content: this.note.content,
        favorite: this.note.favorite,
        id: this.note.id,
        title: this.note.title,
      }
      this.isLoaded = true
    }
  }

  @Watch('view')
  private watchView() {
    this.innerView = this.view
  }
}
