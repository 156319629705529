import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

// Права на просмотр списка домашних заданий
export function viewDashboard ({ next, nextMiddleware }: any) {
  if (![
    Permission.EDUCATION_EXERCISES_CHECK,
    Permission.EDUCATION_SPECIAL_CHECK,
    Permission.MENTOR_LIST_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашних заданий
export function viewCalendar ({ next, nextMiddleware }: any) {
  if (![
    Permission.EDUCATION_EXERCISES_CHECK,
    Permission.EDUCATION_SPECIAL_CHECK,
    Permission.MENTOR_LIST_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашних заданий
export function viewExercises ({ next, nextMiddleware }: any) {
  if (![
    Permission.EDUCATION_EXERCISES_VIEW,
    Permission.EDUCATION_EXERCISES_CHECK,
    Permission.EDUCATION_SPECIAL_CHECK,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашних заданий
export function specialCourseExercise({ nextMiddleware }: any) {
  if (![
    Permission.EDUCATION_SPECIAL_CHECK,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    // return next({
    //   name: 'profile',
    // })
  }
  return nextMiddleware()
}

// Права на просмотр и проверку списка домашнего задания
export function checkExercise ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.EDUCATION_EXERCISES_CHECK, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр и проверку списка домашнего задания
export function createPersonal ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.EDUCATION_PERSONAL_CREATE, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашних заданий
export function viewLessons ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.EDUCATION_LESSON_LIST, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка домашнего задания
export function viewLesson ({ next, nextMiddleware }: any) {
  if (![
    Permission.EDUCATION_LESSON_VIEW,
    Permission.EDUCATION_LESSON_LIST,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
