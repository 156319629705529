import { editExercises, viewExercises } from '@/router/_middleware/manageBanks'
import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/manager/bank/exercises/List.vue'),
    meta: { middleware: [auth, viewExercises] },
    name: 'manager.bank.exercises',
    path: '/manager/bank/exercises',
  },
  {
    component: () => import('@/views/manager/bank/exercises/New.vue'),
    meta: { middleware: [auth, editExercises] },
    name: 'manager.bank.exercises.new',
    path: '/manager/bank/exercises/new',
  },
  {
    component: () => import('@/views/manager/bank/exercises/Edit.vue'),
    meta: { middleware: [auth, editExercises] },
    name: 'manager.bank.exercises.item.edit',
    path: '/manager/bank/exercises/:exerciseUUID/edit',
  },
]
