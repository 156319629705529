


















import { Component, Prop } from 'vue-property-decorator'
import shave from 'shave'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { NoteShortResource } from '@/store/types'
import MasterNotesModule from '@/store/modules/master/notes'
import AuthModule from '@/store/modules/auth'

@Component
export default class NoteCard extends NotifyMixin {
  @Prop({ required: true })
  private note!: NoteShortResource

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    shave(`#note-title-${this.note.id}`, 24)
  }

  private favorite() {
    MasterNotesModule.toggleFavoriteNote(this.note.id)
      .catch(this.notifyError)
  }

  private toCard() {
    this.$emit('toCard', this.note.id)
  }
}
