








import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TourVisible extends Vue {
  private isVisible = false

  private mounted() {
    this.$bus.$on('showMessage', this.showMessage)
  }

  private destroyed() {
    this.$bus.$off('showMessage', this.showMessage as any)
  }

  private showMessage() {
    this.isVisible = true
  }
}
