










import { Component, Prop } from 'vue-property-decorator'

// components
import NoteEditForm from '@/components/notes/NoteEditForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { NoteResource, UpdateNoteRequest } from '@/store/types'

@Component({
  components: {
    NoteEditForm,
  },
})
export default class NoteEdit extends NotifyMixin {
  @Prop({ required: true })
  private note!: NoteResource

  @Prop({ required: true })
  private form!: UpdateNoteRequest
}
