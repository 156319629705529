import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR } from '@/store'
import {
  ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPutRequest,
  MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPutRequest,
  MasterNewsGetRequest,
  MasterNewsIdGetRequest,
  MasterSalesNewsGetRequest,
  MessagesGetRequest,
  MessagesReadAllPatchRequest,
  NotificationResource,
  NotificationResourcePaginator,
  NotificationsGetRequest,
  NotificationsReadAllPatchRequest,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'

/**
 * Работа с уведомлениями
 * - получение и хранение уведомлений
 * - чтение и удаление уведомлений
 */

@Module({
  dynamic: true,
  name: 'Notification',
  namespaced: true,
  store,
})
class Notification extends VuexModule {
  // ---------------------------- Notifications buffer ---------------------------- >>
  buffer: NotificationResource[] = []

  @Mutation
  addNotificationToBuffer (response: NotificationResource) {
    this.buffer.push(response)
  }

  @Mutation
  deleteNotificationFromBuffer () {
    this.buffer = this.buffer.slice(1)
  }

  // ---------------------------- Notifications modal ---------------------------- >>
  drawerVisibility = false

  @Mutation
  toggleDrawerVisibility () {
    this.drawerVisibility = !this.drawerVisibility
  }

  @Mutation
  hideDrawer () {
    this.drawerVisibility = false
  }

  @Mutation
  setDrawerVisibility(value: boolean) {
    this.drawerVisibility = value
  }

  // ---------------------------- Notifications list ---------------------------- >>
  notifications: NotificationResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setNotifications (response: NotificationResourcePaginator) {
    this.notifications.data.push(...response.data)
    this.notifications.meta = Object.assign({}, response.meta)
  }

  @Mutation
  deleteNotification(ids: string[]) {
    ids.forEach((id: string) => {
      this.notifications.data = this.notifications.data.filter(notification => notification.id !== id)
    })
  }

  @Mutation
  addNotification(response: NotificationResource) {
    this.notifications.data.push(response)
  }

  // Сброс массива сообщений для метода прочтения всех уведомлений
  @Mutation
  removeAllNotifications() {
    this.notifications.data = []
  }

  @Mutation
  readNotification(notificationID: string) {
    const notification = this.notifications.data.find((notification: NotificationResource) => notification.id === notificationID)
    if (notification) {
      notification.isViewed = true

      if (AuthModule.self && AuthModule.self.notificationsCount > 0) {
        AuthModule.setNotificationsCount(AuthModule.self.notificationsCount - 1)
      }
    }
  }

  @Action({ rawError: true })
  async fetchNotifications (page = 1) {
    const { data } = await NotificationsGetRequest({
      loading: false,
      params: {
        page,
        perPage: 50,
      },
    })
    this.setNotifications(data)
    return data
  }

  @Action({ rawError: true })
  async readAllNotifications() {
    await NotificationsReadAllPatchRequest({
      loading: false,
    })
    const data = this.notifications.data.map(notification => ({ ...notification, isViewed: true }))
    this.removeAllNotifications()
    this.setNotifications({ data, meta: this.notifications.meta })
  }

  // ---------------------------- Messages list -------------------------------- >>
  messages: NotificationResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setMessages(response: NotificationResourcePaginator) {
    this.messages.data.push(...response.data)
    this.messages.meta = Object.assign({}, response.meta)
  }

  @Mutation
  addMessage(response: NotificationResource) {
    this.messages.data.push(response)
    this.messages.meta.total++
  }

  // Сброс массива сообщений для метода прочтения всех уведомлений
  @Mutation
  removeAllMessage() {
    this.messages.data = []
  }

  @Mutation
  readMessage(messageID: string) {
    const message = this.messages.data.find((message: NotificationResource) => message.id === messageID)
    if (message) {
      message.isViewed = true

      if (AuthModule.self && AuthModule.self.messagesCount > 0) {
        AuthModule.setMessagesCount(AuthModule.self.messagesCount - 1)
      }
    }
  }

  @Action({ rawError: true })
  async fetchMessages (page = 1) {
    const { data } = await MessagesGetRequest({
      loading: false,
      params: {
        page,
        perPage: 50,
      },
    })
    this.setMessages(data)
    return data
  }

  @Action({ rawError: true })
  async readAllMessages() {
    await MessagesReadAllPatchRequest({
      loading: false,
    })
    const data = this.messages.data.map(message => ({ ...message, isViewed: true }))
    this.removeAllMessage()
    this.setMessages({ data, meta: this.messages.meta })
  }

  // Прочитать сообщение (уведомление) мастера
  @Action({ rawError: true })
  async readMessageMaster(payload: { exerciseUUID: string, groupID: number, taskUUID: string }) {
    await MasterEducationMasterGroupIdExercisesExerciseUuidTaskTaskUuidMessagesPutRequest(payload.groupID, payload.exerciseUUID, payload.taskUUID, {
      loading: false,
    })
  }

  // Прочитать сообщение (уведомление) наставника
  @Action({ rawError: true })
  async readMessageManager(payload: { groupID: number, masterID: number, taskUUID: string }) {
    await ManagerMasterGroupsMasterGroupIdTasksTaskUuidMastersMasterIdMessagesPutRequest(payload.groupID, payload.taskUUID, payload.masterID, {
      loading: true,
    })
  }

  // ----------------------------------- News ---------------------------------- >>
  @Action({ rawError: true })
  async fetchNews() {
    const { data } = await MasterNewsGetRequest()
    return data
  }

  @Action({ rawError: true })
  async fetchNewsItem(id: number) {
    const { data } = await MasterNewsIdGetRequest(id)
    return data
  }

  @Action({ rawError: true })
  async fetchSalesNews() {
    const { data } = await MasterSalesNewsGetRequest()
    return data
  }
}

const NotificationModule = getModule(Notification)

export default NotificationModule
