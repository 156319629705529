import guest from '@/router/_middleware/guest'

export default [
  {
    name: 'auth',
    path: '/auth',
    redirect: '/auth/login',
  },
  {
    component: () => import('@/views/auth/Login.vue'),
    meta: { middleware: [guest] },
    name: 'auth.login',
    path: '/auth/login',
  },
  {
    component: () => import('@/views/auth/Registration.vue'),
    meta: { middleware: [guest] },
    name: 'auth.register',
    path: '/auth/register',
  },
  {
    component: () => import('@/views/auth/RegistrationConfirmation.vue'),
    meta: { middleware: [guest] },
    name: 'auth.register.confirmation',
    path: '/auth/register/:token',
  },
  {
    component: () => import('@/views/auth/Restore.vue'),
    meta: { middleware: [guest] },
    name: 'auth.restore',
    path: '/auth/restore',
  },
  {
    component: () => import('@/views/auth/RestoreConfirmation.vue'),
    meta: { middleware: [guest] },
    name: 'auth.restore.confirmation',
    path: '/auth/restore/:token',
  },
]
