import auth from '@/router/_middleware/auth'
import { manageUsers } from '@/router/_middleware/manageUsers'

import employeesRoutes from './users/employees'
import mastersRoutes from './users/masters'
import rolesRoutes from './users/roles'

export default [
  {
    children: [
      ...employeesRoutes,
      ...mastersRoutes,
      ...rolesRoutes,
    ],
    component: () => import('@/views/manager/Users.vue'),
    meta: { middleware: [auth, manageUsers] },
    name: 'manager.users',
    path: '/manager/users',
  },
]
