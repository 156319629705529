import auth from '@/router/_middleware/auth'
import manageSocials from '@/router/_middleware/manageSocials'

export default [
  {
    children: [
      {
        children: [
          {
            component: () => import('@/views/master/exercises/item/task/Answer.vue'),
            meta: { middleware: [auth, manageSocials] },
            name: 'master.exercises.item.task.answer',
            path: '/master/exercises/:groupID/:exerciseUUID/:taskUUID/answer',
          },
          {
            component: () => import('@/views/master/exercises/item/task/Messages.vue'),
            meta: { middleware: [auth, manageSocials ] },
            name: 'master.exercises.item.task.messages',
            path: '/master/exercises/:groupID/:exerciseUUID/:taskUUID/messages',
          },
        ],
        component: () => import('@/views/master/exercises/item/Task.vue'),
        meta: { middleware: [auth, manageSocials] },
        name: 'master.exercises.item.task',
        path: '/master/exercises/:groupID/:exerciseUUID/:taskUUID',
      },
    ],
    component: () => import('@/views/master/exercises/Item.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.exercises.item',
    path: '/master/exercises/:groupID/:exerciseUUID',
  },
  {
    component: () => import('@/views/master/exercises/List.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.exercises',
    path: '/master/exercises',
  },
]
