











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class NoDataFound extends Vue {
  @Prop({ default: 'white' })
  private skin!: 'white'|'grey'

  @Prop({ default: '$smileBroken' })
  private icon!: string

  @Prop({ default: 'Упс, здесь ничего нет.' })
  private text!: string
}
