import Vue from 'vue'
import Router from 'vue-router'

import middlewarePipeline from '@/router/_middleware/pipeline'

import authRoutes from './auth'
import offerRoutes from './offer'
import managerRoutes from './manager'
import masterRoutes from './master'
import profileRoutes from './profile'
import redirectsRoutes from './redirects'
import docsRoutes from './docs'
import paymentRoutes from './payments'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    ...authRoutes,
    ...docsRoutes,
    ...managerRoutes,
    ...masterRoutes,
    ...offerRoutes,
    ...paymentRoutes,
    ...profileRoutes,
    ...redirectsRoutes,
    {
      name: 'app',
      path: '/',
      redirect: '/auth/login',
    },
    {
      component: () => import('@/views/NotFound.vue'),
      name: 'not_found',
      path: '*',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta || !to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next, to }

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  })
})
export default router
