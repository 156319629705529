import Vue from 'vue'

import { convertSecondsToHoursMinutes, declension } from '@/utils/functions'

Vue.directive('time', {
  bind: (el: HTMLElement, binding) => {
    const time = convertSecondsToHoursMinutes(binding.value)
    const split = time.split(':')
    let hours = +split[0]
    const minutes = +split[1]
    const days = hours >= 24 ? Math.floor(hours / 24) : 0
    hours = hours % 24
    el.innerHTML = `${days ? `${days} ` + declension(days, ['день', 'дня', 'дней']) : ''} ${hours ? `${hours} ` + declension(hours, ['час', 'часа', 'часов']) : ''} ${minutes ? `${minutes} ` + 'мин' : ''}`
  },
  update: (el: HTMLElement, binding) => {
    if (binding.value !== binding.oldValue) {
      const time = convertSecondsToHoursMinutes(binding.value)
      const split = time.split(':')
      let hours = +split[0]
      const minutes = +split[1]
      const days = hours >= 24 ? Math.floor(hours / 24) : 0
      hours = hours % 24
      el.innerHTML = `${days ? `${days} ` + declension(days, ['день', 'дня', 'дней']) : ''} ${hours ? `${hours} ` + declension(hours, ['час', 'часа', 'часов']) : ''} ${minutes ? `${minutes} ` + 'мин' : ''}`
    }
  },
})
