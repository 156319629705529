













import Vue from 'vue'
import Component from 'vue-class-component'

// Components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import { domainName } from '@/utils/constants'

@Component({
  components: {
    ButtonArrow,
  },
})
export default class Footer extends Vue {
  private get domainName(): string {
    return domainName
  }

  private get currentYear() {
    return new Date().getFullYear()
  }

  private get to() {
    return { name: 'docs' }
  }
}
