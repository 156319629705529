









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Back extends Vue {
  @Prop({ default: -1 })
  private back!: number

  private goBack() {
    this.$router.go(this.back)
  }
}
