import {
  viewMentorExercises,
  viewMentorLessons,
  viewMentorMasters,
  viewMentors,
} from '@/router/_middleware/manageMentors'
import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/manager/education/mentors/item/Lesson.vue'),
    meta: { middleware: [auth, viewMentorLessons] },
    name: 'manager.education.mentors.item.lessons.item',
    path: '/manager/education/mentors/:mentorID/lessons/:groupID/:lessonID',
  },
  // {
  //   component: () => import('@/views/manager/education/mentors/item/Master.vue'),
  //   meta: { middleware: [auth, manageMentors] },
  //   name: 'manager.education.mentors.item.masters.item',
  //   path: '/manager/education/mentors/:mentorID/lessons/:groupID/:lessonID',
  // },
  // {
  //   component: () => import('@/views/manager/education/mentors/item/Exercise.vue'),
  //   meta: { middleware: [auth, manageMentors] },
  //   name: 'manager.education.mentors.item.exercises.item',
  //   path: '/manager/education/mentors/:mentorID/exercises/:exerciseUUID',
  // },
  {
    children: [
      {
        component: () => import('@/views/manager/education/mentors/item/Masters.vue'),
        meta: { middleware: [auth, viewMentorMasters] },
        name: 'manager.education.mentors.item.masters',
        path: '/manager/education/mentors/:mentorID/masters',
      },
      {
        component: () => import('@/views/manager/education/mentors/item/Information.vue'),
        meta: { middleware: [auth, viewMentors] },
        name: 'manager.education.mentors.item.information',
        path: '/manager/education/mentors/:mentorID/information',
      },
      {
        component: () => import('@/views/manager/education/mentors/item/Lessons.vue'),
        meta: { middleware: [auth, viewMentorLessons] },
        name: 'manager.education.mentors.item.lessons',
        path: '/manager/education/mentors/:mentorID/lessons',
      },
      {
        component: () => import('@/views/manager/education/mentors/item/Exercises.vue'),
        meta: { middleware: [auth, viewMentorExercises] },
        name: 'manager.education.mentors.item.exercises',
        path: '/manager/education/mentors/:mentorID/exercises',
      },
    ],
    component: () => import('@/views/manager/education/mentors/Item.vue'),
    meta: { middleware: [auth, viewMentors] },
    name: 'manager.education.mentors.item',
    path: '/manager/education/mentors/:mentorID',
  },
  {
    component: () => import('@/views/manager/education/mentors/List.vue'),
    meta: { middleware: [auth, viewMentors] },
    name: 'manager.education.mentors',
    path: '/manager/education/mentors',
  },
]
