import Vue from 'vue'
import VueMask from 'v-mask'

import Agreement from '@/components/_uikit/controls/Agreement.vue'
import Attachment from '@/components/_uikit/Attachment.vue'
import Avatar from '@/components/_uikit/Avatar.vue'
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import LinkPrimary from '@/components/_uikit/links/LinkPrimary.vue'
import Logo from '@/components/_uikit/Logo.vue'
import NoDataFound from '@/components/NoDataFound.vue'
import PageContainer from '@/components/layout/PageContainer.vue'
import PageHeader from '@/components/layout/PageHeader.vue'

Vue.use(VueMask)

Vue.component('Agreement', Agreement)
Vue.component('Attachment', Attachment)
Vue.component('Avatar', Avatar)
Vue.component('ButtonIconAction', ButtonIconAction)
Vue.component('ButtonTextIcon', ButtonTextIcon)
Vue.component('CardWrapper', CardWrapper)
Vue.component('LinkPrimary', LinkPrimary)
Vue.component('Logo', Logo)
Vue.component('NoDataFound', NoDataFound)
Vue.component('PageContainer', PageContainer)
Vue.component('PageHeader', PageHeader)
