import auth from '@/router/_middleware/auth'
import { createPersonal, viewLesson, viewLessons } from '@/router/_middleware/manageEducation'

export default [
  {
    children: [
      {
        component: () => import('@/views/manager/education/lessons/lesson/New.vue'),
        meta: { middleware: [auth, createPersonal] },
        name: 'manager.education.lessons.item.new',
        path: '/manager/education/lessons/:groupID/new',
      },
      {
        component: () => import('@/views/manager/education/lessons/lesson/Edit.vue'),
        meta: { middleware: [auth, createPersonal] },
        name: 'manager.education.lessons.item.edit',
        path: '/manager/education/lessons/:groupID/:lessonID/edit',
      },
      {
        component: () => import('@/views/manager/education/lessons/lesson/Item.vue'),
        meta: { middleware: [auth, viewLesson] },
        name: 'manager.education.lessons.item',
        path: '/manager/education/lessons/:groupID/:lessonID',
      },
    ],
    component: () => import('@/views/manager/education/lessons/Lesson.vue'),
    meta: { middleware: [auth, viewLessons] },
    path: '/manager/education/lessons/:groupID',
  },
  {
    component: () => import('@/views/manager/education/lessons/List.vue'),
    meta: { middleware: [auth, viewLessons] },
    name: 'manager.education.lessons',
    path: '/manager/education/lessons',
  },
]
