import { isEqual } from 'lodash'
import Vue from 'vue'

import { formatDate } from '@/utils/functions'

Vue.directive('date', {
  bind: (el: HTMLElement, binding) => {
    const { date, mask, localTZ } = binding.value
    el.innerHTML = formatDate(date, mask, localTZ)
  },
  update: (el: HTMLElement, binding) => {
    if (!isEqual(binding.value, binding.oldValue)) {
      const { date, mask, localTZ } = binding.value
      el.innerHTML = formatDate(date, mask, localTZ)
    }
  },
})
