import auth from '@/router/_middleware/auth'
import { manageBanks } from '@/router/_middleware/manageBanks'

import depositoryRoutes from './bank/depository'
import exercisesRoutes from './bank/exercises'

export default [
  {
    children: [
      ...depositoryRoutes,
      ...exercisesRoutes,
    ],
    component: () => import('@/views/manager/Bank.vue'),
    meta: { middleware: [auth, manageBanks] },
    name: 'manager.bank',
    path: '/manager/bank',
  },
]
