import { requestGamification as request } from '@/utils/services/client'
/**
 * Этот файл сгенерирован автоматически. Не вносите изменения вручную.
 */

export interface PartingWordResource {
	text: string,
	author: string,
}

export interface LessonResource {
	groupId: number,
	lessonId: number,
	name: string,
	time: string,
}

export interface ExerciseResource {
	groupId: number,
	exerciseUuid: number,
	taskUuid: number,
	name: string,
	time: string,
}

export interface AllCurrentResource {
	all: number,
	current: number,
}

export interface StatisticResource {
	month: AllCurrentResource,
	livesLost: AllCurrentResource,
	lessons: AllCurrentResource,
	exercises: AllCurrentResource,
}

export interface UserCourseStatisticResource {
	percent: number,
	character: CharacterShortResource,
	currentRank: UserRankResource,
	statistic: StatisticResource,
	lessonsCounts?: AllCurrentResource,
	lessons?: LessonResource[],
	exercisesCounts?: AllCurrentResource,
	exercises?: ExerciseResource[],
}

export interface CharacterUpdateRequest {
	username?: string,
	about?: string,
	equipments?: string[],
}

export interface CharacterLargeResource {
	username: string|null,
	about: string|null,
	isViewedPartingWord: boolean,
}

export interface CharacterShortResource {
	username: string,
	about: string,
}

export interface ColorAttributeResource {
	primary: string,
	secondary: string,
	eyes: string,
	outline: string,
	shadow: string,
}

export interface ItemAttributeResource {
	color?: ColorAttributeResource,
	texture?: ColorAttributeResource,
}

export enum ItemType {
	CHARACTER_COLOR = 'character_color',
	CHARACTER_POSE = 'character_pose',
	BACKGROUND_COLOR = 'background_color',
	FRAME_TYPE = 'frame_type',
	FRAME_COLOR = 'frame_color',
}

export enum ItemSubType {
	COLOR = 'color',
	TEXTURE = 'texture',
}

export interface ItemLargeResource {
	uuid: string,
	slug: string,
	type: ItemType,
	subtype?: ItemSubType,
	isEquipped: boolean,
	attributes?: ItemAttributeResource,
}

export interface AchievementResource {
	slug: string,
	title: string,
	description: string,
	isSecret: boolean,
	receivedAt: string|null,
	progress: number,
	maxProgress: number,
}

export interface UserRankResource {
	name: string,
	description: string,
	image: string,
	position: number,
	course: CourseShortResource,
}

export interface CourseShortResource {
	id: number,
	name: string,
	type: 'default'|'special',
	subject: string,
}

export interface CharacterWithItemsResource {
	character: CharacterLargeResource,
	items: ItemLargeResource[],
}

export interface CharacterWithItemsAndRanksResource {
	character: CharacterLargeResource,
	items: ItemLargeResource[],
	ranks: UserRankResource[],
	hasCourses: boolean,
	achievements: AchievementResource[],
}

export interface UserRankWithInfoResource {
	name: string,
	description: string,
	button?: string,
	image: string,
	position: number,
	course?: CourseShortResource,
	lessons?: number,
	exercises?: number,
}

export interface CourseInfoResource {
	id: number,
	name: string,
	hasGamification: boolean,
}

export interface CharacterPartingWordGetParams {
	getRandom?: boolean,
}

// Получить весь список ачивментов
export const AchievementsGetRequest = (options?: any) => request<AchievementResource[]>('/achievements', { method: 'GET', ...options })

// Получить только выданные пользователю ачивменты
export const AchievementsUsersGetRequest = (options?: any) => request<AchievementResource[]>('/achievements/users', { method: 'GET', ...options })

// Получить данные персонажа
export const CharacterGetRequest = (options?: any) => request<CharacterWithItemsAndRanksResource>('/character', { method: 'GET', ...options })

// Обновить данные персонажа
export const CharacterPatchRequest = (body: CharacterUpdateRequest, options?: any) => request<CharacterWithItemsResource>('/character', { body, method: 'PATCH', ...options })

// Получить весь инвентарь персонажа
export const CharacterInventoryGetRequest = (options?: any) => request<ItemLargeResource[]>('/character/inventory', { method: 'GET', ...options })

// Получить наставление для персонажа
export const CharacterPartingWordGetRequest = (params?: CharacterPartingWordGetParams, options?: any) => request<PartingWordResource>('/character/parting-word', { method: 'GET', params, ...options })

// Получить все возможные ранги по курсу
export const CourseCourseIdRanksGetRequest = (courseId: number, options?: any) => request<UserRankResource[]>(`/course/${courseId}/ranks`, { method: 'GET', ...options })

// Получить статистику пользователя по рангам курса (дополнительно приложены оставшиеся ранги курса)
export const CourseCourseIdRanksStatisticsGetRequest = (courseId: number, options?: any) => request<UserRankWithInfoResource[]>(`/course/${courseId}/ranks/statistics`, { method: 'GET', ...options })

// Получить общую статистику для пользователя по курсу
export const CourseCourseIdStatisticsGetRequest = (courseId: number, options?: any) => request<UserCourseStatisticResource>(`/course/${courseId}/statistics`, { method: 'GET', ...options })

// Обновить время последнего посещения пользователя
export const UserCheckGetRequest = (options?: any) => request<any>('/user/check', { method: 'GET', ...options })

// Получить список курсов пользователя
export const UserCoursesGetRequest = (options?: any) => request<CourseInfoResource[]>('/user/courses', { method: 'GET', ...options })

// Получить список новых рангов пользователя
export const UserUpdatesGetRequest = (options?: any) => request<UserRankWithInfoResource[]>('/user/updates', { method: 'GET', ...options })
