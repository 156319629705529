





































































import { Component, Prop } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterTutorialsModule from '@/store/modules/master/tutorials'

@Component({
  components: {
    ButtonTextIcon,
  },
})
export default class CalendarTutorial extends NotifyMixin {
  @Prop()
  private scroll!: vuescroll

  private get isTourStart() {
    return MasterTutorialsModule.calendarTutorialActive
  }

  private callbacks = {
    onNextStep: this.onNextStep,
    onSkip: this.tourDestroy,
    onStart: this.tourStart,
    onStop: this.tourDestroy,
  }

  // флаг отправки метрики
  // нужен, что метрика не отправлялась дважды
  private isMetricSent = false

  private steps = [
    {
      before: () => this.handleScrollToTop(),
      content: `
        <p>В этом блоке собраны основные события: <strong>уроки, задания</strong> и <strong>полезные материалы</strong>.</p>
        <p>У элементов существует четыре состояния:</p>
        <div class="description">
          <div class="description-event">
            <div class="item">
              <div class="v-event v-event_grey-pink is-passed">
                <div class="event__content">
                  <div class="event__content-event">
                    <div class="event text-caption">
                      <span aria-hidden="true" class="v-icon notranslate event__content-icon grey-pink--text" style="font-size: 16px; height: 16px; width: 16px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 16px; height: 16px; width: 16px;"><path d="M19.9732 9.67407C19.9227 9.53061 19.8309 9.40459 19.709 9.31131C19.587 9.21804 19.4401 9.1615 19.2861 9.14856L14.7403 8.49755L12.7031 4.44246C12.6376 4.30985 12.5355 4.19801 12.4083 4.11976C12.2812 4.0415 12.1341 4 11.984 4C11.834 4 11.6869 4.0415 11.5597 4.11976C11.4326 4.19801 11.3304 4.30985 11.265 4.44246L9.22779 8.48971L4.68198 9.14856C4.53411 9.16919 4.39511 9.2301 4.28072 9.32438C4.16634 9.41865 4.08116 9.54252 4.03486 9.68192C3.99247 9.81814 3.98867 9.96312 4.02385 10.1013C4.05904 10.2395 4.13189 10.3657 4.23458 10.4663L7.53409 13.6037L6.73518 18.0588C6.70666 18.2058 6.72159 18.3578 6.77822 18.4968C6.83485 18.6358 6.9308 18.756 7.05475 18.8431C7.17556 18.9279 7.31805 18.978 7.46622 18.9877C7.6144 18.9974 7.7624 18.9663 7.8936 18.898L11.984 16.8038L16.0585 18.9059C16.1706 18.968 16.2973 19.0004 16.426 19C16.5952 19.0006 16.7603 18.9484 16.8973 18.851C17.0213 18.7638 17.1172 18.6436 17.1739 18.5046C17.2305 18.3657 17.2454 18.2137 17.2169 18.0666L16.418 13.6115L19.7175 10.4741C19.8329 10.3782 19.9181 10.2521 19.9634 10.1104C20.0086 9.96883 20.012 9.81751 19.9732 9.67407Z"></path></svg></span>
                      <span class="event__content-time">12:00</span>
                      <span class="event__content-name">Полезное</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-caption"> — просмотренные</span>
            </div>
            <span class="text-caption teal--text text--darken-4">События, которые вы уже посмотрели и завершили</span>
          </div>
          <div class="description-event">
            <div class="item">
              <div class="v-event v-event_orange">
                <div class="event__content">
                  <div class="event__content-event">
                    <div class="event text-caption">
                      <span aria-hidden="true" class="v-icon notranslate event__content-icon orange--text" style="font-size: 16px; height: 16px; width: 16px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 16px; height: 16px; width: 16px;"><path d="M20 8.192a.8.8 0 00-.232-.568l-3.392-3.392A.8.8 0 0015.808 4a.8.8 0 00-.568.232l-2.264 2.264-8.744 8.743a.8.8 0 00-.232.568V19.2a.8.8 0 00.8.8h3.392a.8.8 0 00.608-.232l8.696-8.743L19.768 8.8a.953.953 0 00.176-.264.8.8 0 000-.192.566.566 0 000-.112l.056-.04zM7.864 18.399H5.6v-2.264l7.944-7.943 2.264 2.264-7.944 7.943zm9.072-9.071l-2.264-2.264 1.136-1.128 2.256 2.256-1.128 1.136z"></path></svg></span>
                      <span class="event__content-time">13:00</span>
                      <span class="event__content-name">Задание</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-caption"> — доступные</span>
            </div>
            <span class="text-caption teal--text text--darken-4">Эти события уже открыты для просмотра и выполнения</span>
          </div>
          <div class="description-event">
            <div class="item">
              <div class="v-event v-event_green no-start">
                <div class="event__content">
                  <div class="event__content-event">
                    <div class="event text-caption">
                      <span aria-hidden="true" class="v-icon notranslate event__content-icon green--text" style="font-size: 16px; height: 16px; width: 16px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 16px; height: 16px; width: 16px;"><path d="M20 8.192a.8.8 0 00-.232-.568l-3.392-3.392A.8.8 0 0015.808 4a.8.8 0 00-.568.232l-2.264 2.264-8.744 8.743a.8.8 0 00-.232.568V19.2a.8.8 0 00.8.8h3.392a.8.8 0 00.608-.232l8.696-8.743L19.768 8.8a.953.953 0 00.176-.264.8.8 0 000-.192.566.566 0 000-.112l.056-.04zM7.864 18.399H5.6v-2.264l7.944-7.943 2.264 2.264-7.944 7.943zm9.072-9.071l-2.264-2.264 1.136-1.128 2.256 2.256-1.128 1.136z"></path></svg></span>
                      <span class="event__content-time">14:00</span>
                      <span class="event__content-name">Задание</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-caption"> — предстоящие</span>
            </div>
            <span class="text-caption teal--text text--darken-4">Запланированные, но пока недоступные события</span>
          </div>
          <div class="description-event">
            <div class="item">
              <div class="v-event v-event_grey no-available">
                <div class="event__content">
                  <div class="event__content-event">
                    <div class="event text-caption">
                      <span aria-hidden="true" class="v-icon notranslate event__content-icon teal--text text--darken-4" style="font-size: 16px; height: 16px; width: 16px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 16px; height: 16px; width: 16px;"><path d="M20 8.192a.8.8 0 00-.232-.568l-3.392-3.392A.8.8 0 0015.808 4a.8.8 0 00-.568.232l-2.264 2.264-8.744 8.743a.8.8 0 00-.232.568V19.2a.8.8 0 00.8.8h3.392a.8.8 0 00.608-.232l8.696-8.743L19.768 8.8a.953.953 0 00.176-.264.8.8 0 000-.192.566.566 0 000-.112l.056-.04zM7.864 18.399H5.6v-2.264l7.944-7.943 2.264 2.264-7.944 7.943zm9.072-9.071l-2.264-2.264 1.136-1.128 2.256 2.256-1.128 1.136z"></path></svg></span>
                      <span class="event__content-time">15:00</span>
                      <span class="event__content-name">Задание</span>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-caption"> — неоплаченные</span>
            </div>
            <span class="text-caption teal--text text--darken-4">Для доступа к ним требуется оплата</span>
          </div>
        </div>
      `,
      header: {
        title: 'Календарь',
      },
      options: {
        labels: {
          buttonNext: 'Верхнее меню',
        },
      },
      params: {
        enableScrolling: false,
        placement: 'right-start',
      },
      target: '#calendar-tour-step-1',
    },
    {
      before: (type: 'next' | 'previous') => new Promise(resolve => {
        if (type === 'previous') {
          this.$bus.$emit('showCalendarLegend', false)
          resolve(true)
        } else {
          resolve(true)
        }
      }),
      content: '<p>Это инструмент управления календарём. Здесь ты можешь:</p><p><strong>Изменить месяц</strong> — переключайся между месяцами для просмотра событий.</p><p><strong>Поменять отображение</strong> — выбирай удобный формат: день, неделя или месяц.</p><p><strong>Посмотреть легенду</strong> — узнай обозначения уроков, домашек, полезных материалов и соответствующие цвета курсов. А также настрой отображение нужных элементов в календаре.</p>',
      header: {
        title: 'Верхнее меню',
      },
      options: {
        labels: {
          buttonNext: 'Легенда',
          buttonPrevious: 'Календарь',
        },
      },
      params: {
        enableScrolling: false,
        placement: 'right-start',
      },
      target: '#calendar-tour-step-2',
    },
    {
      before: (type: 'next' | 'previous') => new Promise(resolve => {
        if (type === 'next') {
          this.$bus.$emit('showCalendarLegend', true)
        }
        resolve(true)
      }),
      content: '<p>Легенда поможет тебе настроить отображение событий по вашим предпочтениям:</p><p><strong>Иконки</strong> — показывают, как обозначены уроки, домашние задания и полезные материалы.</p><p><strong>Цвет</strong> — каждый предмет имеет свой уникальный цвет, что помогает быстро различать их в расписании.</p><p><strong>Настройка отображения</strong> — ты можешь выбрать, какие элементы показывать в календаре для каждого курса.</p><p>Например, можно настроить так, чтобы для одного курса отображались только уроки, а для другого — только домашки.</p>',
      header: {
        title: 'Легенда',
      },
      options: {
        labels: {
          buttonPrevious: 'Верхнее меню',
        },
      },
      params: {
        enableScrolling: false,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 24],
            },
          },
        ],
        placement: 'left-start',
      },
      target: '#calendar-tour-step-3',
    },
  ]

  private mounted() {
    this.$bus.$on('startCalendarTour', this.startTour)
  }

  private completeTour() {
    MasterTutorialsModule.setCalendarTutorialActive(false)
    this.$tours['calendar-tour'].finish()
  }

  private fetchCompleteTour() {
    if (AuthModule.self && !AuthModule.self.calendarTutorialPassed) {
      AuthModule.completeCalendarTour()
        .catch(this.notifyError)
    }
  }

  private startTour() {
    setTimeout(() => {
      this.$tours['calendar-tour'].start()
    }, 500)
  }

  private onNextStep(step: number) {
    if (!this.isMetricSent && step === 1)
      try {
        this.$metrika.reachGoal('next_second_step')
        this.isMetricSent = true
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
  }

  private tourDestroy() {
    this.$bus.$emit('showCalendarLegend', false)
    if (!this.isMetricSent)
      try {
        if (!this.$tours['calendar-tour'].currentStep) // тутор закрыли на 1м шаге
          this.$metrika.reachGoal('close_first_step')
        if (this.$tours['calendar-tour'].currentStep === 1) // тутор закрыли на 2м шаге
          this.$metrika.reachGoal('close_second_step')
        this.isMetricSent = true
      } catch {
        // eslint-disable-next-line
        console.error('Отправка метрики заблокирована расширением браузера')
      }
    this.fetchCompleteTour()
    MasterTutorialsModule.setCalendarTutorialActive(false)
  }

  private tourStart() {
    MasterTutorialsModule.setCalendarTutorialActive(true)
  }

  private handleScrollToTop() {
    this.scroll.scrollTo({ x:0, y: 0 }, 500, 'easeInOutQuad')

    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
      })
    }, 450)
  }
}
