















import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import { MonthShortResource, PackageShortResource } from '@/store/types'

@Component
export default class LineCourse extends Vue {
  @Prop({ default: () => ([]) })
  private courseMonths!: MonthShortResource[]

  @Prop({ required: true })
  private itemPackage!: PackageShortResource

  @Prop({ default: false })
  private isProlongation!: boolean

  private get monthsLineStyles() {
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${this.lineCourseMonths.length}, 1fr)`,
    }
  }

  private get lineCourseMonths() {
    return this.courseMonths.filter(item => item.isVisibleInShop)
  }

  // показывать линейку? если в пакете покупки/продления участвует месяц, который скрыт на линейке, то она не отображается
  private get isShowLine() {
    return this.courseMonths.filter(_item => {
      return this.itemPackage.months.some(item => {
        return item.value === _item.id
      })
    }).every(item => item.isVisibleInShop)
  }

  // сколько месяцев было до
  private get howMonthsPassed() {
    const beforeIndex = this.courseMonths.findIndex(item => item.id === this.itemPackage.months[0].value)
    return this.courseMonths.slice(0, beforeIndex).filter(item => item.isVisibleInShop).length
  }

  // сколько месяцев будет после
  private get howMonthsBe() {
    const afterIndex = this.courseMonths.findIndex(item => item.id === this.itemPackage.months[this.itemPackage.months.length - 1].value)
    return this.courseMonths.slice(afterIndex + 1).filter(item => item.isVisibleInShop).length
  }
}
