import auth from '@/router/_middleware/auth'

export default [
  {
    children: [
      {
        component: () => import('@/components/views/courses/My.vue'),
        meta: { middleware: [auth] },
        name: 'master.store.my',
        path: '/master/store/my',
      },
      {
        component: () => import('@/components/views/store/List.vue'),
        meta: { middleware: [auth] },
        name: 'master.store.shop',
        path: '/master/store/shop',
      },
    ],
    component: () => import('@/views/master/store/Store.vue'),
    meta: { middleware: [auth] },
    name: 'master.store',
    path: '/master/store',
  },
  {
    component: () => import('@/views/master/store/Item.vue'),
    meta: { middleware: [auth] },
    name: 'master.store.item',
    path: '/master/store/:courseID',
  },
]
