import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  EducationMasterGroupResource,
  ManagerMasterGroupsSelectGetRequest,
  ManagerMasterGroupsSelectMasterGroupIdGetRequest,
} from '@/store/types'
import store from '@/store'

/**
 * Процесс обучения со стороны наставника. Модуль затрагивает несколько интерфейсов наставника
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MentorEducation',
  namespaced: true,
  store,
})
class MentorEducation extends VuexModule {
  // ---------------------------- Master groups ---------------------------- >>
  // Current
  currentMasterGroupID = -1

  // Переменная для хранения айди выбранного месяца
  savedMasterGroupMonthID: number | string | null = null

  @Mutation
  setCurrentMasterGroupID(payload: number) {
    this.currentMasterGroupID = payload
  }

  get currentMasterGroup(): EducationMasterGroupResource | null {
    return this.masterGroups.find(group => group.id === this.currentMasterGroupID) || null
  }

  // List
  masterGroups: EducationMasterGroupResource[] = []

  @Mutation
  setMasterGroups (payload: EducationMasterGroupResource[]) {
    this.masterGroups = [...payload]
  }

  @Action({ rawError: true })
  async fetchMasterGroups (fetch = false) {
    if (!this.masterGroups.length || fetch) {
      const { data } = await ManagerMasterGroupsSelectGetRequest()
      this.setMasterGroups(data)

      if (data.length) {
        this.setCurrentMasterGroupID(data[0].id)
      }
      return data
    }
    return this.masterGroups
  }

  // Actions
  @Action({ rawError: true })
  async fetchMasterGroupById (id: number) {
    const { data } = await ManagerMasterGroupsSelectMasterGroupIdGetRequest(id)
    this.setCurrentMasterGroupID(data.id)
    return data
  }

  // Actions
  @Action({ rawError: true })
  async fetchMasterGroupByIdWithoutSideEffect (id: number) {
    const { data } = await ManagerMasterGroupsSelectMasterGroupIdGetRequest(id)
    return data
  }

  @Mutation
  setSavedMasterGroupMounthID(payload: number | null) {
    this.savedMasterGroupMonthID = payload
  }
}

const MentorEducationModule = getModule(MentorEducation)

export default MentorEducationModule
