


























import { Component, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import OptionWithMark from '@/components/_uikit/controls/OptionWithMark.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { CreateNoteRequest, UpdateNoteRequest } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    OptionWithMark,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class NoteEditForm extends NotifyMixin {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private form!: CreateNoteRequest | UpdateNoteRequest

  @Prop({ default: false })
  private isEdit!: boolean

  private get colors() {
    return DictionaryModule.colors
  }

  private editable() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.isEdit) {
            this.$emit('editNote', this.form)
          } else {
            const form = {
              color: this.form.color,
              content: this.form.content,
              favorite: this.form.favorite,
              title: this.form.title,
            }
            this.$emit('createNote', form)
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private deleteNote() {
    this.confirm.open(
      'Удаление заметки',
      'Вы действительно хотите удалить заметку?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.$emit('deleteNote', (this.form as UpdateNoteRequest).id)
      })
      .catch(() => { return })
  }
}
