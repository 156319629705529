
































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

// components
import LineCourse from '@/components/LineCourse.vue'
import Tag from '@/components/_uikit/Tag.vue'
// store
import { CourseType, MonthShortResource, PackageShortResource, SaleTargetCource } from '@/store/types'

@Component({
  components: {
    LineCourse,
    Tag,
  },
})
export default class PackageRadioSelect extends Vue {
  @PropSync('value', { required: true })
  private innerValue!: number

  @Prop({ default: () => ([]) })
  private packages!: PackageShortResource[]

  @Prop({ default: () => ([]) })
  private packagesWithDiscounts!: PackageShortResource[]

  @Prop({ default: () => ([]) })
  private courses!: SaleTargetCource[]

  @Prop({ default: null })
  private fakePrice!: null | number

  @Prop({ default: false })
  private isSpecial!: boolean

  @Prop({ default: false })
  private withTypeHint!: boolean

  @Prop({ default: false })
  private about!: boolean

  @Prop({ default: () => ([]) })
  private courseMonths!: MonthShortResource[]

  @Prop({ default: false })
  private isProlongation!: boolean

  private get lineCourseMonths() {
    return this.courseMonths.filter(item => item.isVisibleInShop)
  }

  private special = CourseType.SPECIAL
}
