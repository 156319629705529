import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { DateTime } from 'luxon'

import store from '@/store'
import {
  EducationMasterGroupResource,
  MasterEducationMasterGroupsCourseCourseIdGetRequest, MasterEducationMasterGroupsGetParams,
  MasterEducationMasterGroupsGetRequest, MasterEducationMasterGroupsPutRequest, VisibleCourseRequest,
} from '@/store/types'
import { compareMonths, parseDateToMilliseconds } from '@/utils/functions'

/**
 * Процесс обучения мастера. Модуль затрагивает несколько интерфейсов мастера
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterEducation',
  namespaced: true,
  store,
})
class MasterEducation extends VuexModule {
  // ---------------------------- Master groups ---------------------------- >>
  // Current
  currentMasterGroupID = -1

  // Мастер-группа для прохождения туториала (курс должен быть доступен)
  currentMasterGroupCourseID: null | number = null

  previousMasterGroupID: number | null = null

  // Переменная для хранения айди выбранного месяца
  savedMasterGroupMonthID: number | string | null = null

  @Mutation
  setCurrentMasterGroupID(payload: number) {
    this.currentMasterGroupID = payload
  }

  @Mutation
  setCurrentMasterGroupCourseID(payload: number | null) {
    this.currentMasterGroupCourseID = payload
  }

  get currentMasterGroup (): EducationMasterGroupResource | null {
    return this.masterGroups.find(group => group.id === this.currentMasterGroupID) || null
  }

  // List
  masterGroups: EducationMasterGroupResource[] = []

  @Mutation
  setMasterGroups (payload: EducationMasterGroupResource[]) {
    this.masterGroups = [...payload]
  }

  @Mutation
  addMasterGroup(payload: EducationMasterGroupResource) {
    this.masterGroups.push(payload)
  }

  @Mutation
  deleteMasterGroup() {
    this.masterGroups = this.masterGroups.filter(group => group.isVisible)
    const findMasterGroup = this.masterGroups.find(group => group.id === this.currentMasterGroupID)
    if (!findMasterGroup) {
      this.currentMasterGroupID = this.masterGroups[0].id
    }
  }

  @Action({ rawError: true })
  async fetchMasterGroups() {
    const { data } = await MasterEducationMasterGroupsGetRequest()
    this.setMasterGroups(data)

    if (data.length) {
      this.setCurrentMasterGroupID(data.filter(group => group.dashboardShow)[0].id)


      // Ищем мастер-группу, которая удовлетворяет следующим условиям:
      // 1 - Дата открытия дашборда курса наступила
      // 2 - Есть купленные месяца
      // 3 - Текущий месяц приобретен мастером ИЛИ дата начала курса НЕ наступила
      // ИЛИ дата начала и окончания курса прошли
      const find = data.find(masterGroup => {
        const _month = masterGroup.months.map(month => DateTime.fromSQL(month.name).toFormat('MM-yyyy')).includes(DateTime.now().toFormat('MM-yyyy'))
        return (parseDateToMilliseconds(masterGroup.dates.open ?? masterGroup.dates.start) < Date.now() && masterGroup.months.length && (_month || parseDateToMilliseconds(masterGroup.dates.start) > Date.now())) || (masterGroup.months.length && parseDateToMilliseconds(masterGroup.dates.open ?? masterGroup.dates.start) < Date.now() && parseDateToMilliseconds(masterGroup.dates.end) < Date.now())
      })
      // Если дата окончания курса не наступила - передаем id, иначе, если последний купленный месяц сопадает с месяцем окончания курса, то также передаем id. Иначе передаем null.
      this.setCurrentMasterGroupCourseID(find && parseDateToMilliseconds(find.dates.end) > Date.now() ? find.id : find && compareMonths(find.months[find.months.length - 1].name, find.dates.end) ? find.id : null)
    }
    return data
  }

  @Action({ rawError: true })
  async fetchMasterGroupsAll(payload?: MasterEducationMasterGroupsGetParams) {
    const { data } = await MasterEducationMasterGroupsGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async updateMasterGroups(payload: VisibleCourseRequest) {
    const { data } = await MasterEducationMasterGroupsPutRequest(payload)
    this.setMasterGroups(data)

    if (data.length) {
      this.setCurrentMasterGroupID(data.filter(group => group.dashboardShow)[0].id)


      // Ищем мастер-группу, которая удовлетворяет следующим условиям:
      // 1 - Дата открытия дашборда курса наступила
      // 2 - Есть купленные месяца
      // 3 - Текущий месяц приобретен мастером ИЛИ дата начала курса НЕ наступила
      // ИЛИ дата начала и окончания курса прошли
      const find = data.find(masterGroup => {
        const _month = masterGroup.months.map(month => DateTime.fromSQL(month.name).toFormat('MM-yyyy')).includes(DateTime.now().toFormat('MM-yyyy'))
        return (parseDateToMilliseconds(masterGroup.dates.open ?? masterGroup.dates.start) < Date.now() && masterGroup.months.length && (_month || parseDateToMilliseconds(masterGroup.dates.start) > Date.now())) || (masterGroup.months.length && parseDateToMilliseconds(masterGroup.dates.open ?? masterGroup.dates.start) < Date.now() && parseDateToMilliseconds(masterGroup.dates.end) < Date.now())
      })
      // Если дата окончания курса не наступила - передаем id, иначе, если последний купленный месяц сопадает с месяцем окончания курса, то также передаем id. Иначе передаем null.
      this.setCurrentMasterGroupCourseID(find && parseDateToMilliseconds(find.dates.end) > Date.now() ? find.id : find && compareMonths(find.months[find.months.length - 1].name, find.dates.end) ? find.id : null)
    }
    return data
  }

  // Actions
  @Action({ rawError: true })
  async fetchMasterGroupByCourse (courseID: number) {
    const { data } = await MasterEducationMasterGroupsCourseCourseIdGetRequest(courseID)
    this.setCurrentMasterGroupID(data.id)
    return data
  }

  // MY_STEP: ~ Не сломать!
  // Запись предыдущей мастер группы (Связанно с сокрытием курсов)
  @Mutation
  setPreviousMasterGroupID(payload: number) {
    this.previousMasterGroupID = payload
  }

  @Mutation
  setSavedMasterGroupMounthID(payload: number | null) {
    this.savedMasterGroupMonthID = payload
  }
}

const MasterEducationModule = getModule(MasterEducation)

export default MasterEducationModule
