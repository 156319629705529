import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import { Permission } from '@/store/types'
import { hasPermission } from '@/utils/functions'

// Права на управление разделом "Покупки и оплаты"
export function manageCommerce ({ next, nextMiddleware }: any) {
  if (![
    Permission.ORDERS_CREATE,
    Permission.ORDERS_VIEW,
    Permission.ORDERS_PROMO_CREATE,
    Permission.ORDERS_LINK_LIST,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр заказов
export function viewOrders ({ next, nextMiddleware }: any) {
  if (![
    Permission.ORDERS_CREATE,
    Permission.ORDERS_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на создание и редактирование заказов
export function createOrders ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.ORDERS_CREATE, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на управление разделом "Промокоды"
export function managePromos ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.ORDERS_PROMO_CREATE, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на управление разделом "Ссылки на покупку курсов"
export function manageCoursesLinks ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.ORDERS_LINK_LIST, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на управление разделом "Скидки на предмет"
export function manageDiscounts({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.ORDERS_SALES_CREATE, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
