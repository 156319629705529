import { isEqual } from 'lodash'
import Vue from 'vue'

import { getMonthsRange } from '@/utils/functions'

Vue.directive('months-range', {
  bind: (el: HTMLElement, binding) => {
    const { months, mask } = binding.value
    el.innerHTML = getMonthsRange(months, mask)
  },
  update: (el: HTMLElement, binding) => {
    if (!isEqual(binding.value, binding.oldValue)) {
      const { months, mask } = binding.value
      el.innerHTML = getMonthsRange(months, mask)
    }
  },
})
