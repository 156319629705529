import Vue from 'vue'

import { transformSubject } from '@/utils/functions'

Vue.directive('subject', {
  bind: (el: HTMLElement, binding) => {
    const subject = binding.value
    el.innerHTML = transformSubject(subject)
  },
  update: (el: HTMLElement, binding) => {
    if (binding.value !== binding.oldValue) {
      const subject = binding.value
      el.innerHTML = transformSubject(subject)
    }
  },
})
