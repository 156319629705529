// Core
import { Component, Vue, Watch } from 'vue-property-decorator'

// Store
import MasterProgressAchievementsModule from '@/store/modules/master/progress_achievements'
// Components
import ProgressNewAchievementPopup from '@/components/modals/progress/ProgressNewAchievementPopup.vue'
// Types
import { AchievementResource } from '@/store/types/schema.progress'

@Component({
  components: {
    ProgressNewAchievementPopup,
  },
})
export default class MasterProgressToastMixin extends Vue {
  private options = {
    closeButton: false,
    closeOnClick: false,
    draggable: true,
    icon: false,
    maxToasts: 2,
    timeout: 0,
    transitionDuration: 750,
  }

  protected toastQuantity = 0

  private get achievementsToastsBuffer () {
    return MasterProgressAchievementsModule.toastsBuffer
  }

  protected get isShowCloseAllProgressToast () {
    return this.toastQuantity > this.toastAmountLimit || this.toastQuantity > 2
  }

  private get toastAmountLimit() {
    if (this.$vuetify.breakpoint.height > 767 && this.$vuetify.breakpoint.width > 600) {
      return 2
    } else {
      return 1
    }
  }

  private resizeCheck () {
    /* eslint-disable-next-line */
    /* @ts-ignore:next-line */
    this.options.draggable = Boolean(window.matchMedia('(pointer: coarse)').matches) || (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
    this.options.maxToasts = this.toastAmountLimit

    /* eslint-disable-next-line */
    /* @ts-ignore:next-line */
    this.$toast.updateDefaults(this.options)
  }

  // Показанный тост добавляет слаг ачивки в локал сторейдж
  protected toastNewAchievement (
    achievementInfo: AchievementResource,
    handleOpen: (item: AchievementResource) => void,
  ) {
    if (this.toastQuantity === 0) {
      this.$toast.updateDefaults({
        container: document.getElementById('toasts-container--progress') ?? document.body,
      })
    }

    const content = {
      component: ProgressNewAchievementPopup,
      listeners: {
        'button-open': (achievement: AchievementResource) => {
          handleOpen(achievement)
        },
        'close-toast': () => { this.toastQuantity-- },
      },
      props: {
        achievementInfo,
        theme: this.$vuetify.theme.dark ? 'theme--dark' : 'theme--light',
      },
    }

    this.resizeCheck()

    /* eslint-disable-next-line */
    /* @ts-ignore:next-line */
    this.$toast(content, this.options)
    this.toastQuantity++

    const viewedAchievementsSlugs = JSON.parse(localStorage.getItem('viewed_achievements_slugs') ?? '[]')
    viewedAchievementsSlugs.push(achievementInfo.slug)
    localStorage.setItem('viewed_achievements_slugs', JSON.stringify(viewedAchievementsSlugs))
  }

  protected closeAllProgressToasts() {
    this.$toast.clear()
    this.toastQuantity = 0
  }

  protected showBufferedToasts (handleOpen: (item: AchievementResource) => void) {
    if (this.achievementsToastsBuffer.length) {
      const viewedAchievementsSlugs = JSON.parse(localStorage.getItem('viewed_achievements_slugs') ?? '[]')

      if (viewedAchievementsSlugs?.length) {
        MasterProgressAchievementsModule.removeItemsFromToastsBuffer(viewedAchievementsSlugs)
      }

      this.achievementsToastsBuffer.forEach(item => {
        this.toastNewAchievement(item, handleOpen)
        viewedAchievementsSlugs.push(item.slug)
      })

      localStorage.setItem('viewed_achievements_slugs', JSON.stringify(viewedAchievementsSlugs))
      MasterProgressAchievementsModule.clearToastsBuffer()
    }
  }

  @Watch('toastQuantity')
  private watchToastQuantity () {
    if (this.toastQuantity < 0) this.toastQuantity = 0
  }
}