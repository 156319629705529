



































import { Component, Vue } from 'vue-property-decorator'

import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'

/**
 * Модалка подтверждения действия
 *
 * Пример использования:
 *
 * В шаблоне:
 * <Confirmation ref="confirm"></Confirmation>
 *
 * В компоненте:
 * @Ref() confirm!: Confirmation
 *
 * private confirmDelete () {
 *   this.confirm.open(
 *     'Удаление сущности',
 *     'Вы уверены, что хотите удалить сущность?',
 *     {
 *       buttonConfirmText: 'Удалить',
 *     },
 *   )
 *     .then(this.handleDelete)
 *     .catch(() => (this.someAction)
 * }
 */

interface ConfirmOptions {
  buttonCancelText?: string,
  buttonCancelVisible?: boolean,
  buttonConfirmText?: string,
  buttonConfirmVisible?: boolean,
  buttonReverse?: boolean,
  hideDefaultClose?: boolean,
  isBuyCourse?: boolean,
  messageNoMargin?: boolean,
  persistent?: boolean,
  promptPlaceholder?: string,
  skin?: 'primary' | 'secondary' | 'teal',
  width?: number,
  withPrompt?: boolean,
}

@Component({
  components: {
    ModalWrapper,
    TextInput,
  },
})
export default class Confirmation extends Vue {
  private visible = false
  private resolve: any = null
  private reject: any = null
  private message = ''
  private title = ''
  private prompt = ''

  private defaultOptions: ConfirmOptions = {
    buttonCancelText: 'Вернуться',
    buttonCancelVisible: true,
    buttonConfirmText: 'Сохранить',
    buttonConfirmVisible: true,
    buttonReverse: false,
    hideDefaultClose: false,
    messageNoMargin: false,
    persistent: false,
    promptPlaceholder: '',
    skin: 'primary',
    width: 600,
    withPrompt: false,
  }
  private options: ConfirmOptions = {
    buttonCancelText: 'Вернуться',
    buttonCancelVisible: true,
    buttonConfirmText: 'Сохранить',
    buttonConfirmVisible: true,
    buttonReverse: false,
    hideDefaultClose: false,
    isBuyCourse: false,
    messageNoMargin: false,
    persistent: false,
    promptPlaceholder: '',
    skin: 'primary',
    width: 600,
    withPrompt: false,
  }
  private isEmptyPrompt = false

  public open (title: string, message: string, options?: ConfirmOptions): any {
    this.visible = true
    this.title = title
    this.message = message
    this.options = { ...this.defaultOptions, ...options }

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  private handleConfirm () {
    if (this.options.withPrompt && !this.prompt) {
      this.isEmptyPrompt = true
    } else {
      this.resolve(this.options.withPrompt ? this.prompt : true)
      this.visible = false
    }
  }

  private handleCancel () {
    this.reject('Отклонено')
    this.visible = false
  }
}
