import Vue from 'vue'

Vue.directive('declension', {
  bind: (el: HTMLElement, binding) => {
    let { count } = binding.value
    const num = count
    const { list } = binding.value
    const word = list[(count %= 100, count < 20 && count > 4) ? 2 : [2, 0, 1, 1, 1, 2][(count %= 10, count < 5) ? count : 5]]
    el.innerHTML = `${num} ${word}`
  },
  update: (el: HTMLElement, binding) => {
    if (binding.value !== binding.oldValue) {
      let { count } = binding.value
      const num = count
      const { list } = binding.value
      const word = list[(count %= 100, count < 20 && count > 4) ? 2 : [2, 0, 1, 1, 1, 2][(count %= 10, count < 5) ? count : 5]]
      el.innerHTML = `${num} ${word}`
    }
  },
})
