











import { Component, Prop, Vue } from 'vue-property-decorator'

import { MediaResource } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class Attachment extends Vue {
  @Prop({ required: true })
  private file!: MediaResource

  @Prop({ default: false })
  private preview!: boolean

  private get isImage(): boolean {
    if (this.file && this.file.filename) {
      const ext = this.file.filename.split('.').pop()
      if (ext)
        return ['png', 'jpg', 'jpeg', 'webp', 'svg'].includes(ext)
      return false
    }
    return false
  }
}
