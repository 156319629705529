import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/redirect/notification/GoToMasterExercise.vue'),
    name: 'redirect.notification.mg.course.exercise',
    path: '/r/notification/mg/course/:courseID/exercise/:exerciseUUID',
  },
  {
    component: () => import('@/views/redirect/notification/GoToMasterExerciseItem.vue'),
    name: 'redirect.notification.mg.course.exercise.item',
    path: '/r/notification/mg/:groupID/exercise/:exerciseUUID',
  },
  {
    component: () => import('@/views/redirect/notification/GoToManagerExerciseItem.vue'),
    name: 'redirect.notification.manager.mg.course.exercise.item',
    path: '/r/notification/manager/mg/:groupID/exercise/:exerciseUUID/task/:taskUUID/master/:masterID',
  },
  {
    component: () => import('@/views/redirect/notification/GoToMasterLesson.vue'),
    name: 'redirect.notification.mg.course.lesson',
    path: '/r/notification/mg/course/:courseID/lesson/:lessonID',
  },
  {
    component: () => import('@/views/redirect/payment/GoToPayment.vue'),
    name: 'redirect.payment',
    path: '/r/payment/:orderUUID',
  },
  {
    component: () => import('@/views/redirect/VKAuth.vue'),
    name: 'redirect.vk.auth',
    path: '/r/vk/auth',
  },
  {
    component: () => import('@/views/redirect/VKAuth.vue'),
    name: 'redirect.telegram.auth',
    path: '/r/telegram/auth',
  },
  {
    component: () => import('@/views/redirect/News.vue'),
    meta: { middleware: [auth] },
    name: 'redirect.news',
    path: '/r/news/:newsID',
  },
]
