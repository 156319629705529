












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ButtonIconAction extends Vue {
  @Prop({ default: 'accent' })
  readonly color!: string

  @Prop({ default: '$plus' })
  readonly icon!: string

  @Prop({ default: true })
  readonly small!: string

  private innerColor = this.color

  @Watch('color')
  private watchColor() {
    this.innerColor = this.color
  }
}
