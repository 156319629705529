import { Component } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MasterEducationModule from '@/store/modules/master/education'
import MentorEducationModule from '@/store/modules/mentor/education'
import SystemModule from '@/store/modules/system'
import { EducationMasterGroupResource, GroupType, CourseType, ManagerMasterGroupsSelectGetParams, NameValueResource } from '@/store/types'
import { formatDate, getCurrentMonth } from '@/utils/functions'
import MasterDashboardModule from '@/store/modules/master/dashboard'

@Component
export default class MasterGroupMixin extends NotifyMixin {
  protected get groupID () {
    return +this.$route.params.groupID
  }

  // ToDo в будущем лучше чтобы был private, а деление было по правам
  protected get isMaster () {
    return SystemModule.interface === 'master'
  }

  protected get educationStatsBlock() {
    return MasterDashboardModule.dashboard?.educationStatsBlock
  }

  protected get masterGroups () {
    return this.isMaster
      ? MasterEducationModule.masterGroups.map(group => ({ name: group.title, value: group.id }))
      : MentorEducationModule.masterGroups.map(group => ({ name: group.title, value: group.id }))
  }

  protected get masterGroupsFull () {
    return MasterEducationModule.masterGroups.filter(group => group.dashboardShow)
  }

  protected get currentMasterGroupIsPlus () {
    return this.currentMasterGroup ? this.currentMasterGroup.groupType.value === GroupType.PLUS : false
  }

  protected get currentMasterGroupCourseIsSpecial () {
    return this.currentMasterGroup ? this.currentMasterGroup.course.type.value === CourseType.SPECIAL : false
  }

  protected get currentMasterGroupIsLite () {
    return this.currentMasterGroup ? this.currentMasterGroup.groupType.value === GroupType.LITE : false
  }

  protected get currentMasterGroup () {
    return this.isMaster
      ? MasterEducationModule.currentMasterGroup as EducationMasterGroupResource
      : MentorEducationModule.currentMasterGroup as EducationMasterGroupResource
  }

  // MY_STEP: ~ Сохранённый месяц из стора
  protected get savedMasterGroupMonthID () {
    return this.isMaster
      ? MasterEducationModule.savedMasterGroupMonthID
      : MentorEducationModule.savedMasterGroupMonthID
  }

  // MY_STEP: 1 - функция выдаёт месяц
  protected get currentMasterGroupMonth (): NameValueResource | null {
    return getCurrentMonth(this.currentMasterGroupMonths)
  }

  // MY_STEP: 2 - функция выдаёт айди месяца
  protected get currentMasterGroupMonthID (): string | number | undefined {
    if (this.savedMasterGroupMonthID) {
      const foundMonth = this.currentMasterGroupMonths.map(item => item.value).includes(this.savedMasterGroupMonthID)

      if (foundMonth) return this.savedMasterGroupMonthID
    }

    return this.currentMasterGroupMonth?.value
  }

  protected get currentMasterGroupMonths () {
    return this.currentMasterGroup ? this.currentMasterGroup.months : []
  }

  protected get currentMasterGroupDates() {
    return this.currentMasterGroup ? [this.currentMasterGroup.dates.start, this.currentMasterGroup.dates.end, this.currentMasterGroup.dates.open] : []
  }

  protected get currentMasterGroupID () {
    return this.isMaster
      ? MasterEducationModule.currentMasterGroupID
      : MentorEducationModule.currentMasterGroupID
  }

  protected set currentMasterGroupID (id: number) {
    if (this.isMaster) MasterEducationModule.setCurrentMasterGroupID(id)
    else MentorEducationModule.setCurrentMasterGroupID(id)
  }

  protected get months () {
    return this.currentMasterGroup ? this.currentMasterGroupMonths.map(item => ({
      name: formatDate(item.name, 'LLLL yyyy'),
      value: item.value,
    })) : []
  }

  protected async syncMasterGroup(name = '') {
    if (this.groupID && this.groupID !== this.currentMasterGroupID) {
      this.currentMasterGroupID = this.groupID
    }

    if (!this.currentMasterGroup) {
      if (this.isMaster) {
        await MasterEducationModule.fetchMasterGroupsAll({ all: true })
          .then((response: EducationMasterGroupResource[]) => {
            // Мастер может перейти в дз, мастер-группы которого может не быть в списке мастер-групп,
            // потому что курс скрыт. Тогда нужно запросить все мастер-группы, найти нужную и добавить в список
            // а когда мастер покинет страницу дз, удалить её. См. компонент Item.vue, хук: destroyed
            const group = response.find(group => group.id === this.currentMasterGroupID)
            if (group) {
              MasterEducationModule.addMasterGroup(group)
            }
            return Promise.resolve()
          })
          .catch((error: any) => {
            this.notifyError(error)
            return Promise.reject()
          })
      } else {
        await MentorEducationModule.fetchMasterGroupById(this.groupID)
          .then((response) => {
            MentorEducationModule.setMasterGroups([response])
            return Promise.resolve()
          })
          .catch((error: any) => {
            this.notifyError(error)
            if (name)
              this.$router.replace({ name })
                .then(() => {
                  this.$bus.$emit('fetchMasterGroups')
                })
            return Promise.reject()
          })
      }
    }
  }

  protected fetchMasterGroupsSelect (payload?: ManagerMasterGroupsSelectGetParams): Promise<EducationMasterGroupResource[] | null> {
    return DictionaryModule.searchMasterGroups(payload)
  }
}
