






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'

@Component({
  components: {
    Dialog,
    ModalWrapper,
  },
})
export default class MainTourCompleteModal extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  private localVisible = this.visible

  private handleClose () {
    this.localVisible = false
    this.$emit('closeModal')
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
