import Vue from 'vue'

Vue.directive('price', {
  bind: (el: HTMLElement, binding) => {
    el.innerHTML = binding.value
      ? `${binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')}&nbsp;P`
      : `${binding.value}&nbsp;P`
  },
  update: (el: HTMLElement, binding) => {
    if (binding.value !== binding.oldValue) {
      el.innerHTML = binding.value
        ? `${binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')}&nbsp;P`
        : `${binding.value}&nbsp;P`
    }
  },
})
