import AuthModule from '@/store/modules/auth'
import { hasPermission } from '@/utils/functions'
import { Permission } from '@/store/types'
import SystemModule from '@/store/modules/system'

// Права на управления разделом "Пользователи"
export function manageUsers ({ next, nextMiddleware }: any) {
  if (![
    Permission.USERS_MANAGER_LIST,
    Permission.USERS_MANAGER_SEARCH,
    Permission.USERS_MASTER_LIST,
    Permission.USERS_MASTER_SEARCH,
    Permission.USERS_VIEW,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка сотрудников
export function viewEmployees ({ next, nextMiddleware }: any) {
  if (![
    Permission.USERS_MANAGER_LIST,
    Permission.USERS_MANAGER_SEARCH,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр списка мастеров
export function viewMasters ({ next, nextMiddleware }: any) {
  if (![
    Permission.USERS_MASTER_LIST,
    Permission.USERS_MASTER_SEARCH,
  ].some((rule: Permission) => hasPermission(rule, AuthModule.self?.permissions))) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на просмотр страницы мастера или сотрудника
export function viewUser ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.USERS_VIEW, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}

// Права на управление разделом "Группы прав пользователей"
export function manageRoles ({ next, nextMiddleware }: any) {
  if (!hasPermission(Permission.ROLES, AuthModule.self?.permissions)) {
    SystemModule.setSnackbarText('У вас недостаточно прав')
    return next({
      name: 'profile',
    })
  }
  return nextMiddleware()
}
