import auth from '@/router/_middleware/auth'
import isManager from '@/router/_middleware/isManager'
import { checkExercise } from '@/router/_middleware/manageEducation'

export default [
  {
    component: () => import('@/views/manager/education/groups/List.vue'),
    meta: { middleware: [auth, isManager, checkExercise] },
    name: 'manager.education.groups',
    path: '/manager/education/groups',
  },
]
