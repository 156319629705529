import auth from '@/router/_middleware/auth'
import { manageCommerce, manageCoursesLinks } from '@/router/_middleware/manageCommerce'

import ordersRoutes from './commerce/orders'
import promoRoutes from './commerce/promo'
import discount from './commerce/discount'

export default [
  {
    children: [
      ...ordersRoutes,
      ...promoRoutes,
      ...discount,
      {
        component: () => import('@/views/manager/commerce/Links.vue'),
        meta: { middleware: [auth, manageCoursesLinks] },
        name: 'manager.commerce.links',
        path: '/manager/commerce/courses-links',
      },
    ],
    component: () => import('@/views/manager/Commerce.vue'),
    meta: { middleware: [auth, manageCommerce] },
    path: '/manager/commerce',
  },
]
