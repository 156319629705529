






























































import { Component, Mixins, Prop } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
// stores
import DictionaryModule from '@/store/modules/dictionary'
import ProfileModule from '@/store/modules/profile'

// Types
interface INavItem {
  hasBadge?: boolean,
  children?: INavChildren[],
  icon: string,
  isVisible: boolean,
  path?: string,
  title: string,
}

interface INavChildren {
  isVisible: boolean,
  path: string,
  title: string,
}

@Component
export default class SidenavManager extends Mixins(MasterGroupMixin, PermissionsMixin, DetectSafariMixin) {
  @Prop({ default: false })
  private isHidden!: boolean

  @Prop({ default: false })
  private isMobile!: boolean

  private visible = false

  // private navContent: INavItem[] = []
  private get navContent () :INavItem[] {
    return [
      {
        icon: require('/src/assets/images/icons/sidebar/education.svg'),
        isVisible: this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_CHECK, this.Permission.EDUCATION_SPECIAL_CHECK, this.Permission.MENTOR_LIST_VIEW]),
        path: '/manager/dashboard',
        title: 'Обучение',
      },{
        icon: require('/src/assets/images/icons/sidebar/calendar.svg'),
        isVisible: this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_CHECK, this.Permission.EDUCATION_SPECIAL_CHECK, this.Permission.MENTOR_LIST_VIEW]),
        path: '/manager/calendar',
        title: 'Календарь',
      },{
        children: [
          {
            isVisible: this.hasSomePermissions([this.Permission.USERS_MASTER_LIST, this.Permission.USERS_MASTER_SEARCH]),
            path: '/manager/users/masters',
            title: 'Мастера',
          },{
            isVisible: this.hasSomePermissions([this.Permission.USERS_MANAGER_LIST, this.Permission.USERS_MANAGER_SEARCH]),
            path: '/manager/users/employees',
            title: 'Сотрудники',
          },{
            isVisible: this.hasPermission(this.Permission.ROLES),
            path: '/manager/users/roles',
            title: 'Права доступа',
          },
        ],
        icon: require('/src/assets/images/icons/sidebar/users.svg'),
        isVisible: this.hasSomePermissions([this.Permission.USERS_MASTER_LIST, this.Permission.USERS_MASTER_SEARCH, this.Permission.USERS_MANAGER_LIST, this.Permission.USERS_MANAGER_SEARCH]),
        title: 'Пользователи и права',
      },{
        children: [
          {
            isVisible: this.hasSomePermissions([this.Permission.ORDERS_CREATE, this.Permission.ORDERS_VIEW]),
            path: '/manager/commerce/orders',
            title: 'Заказы',
          },{
            isVisible: this.hasPermission(this.Permission.ORDERS_PROMO_CREATE),
            path: '/manager/commerce/promocodes',
            title: 'Промоакции',
          },{
            isVisible: this.hasPermission(this.Permission.ORDERS_LINK_LIST),
            path: '/manager/commerce/courses-links',
            title: 'Ссылки на покупку курса',
          },{
            isVisible: this.hasPermission(this.Permission.ORDERS_SALES_CREATE),
            path: '/manager/commerce/discounts',
            title: 'Скидки',
          },
        ],
        icon: require('/src/assets/images/icons/sidebar/orders.svg'),
        isVisible: this.hasSomePermissions([this.Permission.ORDERS_CREATE, this.Permission.ORDERS_VIEW, this.Permission.ORDERS_PROMO_CREATE, this.Permission.ORDERS_LINK_LIST]),
        title: 'Покупки и оплаты',
      },{
        children: [
          {
            isVisible: this.hasPermission(this.Permission.MG_VIEW_ALL),
            path: '/manager/control/groups',
            title: 'Группы мастеров',
          },{
            isVisible: this.hasPermission(this.Permission.COURSE_VIEW),
            path: '/manager/control/courses',
            title: 'Курсы',
          },
        ],
        icon: require('/src/assets/images/icons/sidebar/courses.svg'),
        isVisible: this.hasSomePermissions([this.Permission.COURSE_VIEW, this.Permission.MG_VIEW_ALL]),
        title: 'Управление курсами',
      },{
        children: [
          {
            isVisible: this.hasSomePermissions([this.Permission.BANK_PERSONAL_VIEW, this.Permission.BANK_BASE_VIEW]),
            path: '/manager/bank/exercises',
            title: 'Банк домашних заданий',
          },{
            isVisible: this.hasSomePermissions([this.Permission.DEPOSITORY_MANUAL_VIEW, this.Permission.DEPOSITORY_MANUAL_EDIT, this.Permission.DEPOSITORY_MATERIALS_VIEW, this.Permission.DEPOSITORY_MATERIALS_EDIT]),
            path: '/manager/bank/depository',
            title: 'База знаний',
          },
        ],
        icon: require('/src/assets/images/icons/sidebar/depository.svg'),
        isVisible: this.hasSomePermissions([this.Permission.BANK_PERSONAL_VIEW, this.Permission.BANK_BASE_VIEW, this.Permission.DEPOSITORY_MANUAL_VIEW, this.Permission.DEPOSITORY_MANUAL_EDIT, this.Permission.DEPOSITORY_MATERIALS_VIEW, this.Permission.DEPOSITORY_MATERIALS_EDIT]),
        title: 'Управление банками',
      },{
        children: [
          {
            isVisible: this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_VIEW, this.Permission.EDUCATION_EXERCISES_CHECK, this.Permission.EDUCATION_SPECIAL_CHECK]),
            path: '/manager/education/exercises',
            title: 'Домашняя работа',
          },{
            isVisible: this.hasPermission(this.Permission.EDUCATION_LESSON_LIST),
            path: '/manager/education/lessons',
            title: 'Уроки',
          },{
            isVisible: this.hasPermission(this.Permission.MASTER_VIEW_ALL) || this.hasPermission(this.Permission.MASTER_VIEW),
            path: '/manager/education/masters',
            title: 'Мастера',
          },{
            isVisible: this.hasSomePermissions([this.Permission.MENTOR_LIST_ALL, this.Permission.MENTOR_LIST_VIEW]),
            path: '/manager/education/mentors',
            title: 'Наставники',
          },{
            isVisible: this.hasPermission(this.Permission.EDUCATION_EXERCISES_CHECK),
            path: '/manager/education/groups',
            title: 'Группы',
          },
        ],
        icon: require('/src/assets/images/icons/sidebar/process.svg'),
        isVisible: Boolean(this.masterGroups.length && this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_VIEW, this.Permission.EDUCATION_EXERCISES_CHECK, this.Permission.EDUCATION_SPECIAL_CHECK, this.Permission.EDUCATION_LESSON_LIST, this.Permission.MASTER_VIEW_ALL, this.Permission.MENTOR_LIST_ALL, this.Permission.MENTOR_LIST_VIEW])),
        title: 'Учебный процесс',
      },{
        // badge: {
        //   isVisible: this.messageCounter && this.hasPermission(this.Permission.SUPPORT_ANSWER),
        //   messageCounter: this.messageCounter,
        // },
        hasBadge: true,
        icon: require('/src/assets/images/icons/sidebar/support.svg'),
        isVisible: true,
        path: '/manager/support',
        title: 'Техподдержка',
      },
    ]
  }

  private get messageCounter () {
    return DictionaryModule.messageCounter
  }

  private get vueScrollOptions () {
    return {
      bar: {
        disable: this.$vuetify.breakpoint.width < 1024,
      },
      scrollPanel: {
        scrollingX: false,
      },
    }
  }

  private get hasAllSocials() {
    if(ProfileModule.information?.socials?.length) {
      return ProfileModule.information?.socials?.length >= ProfileModule.requiredSocials.length
    }

    return false
  }

  private mounted () {
    this.visible = !this.isHidden
    this.$bus.$on('sidenav-toggle', this.handleToggle)
  }

  private handleToggle (value: boolean) {
    this.visible = value
  }

  private handleChangeVisible (value: boolean) {
    this.$bus.$emit('sidenav-toggle', value)
  }
}
