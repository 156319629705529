import auth from '@/router/_middleware/auth'
import isManager from '@/router/_middleware/isManager'

import exercisesRoutes from './education/exercises'
import lessonsRoutes from './education/lessons'
import mastersRoutes from './education/masters'
import mentorsRoutes from './education/mentors'
import groupsRoutes from './education/groups'

export default [
  {
    children: [
      ...exercisesRoutes,
      ...lessonsRoutes,
      ...mastersRoutes,
      ...mentorsRoutes,
      ...groupsRoutes,
    ],
    component: () => import('@/views/manager/Education.vue'),
    meta: { middleware: [auth, isManager] },
    path: '/manager/education',
  },
]
