import auth from '@/router/_middleware/auth'
import manageSocials from '@/router/_middleware/manageSocials'

export default [
  {
    component: () => import('@/views/master/lessons/Item.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.lessons.item',
    path: '/master/lessons/:groupID/:lessonID',
  },
  {
    component: () => import('@/views/master/lessons/List.vue'),
    meta: { middleware: [auth, manageSocials] },
    name: 'master.lessons',
    path: '/master/lessons',
  },
]
