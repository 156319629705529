import { render, staticRenderFns } from "./MainTourModal.vue?vue&type=template&id=5592ab2d&scoped=true&lang=pug&"
import script from "./MainTourModal.vue?vue&type=script&lang=ts&"
export * from "./MainTourModal.vue?vue&type=script&lang=ts&"
import style0 from "./MainTourModal.vue?vue&type=style&index=0&id=5592ab2d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5592ab2d",
  null
  
)

export default component.exports