










































import { Component, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component({
  inheritAttrs: false,
})
export default class TextInput extends DetectSafariMixin {
  @Prop({ default: '' })
  private value!: string

  @Prop({ default: true })
  private outlined!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: '' })
  private messages!: string

  @Prop({ default: '' })
  private tooltip!: string

  @Prop({ default: 'right' })
  private tooltipPosition!: 'left' | 'right'

  private innerValue = ''

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('input', this.innerValue)
  }

  private handleChange(value: string) {
    this.innerValue = value
    this.$emit('change', this.innerValue)
  }

  private paste(e: ClipboardEvent) {
    const pasteText = e.clipboardData?.getData('text')
    this.$emit('paste', pasteText)
  }

  private handleEnter () {
    this.$emit('update:value', this.innerValue) // Только для поля поиска в фильтре. В handleInput добавлять нельзя!
    this.$emit('enter', this.innerValue)
  }

  private handleClear () {
    this.innerValue = ''
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
    this.$emit('clear')
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
