import auth from '@/router/_middleware/auth'

export default [
  {
    children: [
      {
        component: () => import('@/views/profile/Information.vue'),
        meta: { middleware: [auth] },
        name: 'profile',
        path: '',
      },
      {
        component: () => import('@/views/profile/Notifications.vue'),
        meta: { middleware: [auth] },
        name: 'profile.notifications',
        path: '/profile/notifications',
      },
      {
        component: () => import('@/views/profile/Courses.vue'),
        meta: { middleware: [auth] },
        name: 'profile.courses',
        path: '/profile/courses',
      },
      {
        component: () => import('@/views/profile/CourseItem.vue'),
        meta: { middleware: [auth] },
        name: 'profile.courses.item',
        path: '/profile/courses/:courseID',
      },
      {
        component: () => import('@/views/profile/Payments.vue'),
        meta: { middleware: [auth] },
        name: 'profile.payments',
        path: '/profile/payments',
      },
    ],
    component: () => import('@/views/Profile.vue'),
    meta: { middleware: [auth] },
    path: '/profile',
  },
]
