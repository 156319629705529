






import { Component } from 'vue-property-decorator'

// components
import NoteEditForm from '@/components/notes/NoteEditForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { CreateNoteRequest, NoteColors } from '@/store/types'
import MasterNotesModule from '@/store/modules/master/notes'

@Component({
  components: {
    NoteEditForm,
  },
})
export default class NoteCreate extends NotifyMixin {
  private form: CreateNoteRequest = {
    color: NoteColors._56C0AD,
    content: '',
    favorite: false,
    title: '',
  }

  private createNote() {
    MasterNotesModule.createNotes(this.form)
      .then(() => {
        this.$emit('noteCreated')
      })
      .catch(this.notifyError)
  }
}
