






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class FireworksIcon extends Vue {
  @Prop({
    default: 30,
    validator (value: number): boolean {
      return [30, 20].includes(value)
    },
  })
  private size!: number

  @Prop({
    default: 'orange-accent-2',
    validator (value: string): boolean {
      return !!value.match(/(pink-base|green-base|purple-base|orange-accent-2)/)
    },
  })
  private color!: string

  @Prop({ default: 300 })
  private delay!: number
}
