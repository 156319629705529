import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'

// Проверка является ли пользователь НЕ авторизованным в системе
export default async function guest ({ next, nextMiddleware }: any) {
  if (AuthModule.self) {
    return next({ name: SystemModule.interface === 'master' ? 'master' : 'manager.dashboard' })
  }
  return nextMiddleware()
}
