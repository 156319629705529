import auth from '@/router/_middleware/auth'

export default [
  {
    component: () => import('@/views/support/New.vue'),
    meta: { middleware: [auth] },
    name: 'master.support.new',
    path: '/master/support/new',
  },
  {
    children: [
      {
        component: () => import('@/views/master/support/FAQList.vue'),
        meta: { middleware: [auth] },
        name: 'master.support.faq',
        path: '/master/support/faq',
      },
      {
        component: () => import('@/views/support/List.vue'),
        meta: { middleware: [auth] },
        name: 'master.support.tickets',
        path: '/master/support/tickets',
      },
    ],
    component: () => import('@/views/master/support/Support.vue'),
    meta: { middleware: [auth] },
    name: 'master.support',
    path: '/master/support',
  },
  {
    component: () => import('@/views/support/Item.vue'),
    meta: { middleware: [auth] },
    name: 'master.support.item',
    path: '/master/support/:ticketID',
  },
]
