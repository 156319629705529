import { render, staticRenderFns } from "./NoteItem.vue?vue&type=template&id=85e45554&scoped=true&lang=pug&"
import script from "./NoteItem.vue?vue&type=script&lang=ts&"
export * from "./NoteItem.vue?vue&type=script&lang=ts&"
import style0 from "./NoteItem.vue?vue&type=style&index=0&id=85e45554&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e45554",
  null
  
)

export default component.exports