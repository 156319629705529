import auth from '@/router/_middleware/auth'
import { editNews, viewNews } from '@/router/_middleware/manageControl'

export default [
  {
    component: () => import('@/views/manager/control/news/Edit.vue'),
    meta: { middleware: [auth, editNews] },
    name: 'manager.control.news.edit',
    path: '/manager/control/news/edit/:newsID',
  },
  {
    component: () => import('@/views/manager/control/news/New.vue'),
    meta: { middleware: [auth, editNews] },
    name: 'manager.control.news.new',
    path: '/manager/control/news/new',
  },
  {
    component: () => import('@/views/manager/control/news/List.vue'),
    meta: { middleware: [auth, viewNews] },
    name: 'manager.control.news',
    path: '/manager/control/news',
  },
]
