











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Heart extends Vue {
  @Prop({ default: 24 })
  private width!: number

  @Prop({ default: true })
  private filled!: boolean
}
