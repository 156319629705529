export default [
  {
    component: () => import('@/views/payment/Success.vue'),
    name: 'master.payment.success',
    path: '/payment/success',
  },
  {
    component: () => import('@/views/payment/Failed.vue'),
    name: 'master.payment.failed',
    path: '/payment/failed',
  },
  {
    component: () => import('@/views/Order.vue'),
    name: 'master.payment.order',
    path: '/payment/:orderUUID',
  },
]
