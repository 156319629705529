




// Для ускорения загрузки Rive анимаций
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Rive } from '@rive-app/canvas'
// import { Rive, RuntimeLoader } from '@rive-app/canvas'
// import riveWASMResource from '@rive-app/canvas/rive.wasm'

@Component
export default class Achievement extends Vue {
  @Prop({ required: true })
  private name!: string

  @Prop({ default: 160 })
  private width!: number

  @Prop({ default: 160 })
  private height!: number

  @Prop({ default: true })
  private isCanPlay!: boolean

  @Prop({ default: false })
  private isAutoplay!: boolean

  private achievement: null | Rive = null

  private get src(): string {
    return process.env.VUE_APP_SND_ACHIEVEMENTS_BASE_URL + this.name + '/riv.riv'
      ?? 'https://cdn.rive.app/animations/vehicles.riv'
  }

  private mounted () {
    // console.log(RuntimeLoader, riveWASMResource)
    // RuntimeLoader.setWasmUrl(riveWASMResource)

    this.achievement = new Rive({
      autoplay: this.isAutoplay,
      canvas: this.$refs.canvas as HTMLCanvasElement,
      onLoad: () => {
        this.$emit('loaded')
      },
      onLoop: () => {
        if (!this.isCanPlay) this.achievement?.pause()
      },
      src: this.src,
    })
  }

  public play () {
    this.achievement?.play()
  }
}
