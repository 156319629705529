import Vue from 'vue'
import vuescroll from 'vuescroll'

Vue.use(vuescroll, {
  mode: 'slide',
  name: 'Vuescroll',
  ops: {
    bar: {
      background: '#6B9EFF',
      size: '15px',
    },
  },
  scrollPanelX: false,
})
