






















import { orderBy, uniqBy } from 'lodash'
import { Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// components
import NoDataFound from '@/components/NoDataFound.vue'
import NoteCard from '@/components/notes/NoteCard.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterNotesModule from '@/store/modules/master/notes'
import SystemModule from '@/store/modules/system'
import { MasterNotesGetParams } from '@/store/types'

@Component({
  components: {
    NoDataFound,
    NoteCard,
    TextInput,
  },
})
export default class NotesList extends Mixins(NotifyMixin) {
  private filter: MasterNotesGetParams = {
    page: 1,
    search: '',
  }

  private lastSearch = ''

  private get notesVisibility() {
    return SystemModule.notesVisibility
  }

  private get pagination() {
    return MasterNotesModule.notes.meta
  }

  private get notes() {
    return orderBy(uniqBy(MasterNotesModule.notes.data, 'id'), ['favorite', 'createdAt'], ['desc', 'desc'])
  }

  private mounted() {
    this.$bus.$on('deleteNote', this.handleDeleteNote)
  }

  private destroyed() {
    this.$bus.$off('deleteNote', this.handleDeleteNote as any)
  }

  private handleDeleteNote() {
    if (this.pagination.total > 10 && this.pagination.total - 1 !== this.notes.length) {
      this.fetchNotes({ page: 10 * this.pagination.currentPage, perPage: 1 })
    }
  }

  private fetchNotes(filter = this.filter, search = false) {
    MasterNotesModule.fetchNotes({ filter, search })
      .catch()
  }

  private handleScroll(vertical: any) {
    if (vertical.process > 0.7 && this.pagination.lastPage > this.pagination.currentPage) {
      this.filter.page++
      this.fetchNotes()
    }
  }

  private toCard(id: number) {
    this.$emit('toCard', id)
  }

  @Watch('notesVisibility')
  private watchNotesVisibility(value: boolean) {
    if (value && !this.notes.length) {
      this.fetchNotes()
    }
  }

  @Debounce(500)
  @Watch('filter.search')
  private watchSearch(value: string | null) {
    if (value !== this.lastSearch) {
      this.filter.page = 1
      this.fetchNotes(undefined, true)
      this.lastSearch = value || ''
    }
  }
}
