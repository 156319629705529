








































import { camelCase, compact } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import shave from 'shave'

import Tag from '@/components/_uikit/Tag.vue'
import NotificationModule from '@/store/modules/notification'
import AuthModule from '@/store/modules/auth'
import {
  NotificationResource,
  NotificationPlaceholderType,
  NotificationValuesResource,
} from '@/store/types'

interface IWord {
  tag: 'span' | 'div' | 'link-primary' | 'button',
  text: string,
  type?: string,
  link?: string,
}
type NotificationValuesResourceKeys = keyof Omit<NotificationValuesResource, 'sales'>

@Component({
  components: {
    Tag,
  },
})
export default class NotificationCard extends Vue {
  @Prop({ required: true })
  private notification!: NotificationResource

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private isViewed = false
  private words: IWord[] = []

  private mounted () {
    shave('.notification-card__news__text', 24)
    this.isViewed = this.notification.isViewed

    const descriptionArray = compact(this.notification.message.split('%%'))

    descriptionArray.forEach((word: string) => {
      const match = this.notification.placeholders.find((placeholder: string) => placeholder === word)

      if (match) {
        let link = ''
        let tag: 'span' | 'div' | 'link-primary' | 'button' = 'link-primary'
        let type = ''
        let text = this.notification.values[camelCase(match) as NotificationValuesResourceKeys]?.name || ''

        switch (match) {
        case NotificationPlaceholderType.PAY_MODAL:
          tag = 'button'
          text = 'оплатить'
          type = NotificationPlaceholderType.PAY_MODAL
          break
        case NotificationPlaceholderType.MASTER_EXERCISE:
          link = this.notification.values.masterGroup && this.notification.values.masterGroup.value ? this.notification.values.masterTask && this.notification.values.masterTask.value ? `/r/notification/mg/${this.notification.values.masterGroup.value}/exercise/${this.notification.values.masterExercise?.value}?taskUUID=${this.notification.values.masterTask?.value}` : `/r/notification/mg/${this.notification.values.masterGroup.value}/exercise/${this.notification.values.masterExercise?.value}` : `/r/notification/mg/course/${this.notification.values.course?.value}/exercise/${this.notification.values.masterExercise?.value}`
          break
        case NotificationPlaceholderType.MASTER_LESSON:
          link = `/r/notification/mg/course/${this.notification.values.course?.value}/lesson/${this.notification.values.masterLesson?.value}`
          break
        case NotificationPlaceholderType.MENTOR_EXERCISE:
          link = `/r/notification/manager/mg/${this.notification.values.masterGroup?.value}/exercise/${this.notification.values.mentorExercise?.value}/task/${this.notification.values.mentorTask?.value}/master/${this.notification.values.master?.value}`
          break
        case NotificationPlaceholderType.MENTOR_LESSON:
          link = `/manager/education/lessons/${this.notification.values.masterGroup?.value}/${this.notification.values.mentorLesson?.value}`
          break
        case NotificationPlaceholderType.MENTOR_LIST:
          link = '/manager/education/mentors'
          text = 'Посмотреть список наставников'
          break
        case NotificationPlaceholderType.MENTOR:
          link = `/manager/education/mentors/${this.notification.values.mentor?.value}`
          break
        case NotificationPlaceholderType.MASTER_TICKET:
          link = `/master/support/${this.notification.values.ticket?.value}`
          break
        default:
          tag = 'div'
        }

        this.words.push({
          link,
          tag,
          text,
          type,
        })
      } else {
        this.words.push({
          tag: 'span',
          text: word,
        })
      }
    })
  }

  private handleClick(word: IWord) {
    if (this.notification.kind === 'messages') {
      this.isViewed = true
      NotificationModule.readMessage(this.notification.id)
    }

    if (word.tag === 'button' && word.type === NotificationPlaceholderType.PAY_MODAL) {
      this.$bus.$emit('show-prolongation-modal', this.notification.values.course?.value || 0)
    }
  }

  private handleMouseOver () {
    if (!this.notification.isViewed && !this.isViewed) {
      this.isViewed = true
      NotificationModule.readNotification(this.notification.id)
    }
  }

  private fetchNews() {
    this.$bus.$emit('fetchNewsItem', this.notification.values.news?.id)
  }
}
