











































































import { sortBy, reverse, uniqBy } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import NotificationCard from '@/components/notifications/NotificationCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import NotificationModule from '@/store/modules/notification'
import { NotificationResource } from '@/store/types'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    NotificationCard,
  },
})
export default class NotificationsDrawer extends Mixins(NotifyMixin, DetectSafariMixin) {
  private pageNotification = 1
  private pageMessage = 1

  private get visibility () {
    return NotificationModule.drawerVisibility
  }

  private get notifications (): NotificationResource[] {
    return reverse(sortBy(uniqBy(NotificationModule.notifications.data, 'id'), ['createdAt']))
  }

  private get messages(): NotificationResource[] {
    return reverse(sortBy(uniqBy(NotificationModule.messages.data, 'id'), ['createdAt']))
  }

  private get paginationNotifications() {
    return NotificationModule.notifications.meta
  }

  private get paginationMessages() {
    return NotificationModule.messages.meta
  }

  private get notificationsCount() {
    return AuthModule.self ? AuthModule.self.notificationsCount : 0
  }

  private get messagesCount() {
    return AuthModule.self ? AuthModule.self.messagesCount : 0
  }

  private activeTab = 0

  private redirectToSettings() {
    this.handleCLose()
    this.$router.push({ name: 'profile.notifications' })
      .catch(() => {return})
  }

  @Bind
  @Debounce(300)
  private fetchNotifications () {
    this.pageNotification += 1
    NotificationModule.fetchNotifications(this.pageNotification)
      .catch(this.notifyError)
  }

  private handleScroll (vertical: any) {
    if (vertical.process > 0.7 && this.paginationNotifications.lastPage !== this.paginationNotifications.currentPage) {
      this.fetchNotifications()
    }
  }

  private handleCLose () {
    NotificationModule.hideDrawer()
  }

  private readAllNotifications() {
    AuthModule.setNotificationsCount(0)
    NotificationModule.readAllNotifications()
  }

  private readAllMessages() {
    AuthModule.setMessagesCount(0)
    NotificationModule.readAllMessages()
  }

  private handleInputDrawer(value: boolean) {
    NotificationModule.setDrawerVisibility(value)
  }
}
