






import Cookies from 'js-cookie'
import { Component, Vue } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

/* eslint-disable-next-line */
/* @ts-ignore:next-line */
import * as tsParticles from '@/utils/plugins/tsparticles.engine'
import AuthModule from '@/store/modules/auth'
import { SelfResource } from '@/store/types'


@Component
export default class BirthdayAnimation extends Vue {
  private get self () {
    return AuthModule.self
  }

  private showBirthdayAnimationFirstTime = !Cookies.get(`showBirthdayAnimationFirstTime-${(this.self as unknown as SelfResource).id}`)
  private firecrackerAnimation: any = null
  private confettiAnimation: any = null

  private confettiQuantity = 60
  private confettiColors: string[] = [
    '#6B9EFF',
    '#F25E94',
    '#FFA552',
    '#B495FF',
    '#00E676',
    '#FF49E2',
  ]

  mounted() {
    this.showBirthdayAnimationFirstTime ? this.fireCrackerParticlesInit() : this.confettiParticlesInit()
  }

  private firecrackerOptions: any = {
    fpsLimit: 60,
    fullScreen: {
      zIndex: 3,
    },
    emitters: [
      {
        position: {
          x: 0,
          y: 20,
        },
        particles: {
          move: {
            direction: 'top-right',
            outModes: {
              top: 'none',
              left: 'none',
              default: 'destroy',
            },
          },
        },
        life: {
          count: 1,
          duration: 0.1,
          delay: 2,
        },
        rate: {
          delay: 0.1,
          quantity: this.confettiQuantity,
        },
        size: {
          width: 0,
          height: 0,
        },
      },
      {
        position: {
          x: 100,
          y: 20,
        },
        particles: {
          move: {
            direction: 'top-left',
            outModes: {
              top: 'none',
              right: 'none',
              default: 'destroy',
            },
          },
        },
        life: {
          count: 1,
          duration: 0.1,
          delay: 2,
        },
        rate: {
          delay: 0.1,
          quantity: this.confettiQuantity,
        },
        size: {
          width: 0,
          height: 0,
        },
      },
    ],
    particles: {
      number: {
        value: 0,
      },
      color: {
        value: this.confettiColors,
      },
      shape: {
        type: ['circle', 'square'],
        options: {},
      },
      opacity: {
        value: {
          min: 0.3,
          max: 1,
        },
        animation: {
          enable: true,
          speed: 1,
          startValue: 'max',
          destroy: 'min',
        },
      },
      size: {
        value: {
          min: 4,
          max: 10,
        },
      },
      life: {
        duration: {
          sync: true,
          value: 7,
        },
        count: 1,
      },
      move: {
        enable: true,
        gravity: {
          enable: true,
        },
        drift: {
          min: -2,
          max: 2,
        },
        speed: { min: 10, max: 30 },
        decay: 0.1,
        direction: 'right',
        random: false,
        straight: false,
        outModes: {
          default: 'destroy',
          top: 'none',
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        move: true,
        animation: {
          enable: true,
          speed: 60,
        },
      },
      tilt: {
        direction: 'random',
        enable: true,
        move: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 60,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 25,
        },
        enable: true,
        speed: {
          min: 15,
          max: 25,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        move: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
    },
  }

  private confettiOptions: any = {
    fpsLimit: 60,
    particles: {
      color: {
        value: this.confettiColors,
      },
      move: {
        direction: 'bottom',
        enable: true,
        outModes: {
          default: 'out',
        },
        size: true,
        speed: {
          min: 1,
          max: 3,
        },
      },
      number: {
        value: this.confettiQuantity,
        density: {
          enable: true,
          area: 800,
        },
      },
      opacity: {
        value: 1,
        animation: {
          enable: false,
          startValue: 'max',
          destroy: 'min',
          speed: 0.3,
          sync: true,
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        move: true,
        animation: {
          enable: true,
          speed: 3,
        },
      },
      tilt: {
        direction: 'random',
        enable: true,
        move: true,
        value: {
          min: 0,
          max: 360,
        },
        animation: {
          enable: true,
          speed: 60,
        },
      },
      shape: {
        type: [
          'circle',
          'square',
        ],
        options: {},
      },
      size: {
        value: {
          min: 2,
          max: 10,
        },
      },
      roll: {
        darken: {
          enable: true,
          value: 30,
        },
        enlighten: {
          enable: true,
          value: 30,
        },
        enable: true,
        speed: {
          min: 15,
          max: 25,
        },
      },
      wobble: {
        distance: 30,
        enable: true,
        move: true,
        speed: {
          min: -15,
          max: 15,
        },
      },
    },
  }

  @Bind
  @Debounce(2500)
  private async fireCrackerParticlesInit() {
    await tsParticles.loadFull(tsParticles.tsParticles)

    this.firecrackerAnimation = await tsParticles.tsParticles.load({
      id: 'firecracker-particles',
      options: this.firecrackerOptions,
    })

    this.confettiAnimation = await tsParticles.tsParticles.load({
      id: 'confetti-particles',
      options: this.confettiOptions,
    })
  }

  @Bind
  @Debounce(2500)
  private async confettiParticlesInit() {
    await tsParticles.loadFull(tsParticles.tsParticles)

    this.confettiAnimation = await tsParticles.tsParticles.load({
      id: 'confetti-particles',
      options: this.confettiOptions,
    })
  }
}
