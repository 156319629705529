


















import { Component, Prop, Vue } from 'vue-property-decorator'

import Achievement from '@/components/views/progress/achievements/Achievement.vue'
// Types
import { AchievementResource } from '@/store/types/schema.progress'

@Component({
  components: {
    Achievement,
  },
})
export default class ProgressNewAchievementPopup extends Vue {
  @Prop({ required: true })
  private achievementInfo!: AchievementResource

  @Prop({ required: true })
  private theme!: string

  private isButtonClosing = false

  private handleOpen () {
    this.isButtonClosing = true
    this.$emit('button-open', this.achievementInfo)
    this.$emit('close-toast')
  }

  private handleHide () {
    this.isButtonClosing = true
    this.$emit('close-toast')
  }

  private beforeDestroy () {
    if (!this.isButtonClosing) {
      this.$emit('close-toast')
    }
  }
}
