import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IOrdersProfileFilter,
  ITableOptions, MasterOfferAuthLinkPostRequest,
  MasterOfferLinkGetRequest,
  MasterOfferLinkPostRequest, MasterOfferRegisterLinkPostRequest,
  MasterOrderLargeResource,
  MasterOrdersGetRequest,
  MasterOrderShortResourcePaginator,
  MasterOrdersOrderIdCodeDeleteRequest, MasterOrdersOrderIdCodePostParams,
  MasterOrdersOrderIdCodePostRequest,
  MasterOrdersOrderIdGetRequest, MasterOrdersOrderIdSaleSaleIdDeleteRequest, MasterOrdersOrderIdSaleSaleIdPostRequest,
  MasterOrdersOrderIdSalesDeleteRequest,
  MasterOrdersOrderIdSalesPostRequest,
  MasterOrdersPostRequest,
  MasterOrderStoreRequest,
  OfferStoreRequest,
  PaymentOrdersOrderUuidGetRequest,
  PaymentOrdersOrderUuidStatusGetRequest,
  OfferWithAuthRequest,
  OfferWithRegistrationRequest,
  LinkShopResource,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Покупки мастера
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterOrders',
  namespaced: true,
  store,
})
class MasterOrders extends VuexModule {
  orderSubject: number | null = null

  @Mutation
  setSubject (payload: number | null) {
    this.orderSubject = payload
  }

  // ---------------------------- Orders ---------------------------- >>
  // Filter
  ordersFilter: ITableOptions = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setOrdersFilter (payload: ITableOptions) {
    this.ordersFilter = Object.assign({}, payload)
  }

  // Entities
  orders: MasterOrderShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setOrders (payload: MasterOrderShortResourcePaginator) {
    this.orders = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchOrders (options?: IOrdersProfileFilter) {
    const { data } = await MasterOrdersGetRequest(options || tableOptionsToParams(this.ordersFilter))
    this.setOrders(data)
    return data
  }

  // ---------------------------- Order ---------------------------- >>
  order: MasterOrderLargeResource | null = null

  @Mutation
  setOrder (payload: MasterOrderLargeResource) {
    this.order = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchOrder (orderID: number) {
    const { data } = await MasterOrdersOrderIdGetRequest(orderID)
    this.setOrder(data)
    return data
  }

  // ---------------------------- OrderLink ---------------------------- >>
  @Action({ rawError: true })
  async getPaymentStatus(orderUUID: string) {
    const { data } = await PaymentOrdersOrderUuidStatusGetRequest(orderUUID)
    return data
  }

  @Action({ rawError: true })
  async fetchPayment(orderUUID: string) {
    const { data } = await PaymentOrdersOrderUuidGetRequest(orderUUID)
    this.setOrder(data)
    return data
  }

  // ---------------------------- One Discount on Order ---------------------------- >>

  // Apply one Discount
  @Action({ rawError: true })
  async applyOneDiscount(payload: { orderId: number, saleId: number }) {
    const { data } = await MasterOrdersOrderIdSaleSaleIdPostRequest(payload.orderId, payload.saleId)
    this.setOrder(data)
    return data
  }

  // Remove one Discount
  @Action({ rawError: true })
  async removeOneDiscount(payload: { orderId: number, saleId: number }) {
    const { data } = await MasterOrdersOrderIdSaleSaleIdDeleteRequest(payload.orderId, payload.saleId)
    this.setOrder(data)
    return data
  }

  // ---------------------------- Discounts on Order ---------------------------- >>

  // Apply Discount
  @Action({ rawError: true })
  async applyDiscount (orderID: number) {
    const { data } = await MasterOrdersOrderIdSalesPostRequest(orderID)
    this.setOrder(data)
    return data
  }

  // Remove Discount
  @Action({ rawError: true })
  async removeDiscount (orderID: number) {
    const { data } = await MasterOrdersOrderIdSalesDeleteRequest(orderID)
    this.setOrder(data)
    return data
  }

  // ---------------------------- Order manage ---------------------------- >>
  @Action({ rawError: true })
  async createOrder (payload: MasterOrderStoreRequest) {
    const { data } = await MasterOrdersPostRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async addPromocodeToOrder (payload: { orderID: number, params: MasterOrdersOrderIdCodePostParams }) {
    const { data } = await MasterOrdersOrderIdCodePostRequest(payload.orderID, payload.params)
    this.setOrder(data)
    return data
  }

  @Action({ rawError: true })
  async deletePromocodeFromOrder (orderID: number) {
    const { data } = await MasterOrdersOrderIdCodeDeleteRequest(orderID)
    this.setOrder(data)
    return data
  }

  // ---------------------------- Offer ---------------------------- >>
  offer: LinkShopResource | null = null

  @Mutation
  setOffer (payload: LinkShopResource) {
    this.offer = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchOffer (link: string) {
    const { data } = await MasterOfferLinkGetRequest(link)
    this.setOffer(data)
    return data
  }

  // ---------------------------- Offer manage ---------------------------- >>
  @Action({ rawError: true })
  async purchaseOffer (payload: { link: string, params: OfferStoreRequest }) {
    const { data } = await MasterOfferLinkPostRequest(payload.link, payload.params)
    return data
  }

  @Action({ rawError: true })
  async purchaseOfferRegister(payload: { link: string, params: OfferWithRegistrationRequest }) {
    const { data } = await MasterOfferRegisterLinkPostRequest(payload.link, payload.params)
    return data
  }

  @Action({ rawError: true })
  async purchaseOfferLogin(payload: { link: string, params: OfferWithAuthRequest }) {
    const { data } = await MasterOfferAuthLinkPostRequest(payload.link, payload.params)
    return data
  }
}

const MasterOrdersModule = getModule(MasterOrders)

export default MasterOrdersModule
