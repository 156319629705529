import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const GET_DEFAULT_TABLE_FILTER = () => {
  return {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 25,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
  }
}

export const GET_DEFAULT_PAGINATOR_META = () => {
  return {
    currentPage: 0,
    from: 0,
    lastPage: 0,
    perPage: 0,
    to: 0,
    total: 0,
  }
}

export const GET_DEFAULT_PAGINATOR = () => {
  return {
    data: [],
    meta: GET_DEFAULT_PAGINATOR_META(),
  }
}

export default new Vuex.Store({})
